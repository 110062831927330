import React, {useEffect} from "react";
import {getCssSizeValue, handleSetPositionOnMouseMove} from "@repo/utils";
import {BREAKPOINTS} from "../../../styles/themeSettings";
import styled from 'styled-components';
import {useExpandBackgroundAnimation, useHover} from "../../../index";

export const MODAL_BUTTON_VARIANTS = {
    PRIMARY: 'primary',
    SECONDARY: 'secondary'
}

const ButtonWrapper = styled.div`
    position: relative;
    overflow: hidden;
    display: grid;
    place-items: center;
    cursor: pointer;
    flex: 1;
    
    background: var(--background);
    transition: all var(--color-transition-duration) var(--ease);
    height: ${getCssSizeValue(60, BREAKPOINTS.TABLET)};

    &.left {
        border-bottom-left-radius: 19px;
        border-right: var(--border);
    }
    
    &.right {
        border-bottom-right-radius: 19px;
        border-left: var(--border);
    }

    &.single {
        border-radius: 0 0 19px 19px;
    }
    
    &.bold {
        button {
            font-weight: 800 !important;
        }
    }
    
    &.dimmed {
        .button {
            color: rgba(5, 29, 39, 0.5);

            span {
                border-color: rgba(5, 29, 39, 0.5);
            }
        }
    }
    
    &.low-opacity {
        .button {
            opacity: 0.5;
        }
    }
    
    &.${MODAL_BUTTON_VARIANTS.PRIMARY} {
        --border: 1px solid ${({theme}) => theme.colors.white};
        --background: ${({theme}) => theme.colors.lightGray};
    }

    &.${MODAL_BUTTON_VARIANTS.SECONDARY} {
        --border: 1px solid ${({theme}) => theme.colors.twilightBlue};
        --background: ${({theme}) => theme.colors.gunmetal};
    }
    
    @media (min-width: ${({theme}) => theme?.breakpoints?.tablet || 768}px) {
        height: ${getCssSizeValue(87, BREAKPOINTS.TABLET)};
    }

    @media (min-width: ${({theme}) => theme.breakpoints.desktop}px) {
        height: ${getCssSizeValue(87, BREAKPOINTS.DESKTOP)};
    }
`

const ButtonBackground = styled.div`
    position: absolute;
    background: var(--background);
    border-radius: 50%;
    top: var(--y, 50%);
    left: var(--x, 50%);
    transform: translate(-50%, -50%);
    z-index: 0;
    width: var(--width);
    height: var(--height);

    &.${MODAL_BUTTON_VARIANTS.PRIMARY} {
        --background: ${({theme}) => theme.colors.aliceBlue};
    }

    &.${MODAL_BUTTON_VARIANTS.SECONDARY} {
        --background: ${({theme}) => theme.colors.charcoal};
    }
`

export default function ModalButton({className, variant = MODAL_BUTTON_VARIANTS.PRIMARY, children, ...props}) {
    const [ref, isHovering] = useHover({ onlyDesktop: true })

    const hoverTl = useExpandBackgroundAnimation(ref)

    useEffect(() => {
        isHovering ? hoverTl.current.play() : hoverTl.current.reverse()
    }, [isHovering]);

    return (
        <ButtonWrapper
            ref={ref}
            onMouseMove={handleSetPositionOnMouseMove}
            className={`modal-button ${className} ${variant}`}
            {...props}
        >
            <ButtonBackground
                className={variant}
            />
            {children}
        </ButtonWrapper>
    )
}