import React, {useEffect, useRef, useState} from 'react';
import {getFontSize, getHtmlFromRedactorField} from '@repo/utils';
import styled from 'styled-components';
import ArrowTopDark from '../inlineSvg/ArrowTopDark';
import {gsap, SplitText, useGSAP} from "@repo/utils/gsap"
import useIsDesktop from "../../hooks/useIsDesktop";
import Image from '../common/Image'
import {CURSOR_TYPES} from "@repo/utils/constants";

const StyledSingleRowWrapper = styled.div`
	position: relative;
    overflow: hidden;
    margin: 0 auto;
    &:hover {
        .marquee__inner {
            animation-play-state: running;
        }
        .marquee {
            opacity: 1;
        }
    }
`;

const StyledHeading = styled.div`
	font-weight: ${({ theme }) => theme?.fontWeight?.semiBold || 600};
	width: 100%;
	color: var(--secondary-color);
    transition: color var(--color-transition-duration) var(--ease);
	font-size: 24px;
	line-height: 32px;
	max-width: 90%;
    //height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 40px;
	@media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
		font-size: 30px;
		//line-height: 56px;
	}
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		font-size: 46px;
		line-height: 56px;
		max-width: unset;
	}
`;

const StyledRowCollapsed = styled.div`
	//align-items: center;
    align-items: flex-start;
	background-color: transparent;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 30px 0;
	cursor: pointer;
	&:hover {
		background-color: rgba(255, 255, 255, 0.05);
		div.arrow {
			&:after {
				transform: translateX(-50%) translateY(-50%) scale(1);
			}
			svg {
				path {
					fill: #01232F;
				}
			}
		}
	}
	@media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
		//padding: 40px 0 55px;
        align-items: center;
	}
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 60px 0;
	}
`;

const StyledIconAndArrowWrapper = styled.div`
	display: flex;
	width: auto;
	justify-content: space-between;
    align-items: flex-start;
	//margin-top: 15px;
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		flex-direction: row;
		margin-top: 0;
		justify-content: flex-end;
		width: auto;
	}
	img {
		width: 70px;
		height: 70px;
	}
`;

const RowExtended = styled.div`
	max-height: 1px;
	opacity: 0;
	overflow-y: hidden;
	overflow-x: hidden;
    transition: max-height 0.3s ease-in-out 0.80s, opacity 0.9s ease-in-out 0.80s;
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        transition: max-height 0.3s ease-in-out 0.65s, opacity 0.9s ease-in-out 0.6s;
    }
	&.open {
		max-height: 4000px;
		opacity: 1;
		transition: max-height 0.3s ease-in-out, opacity 0s ease-in-out;
		@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
			transition: max-height 0.23s ease-in-out, opacity 0.7s ease-in-out;
		}
	}
    margin: 0 auto;
    @media (min-width: 1440px) {
        max-width: 1290px;
    }
`;

const StyledArrowIconWrapper = styled.div`
	align-items: center;
	display: flex;
	justify-content: flex-end;
	margin-left: 30px;
	margin-right: 3px;
	position: relative;
    padding: 6px 10px;
    @media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        padding: 10px;
    }
	&:after {
		content: '';
		width: 30px;
		height: 30px;
		border-radius: 50%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%) scale(0);
		transition: transform 0.3s var(--ease);
		z-index: 0;
	}
	&.rotated {
		&:after {
			transform: translateX(-50%) translateY(-50%) scale(1);
			transition: transform 0.3s var(--ease);
		}
		svg {
			transition: transform 0.3s ease-in-out;
			transform: rotate(0deg);
			path {
				fill: #01232F;
			}
		}
	}
	&.normal {
		svg {
			transition: transform 0.3s ease-in-out;
			transform: rotate(-180deg);
		}
	}
	svg {
		transition: transform 0.3s ease-in-out;
		z-index: 1;
	}
`;

const StyledIconWrapper = styled(Image)`
	display: none;
	width: 200px;
	height: 200px;
	position: absolute;
	right: -30px;
	top: 35px;
	transform: scale(0.26);
	transition: transform 0.3s ease-in-out, top 0.3s ease-in-out, right 0.3s ease-in-out;
	transform-origin: top;
	z-index: 4;
	will-change: right, top, transform;
	@media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
		transform: scale(0.4);
		right: -10px;
		top: 20px;
	}
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		right: -30px;
		top: 40px;
		width: 267px;
		height: 267px;
		-webkit-transform-3d: scale(0.36);
	}
	&.mobile {
		transform: scale(1);
		position: relative;
		top: unset;
		right: 0;
		z-index: 0;
		margin-right: 10px;
		transition: transform 0s ease-in-out;
		display: block;
		width: 169px;
		height: 169px;
		@media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
			display: none;
		}
	}
	&.open {
		right: 50px;
		top: 110px;
		transform: scale(1);
		transition: transform 0.3s ease-in-out 0.4s, top 0.3s ease-in-out 0.4s, right 0.3s ease-in-out 0.4s;
		transform-origin: top;
		will-change: right, top, transform;
		@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
			top: 80px;
		}
		@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
			right: 86px;
			top: 200px;
		}
		@media (min-width: 1920px) {
			right: 86px;
			top: 160px;
		}
	}
	@media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
		display: block;
	}
    &.marquee-dropdown {
        width: 60px;
        aspect-ratio: 1;
        position: relative;
        margin: 0 60px;
        height: auto;
        right: unset;
        top: unset;
        transform: none;
    }
`;

const StyledDescriptionContainer = styled.div`
	height: 100%;
	transition: transform 0.3s var(--ease) 0.1s;
	transform-origin: top;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	width: 100%;
	@media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
		display: block;
	}
`;

const StyledDescriptionMask = styled.div`
	transform: scaleY(1);
	background-color: var(--primary-color);
	height: 100%;
	transition: transform 0.4s cubic-bezier(0.6, 1, 0.16, 1) 1.2s, background-color 0s linear;
	transform-origin: bottom;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		transition: transform 0.4s cubic-bezier(0.2, 1, 0.15, 0.68) 1.2s, background-color 0s linear;
	}
	&.open {
		transform: scaleY(0);
		transition: transform 0.6s cubic-bezier(0.6, 1, 0.16, 1) 0.15s, background-color 0s linear;
		@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
			transition: transform 0.4s cubic-bezier(0.2, 1, 0.15, 0.68) 0.15s, background-color 0s linear;
		}
	}
`;

const StyledDescription = styled.div`
	max-width: 100%;
	color: #14242c;
	font-weight: 400;
	margin: 29px 0 60px;
	font-size: 16px;
	line-height: 28px;
	@media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
		max-width: calc(100% - 200px - 35px - 30px);
	}
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		font-size: 28px;
		line-height: 46px;
		margin: 29px 0 85px;
		max-width: calc(100% - 270px - 35px - 121px);
	}
	transition: transform 0.3s ease-in-out;
	&.open {
		transition: transform 0.3s ease-in-out;
	}
`;

const StyledInnerWrapper = styled.div`
    width: 100%;
    position: relative;
    margin: 0 auto;
    border-bottom-width: 1px;
    border-color: #c5c5c5;
    border-left-width: 0;
    border-right-width: 0;
    border-style: solid;
    border-top-width: 0;
    @media (min-width: 1440px) {
        max-width: 1290px;
    }
`;

const StyledMarqueeContainer = styled.div`
    display: none;
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        display: block;
    }
    .marquee__inner-wrap {
        height: 100%;
        width: 100%;
        transform: translate3d(0, -101%, 0);
    }
    &.marquee {
        position: absolute;
        //background-color: #051D27;
        background-color: white;
        top: 0;
        z-index: 5;
        left: 0;
        height: 178px;
        overflow: hidden;
        --offset: 20vw;
        --move-initial: calc(-25% + var(--offset));
        --move-final: calc(-50% + var(--offset));
        opacity: 0;
    }
    &.marquee-hidden {
        opacity: 0;
    }
    .marquee__inner {
        height: 100%;
        width: fit-content;
        display: flex;
        align-items: center;
        position: relative;
        transform: translate3d(var(--move-initial), 0, 0);
        animation: marquee 5s linear infinite;
        animation-play-state: paused;
    }

    &.marquee span {
        height: 100%;
        width: max-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        //padding: 0 2vw;
        //color: #F3F4F6;
        color: #051D27;
        font-size: ${getFontSize(66)};
        font-style: normal;
        font-weight: 400;
        line-height: ${getFontSize(56)};
        text-transform: uppercase;
    }

    &.marquee:hover .marquee__inner {
        animation-play-state: running;
    }

    @keyframes marquee {
        0% {
            transform: translate3d(var(--move-initial), 0, 0);
        }

        100% {
            transform: translate3d(var(--move-final), 0, 0);
        }
    }
`

export function splitText(element = null, splitBy = 'words') {
    if (!element) return null;
    const splitText = new SplitText(element, {type: splitBy})
    return splitText[splitBy]
}


const SingleScopeDropdownMarquee = ({ id, title, icon, description }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const isDesktop = useIsDesktop()
    const timeline = useRef(gsap.timeline({paused: true}));
    const image = { url: '', alt: 'scope-icon', focalPoint: {}, blurUpThumb: '', ...icon}
    const handleClickOnDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
        if(isDesktop) {
            gsap.timeline({defaults: {duration: 0.2, ease: 'linear'}})
                .to(marqueeRef.current, { y: '-101%' }, 0)
                .to(marqueeInnerRef.current, { y: '101%' }, 0);
        }
    };
    const animationDefaults = {duration: 0.6, ease: 'expo'};
    const marqueeRef = useRef(null);
    const marqueeInnerRef = useRef(null);
    const descriptionRef = useRef();
    const mouseEnter = (e) => {
        if(isDesktop && !isDropdownOpen) {
            const edge = findClosestEdge(e);
            gsap.timeline({defaults: animationDefaults})
                .set(marqueeRef.current, { y: edge === 'top' ? '-101%' : '101%', opacity: 1 }, 0)
                .set(marqueeInnerRef.current, { y: edge === 'top' ? '101%' : '-101%' }, 0)
                .to([marqueeRef.current, marqueeInnerRef.current], { y: '0%' }, 0);
        }
    };

    const mouseLeave = (e) => {
        if(isDesktop && !isDropdownOpen) {
            const edge = findClosestEdge(e);
            gsap.timeline({defaults: animationDefaults})
                .to(marqueeRef.current, { y: edge === 'top' ? '-101%' : '101%', opacity: 1 }, 0)
                .to(marqueeInnerRef.current, { y: edge === 'top' ? '101%' : '-101%' }, 0);
        }
    };

    const findClosestEdge = (e) => {
        const rect = marqueeRef.current.getBoundingClientRect();
        const offsetX = e.clientX - rect.left;
        const offsetY = e.clientY - rect.top;
        const { width, height } = rect;
        const distTop = offsetY;
        const distBottom = height - offsetY;

        const minDist = Math.min(distTop, distBottom);

        if (minDist === distTop)  return 'top';
        if (minDist === distBottom)  return 'bottom';
    };

    useGSAP(() => {
        const descriptionWords = splitText(descriptionRef.current, 'words');
        gsap.set([descriptionWords], {opacity: 0, y: 15});
        timeline.current.to(descriptionWords, {opacity: 1, stagger: 0.01, y: 0, delay: 0.15})
    })

    useEffect(() => {
        isDropdownOpen ? timeline.current.play() : timeline.current.reverse();
    }, [isDropdownOpen]);

    return (
        <>
            <StyledSingleRowWrapper
                data-cursor={isDropdownOpen ? CURSOR_TYPES.MARQUEE_EXPANDED : CURSOR_TYPES.MARQUEE}
                onMouseEnter={mouseEnter}
                onMouseLeave={mouseLeave}
                className='px-main'>
                <StyledInnerWrapper>
                    <StyledRowCollapsed id={id} onClick={handleClickOnDropdown}>
                        <StyledHeading>{title}</StyledHeading>
                        <StyledIconAndArrowWrapper>
                            <StyledArrowIconWrapper data-cursor={CURSOR_TYPES.CIRCLE} className={isDropdownOpen ? 'rotated arrow' : 'normal arrow'}>
                                <ArrowTopDark />
                            </StyledArrowIconWrapper>
                        </StyledIconAndArrowWrapper>
                    </StyledRowCollapsed>
                    <StyledIconWrapper onClick={handleClickOnDropdown} className={isDropdownOpen ? 'open' : ''} src={image.url} alt={image.alt}/>
                <RowExtended className={isDropdownOpen ? 'open' : ''}>
                    <StyledDescriptionContainer className={isDropdownOpen ? 'open' : ''}>
                        <StyledIconWrapper className={'mobile'} src={image.url} alt={image.alt}/>
                        <StyledDescription
                            ref={descriptionRef}
                            className={isDropdownOpen ? 'open' : ''}
                            dangerouslySetInnerHTML={getHtmlFromRedactorField(description)}
                        />
                        <StyledDescriptionMask className={isDropdownOpen ? 'open' : ''} />
                    </StyledDescriptionContainer>
                </RowExtended>
                </StyledInnerWrapper>
                <StyledMarqueeContainer
                      ref={marqueeRef}
                      // onMouseEnter={mouseEnter}
                      // onMouseLeave={mouseLeave}
                      className={isDropdownOpen ? 'marquee marquee-hidden' : "marquee"}
                      onClick={handleClickOnDropdown}>
                        <div ref={marqueeInnerRef} className="marquee__inner-wrap">
                            <div className="marquee__inner" aria-hidden="true">
                                <span>{title}</span>
                                 <StyledIconWrapper className={'marquee-dropdown'} src={image.url} alt={image.alt}/>
                                <span>{title}</span>
                                 <StyledIconWrapper className={'marquee-dropdown'} src={image.url} alt={image.alt}/>
                                <span>{title}</span>
                                 <StyledIconWrapper className={'marquee-dropdown'} src={image.url} alt={image.alt}/>
                                <span>{title}</span>
                                 <StyledIconWrapper className={'marquee-dropdown'} src={image.url} alt={image.alt}/>
                            </div>
                        </div>
                    </StyledMarqueeContainer>
            </StyledSingleRowWrapper>
        </>
    );
};

export default SingleScopeDropdownMarquee;
