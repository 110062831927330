import {useExpandBackgroundAnimation, useHover, useIsDesktop} from "../../../index";
import {useRouter} from "next/router";
import {
    getCssSizeValue,
    getFontSize,
    getHtmlFromRedactorField,
    getPropSafely,
    handleSetPositionOnMouseMove
} from "@repo/utils";
import styled from "styled-components";
import {BREAKPOINTS, theme} from "../../../styles/themeSettings";
import Image, {formatImageCMSData} from "../common/Image";
import {gsap, useGSAP, ScrollTrigger} from '@repo/utils/gsap'
import {useEffect, useRef, useState} from "react";

const StyledCard = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    @media (min-width: ${({theme}) => theme.breakpoints.desktop || 1024}px) and (orientation: landscape) {
        flex: 1;
    }
`

const AnimatedBackground = styled.div`
    content: '';
    position: absolute;
    top: var(--y, 50%);
    left: var(--x, 50%);
    background: ${props => props.theme.colors.white};
    border-radius: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
    width: var(--width);
    height: var(--height);
`

const CardContent = styled.div`
    background: transparent;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: ${getCssSizeValue(40, BREAKPOINTS.MOBILE)};
    align-items: center;
    text-align: center;
    cursor: pointer;
    transition: all var(--transition-duration) var(--ease);

    @media (min-width: ${({theme}) => theme.breakpoints.tablet || 768}px) {
        gap: ${getCssSizeValue(60, BREAKPOINTS.TABLET)};
    }

    @media (min-width: ${({theme}) => theme.breakpoints.desktop || 1024}px) and (orientation: landscape) {
        gap: ${getCssSizeValue(70, BREAKPOINTS.DESKTOP)};
        width: 100%;
        padding: 5% 0 ${getCssSizeValue(120, BREAKPOINTS.DESKTOP)};

        &.hovered {
            color: ${props => props.theme.colors.twilightBlue};
        }
    }
`

const StyledImage = styled(Image)`
    width: 100%;
    height: 100%;
`

const ImageWrapper = styled.div`
    position: relative;
    aspect-ratio: ${({$aspectRatio}) => $aspectRatio || '16/9'};
    width: 80%;
    height: auto;

    @media (min-width: ${({theme}) => theme.breakpoints.tablet || 768}px) {
        width: 100%;
    }

    @media (min-width: ${({theme}) => theme.breakpoints.desktop || 1024}px) and (orientation: landscape) {
        width: 90%;
    }
`

const ImageRevealBlock = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    background: var(--primary-color);
    top: 0;
    left: 0;
    z-index: 2;
    transform-origin: center top;
`

const Tag = styled.div`
    overflow: hidden;
    text-transform: uppercase;
    font-size: ${getCssSizeValue(22, BREAKPOINTS.MOBILE)};
    letter-spacing: -0.88px;

    @media (min-width: ${({theme}) => theme.breakpoints.tablet || 768}px) {
        font-size: ${getCssSizeValue(22, BREAKPOINTS.TABLET)};
    }

    @media (min-width: ${({theme}) => theme.breakpoints.desktop || 1024}px) and (orientation: landscape) {
        font-size: ${getCssSizeValue(22, BREAKPOINTS.DESKTOP)};
    }
`

const Text = styled.div`
    overflow: hidden;

    em {
        font-weight: ${props => props.theme.fontWeight.book};
    }

    font-size: ${getFontSize(24, BREAKPOINTS.MOBILE)};
    line-height: ${getFontSize(36, BREAKPOINTS.MOBILE)};

    @media (min-width: ${({theme}) => theme.breakpoints.tablet || 768}px) {
        font-size: ${getFontSize(24, BREAKPOINTS.TABLET)};
        line-height: ${getFontSize(36, BREAKPOINTS.TABLET)};
    }

    @media (min-width: ${({theme}) => theme.breakpoints.desktop || 1024}px) and (orientation: landscape) {
        font-size: ${getFontSize(36, BREAKPOINTS.DESKTOP)};
        line-height: ${getFontSize(56, BREAKPOINTS.DESKTOP)};
    }
`

const ArrowAndButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${getCssSizeValue(80, BREAKPOINTS.DESKTOP)};

    .icon {
        height: ${getCssSizeValue(40, BREAKPOINTS.MOBILE)};

        @media (min-width: ${({theme}) => theme.breakpoints.tablet || 768}px) {
            height: ${getCssSizeValue(80, BREAKPOINTS.TABLET)};
        }

        @media (min-width: ${({theme}) => theme.breakpoints.desktop || 1024}px) and (orientation: landscape) {
            height: ${getCssSizeValue(110, BREAKPOINTS.DESKTOP)};
        }
    }

    margin-top: ${getCssSizeValue(60, BREAKPOINTS.MOBILE)};

    @media (min-width: ${({theme}) => theme.breakpoints.tablet || 768}px) {
        margin-top: ${getCssSizeValue(60, BREAKPOINTS.TABLET)};
    }

    @media (min-width: ${({theme}) => theme.breakpoints.desktop || 1024}px) and (orientation: landscape) {
        margin-top: unset;
    }
`

function formatData(data) {
    return {
        id: getPropSafely(data, 'id'),
        tag: getPropSafely(data, 'tag'),
        text: getPropSafely(data, 'text'),
        slug: data?.slug?.slug || '',
        image: formatImageCMSData(data?.image),
        hoverImage: formatImageCMSData(data?.hoverImage)
    }
}

export default function Card({item, index, buttonLink, buttonText, itemsLength, isSinglePage}) {
    const isDesktop = useIsDesktop()
    const [isAnimationFinished, setIsAnimationFinished] = useState(false)
    const scopeRef = useRef(null)
    const tagRef = useRef(null)
    const textRef = useRef(null)
    const imageRevealBlockRef = useRef(null)

    const [ref, isHovered] = useHover({ onlyDesktop: true })
    const router = useRouter()

    const {id, tag, text, slug, image, hoverImage} = formatData(item)
    const imageSrc = isHovered ? hoverImage : image

    const hoverTl = useExpandBackgroundAnimation(scopeRef)

    useEffect(() => {
        isHovered ? hoverTl.current.play() : hoverTl.current.reverse()
    }, [isHovered]);

    useGSAP(() => {
        const tl = gsap.timeline({paused: true})

        gsap.to([tagRef.current, textRef.current], {
            yPercent: 100
        })

        tl
            .to(imageRevealBlockRef.current, {
                scaleY: 0,
                delay: isDesktop ? index * 0.5 : 0,
                onComplete: () => {
                    setIsAnimationFinished(true)
                }
            })
            .to(tagRef.current, {
                yPercent: 0,
            }, '-=90%')
            .to(textRef.current, {
                yPercent: 0,
            }, '-=90%')
            .to('.icon-mask', {
                scaleY: 0,
            })

        ScrollTrigger.create({
            trigger: scopeRef.current,
            start: 'top-=20% center',
            markers: false,
            animation: tl,
            toggleActions: 'play none none none',
        })
    }, {dependencies: [isDesktop]})

    return (
        <StyledCard
            ref={scopeRef}
            onMouseMove={handleSetPositionOnMouseMove}
            onClick={() => {
                router.push(isSinglePage ? `${slug}` : `approach/${slug}`)
            }}
        >
            <AnimatedBackground/>
            <CardContent
                ref={ref}
                className={(isAnimationFinished && isHovered) ? 'hovered' : ''}
            >
                <ImageWrapper
                    $aspectRatio={imageSrc.aspectRatio}
                >
                    <ImageRevealBlock ref={imageRevealBlockRef}/>
                    <StyledImage
                        id={id}
                        src={imageSrc.url}
                        alt={imageSrc.alt}
                        sizes={'100vw'}
                    />
                </ImageWrapper>
                <Tag>
                    <p ref={tagRef}>{tag}</p>
                </Tag>
                <Text>
                    <div
                        dangerouslySetInnerHTML={getHtmlFromRedactorField(text)}
                        ref={textRef}
                    />
                </Text>
            </CardContent>
            {/*{(index === 1 && isDesktop) && (*/}
            {/*    <ArrowAndButtonWrapper>*/}
            {/*        <div className='icon'>*/}
            {/*            <Icon name={ICONS.HOW_WE_CREATE_STORY_ARROW_DOWN_DESKTOP} />*/}
            {/*        </div>*/}
            {/*        <Button*/}
            {/*            onClick={(event) => {*/}
            {/*                event.stopPropagation()*/}
            {/*                router.push(`/${buttonLink}`)*/}
            {/*            }}*/}
            {/*        >*/}
            {/*            {buttonText}*/}
            {/*        </Button>*/}
            {/*    </ArrowAndButtonWrapper>*/}
            {/*)}*/}
        </StyledCard>
    )
}
