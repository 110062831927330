import React, { useMemo, useRef, useState } from 'react';
import styled from "styled-components";
import Text from "../common/Text";
import {getFontSize, getHtmlFromRedactorField, getRangeProgress} from "@repo/utils";
import {ScrollTrigger, useGSAP, gsap} from "@repo/utils/gsap";
import { useWindowSize} from "../../../index";
import Reveal from "../../animations/Reveal";
import {BREAKPOINTS} from "../../../styles/themeSettings";

const DEFAULT_ALIGN = 'left'

const Wrapper = styled.div`
    width: 100%;
    height: 100svh;
    position: relative;
    display: flex;
    flex-direction: column;
    color: var(--secondary-color);
    transition: color var(--color-transition-duration) var(--ease);
    align-items: center;
    justify-content: ${props => props.$containerAlign};
    .text{
        --font-size: ${getFontSize(40, BREAKPOINTS.MOBILE)};
        font-size: var(--font-size);
        letter-spacing: calc(var(--font-size) * -0.04);
        text-align: ${({ $align }) => $align || DEFAULT_ALIGN};
        line-height: ${getFontSize(57, BREAKPOINTS.MOBILE)};
        em{
            font-weight: ${({ theme }) => theme.fontWeight.light};
        }
    }
    

    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        .text{
            --font-size: ${getFontSize(75, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(88, BREAKPOINTS.TABLET)};
        }
    }

    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        
        .text{
            --font-size: ${getFontSize(116)};
            line-height: ${getFontSize(136)};
        }
    }
`
const PinSpacer = styled.div`

`

const OPTIONS = {
    start: 'top top',
    end: '+=250%',
    breakpoint: 0.2,
    trigger: null,
    ease: undefined,
    pinSpacing: true,
    markers: false
}

const Slogan = ({ id='',data, onUpdate, scrollTriggerOptions = OPTIONS, ...props }) => {
    const text = data?.text || ''
    const align = data?.align || DEFAULT_ALIGN
    const containerAlign = data?.containerAlign || 'center'
    const SpacerRef = useRef()
    const WrapperRef = useRef()
    const redactor = getHtmlFromRedactorField(text)
    const windowSize = useWindowSize()
    const [revealTl] = useState(() => gsap.timeline({ paused: true }))
    const opt = useMemo(() => ({
        ...OPTIONS,
        ...scrollTriggerOptions,
    }),[scrollTriggerOptions])
    const {
        start,
        end,
        breakpoint,
        trigger,
        ease,
        pinSpacing,
        markers
    } = opt

    useGSAP(() => {
        const target = trigger ? trigger : WrapperRef.current
        const pinSpacer = trigger ? undefined : SpacerRef.current
        ScrollTrigger.create({
            id: `slogan-slider-${id}`,
            trigger: target,
            start,
            end,
            markers,
            pin: true,
            pinSpacing,
            pinSpacer,
            scrub: true,
            onUpdate: ({ progress }) => {
                if(typeof onUpdate === 'function'){
                    onUpdate({ progress })
                }
                //manage revealing of the whole wrapper
                //progress alternates at enter and leave
                let revealProgress
                if(progress < (1 - breakpoint)){
                    //enter
                    revealProgress = getRangeProgress(progress, 0,breakpoint)
                } else {
                    //leave
                    revealProgress = 1 - getRangeProgress(progress, 1 - breakpoint,1)
                }
                revealTl.progress(revealProgress)
            }
        })

    }, {
        dependencies: [windowSize.width, start, end, breakpoint, trigger, pinSpacing, markers],
        scope: WrapperRef.current,
        revertOnUpdate: true
    })

    return (
        <PinSpacer ref={SpacerRef}>
            <Wrapper ref={WrapperRef} $align={align} $containerAlign={containerAlign} >
                <Reveal paused timeline={revealTl} animationVars={{ ease }} parentProps={props}>
                    <Text id={id} dangerouslySetInnerHTML={redactor}/>
                </Reveal>
            </Wrapper>
        </PinSpacer>
    );
};

export default Slogan;
