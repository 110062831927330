import React, {useState} from 'react';
import styled from 'styled-components'
import {getCssSizeValue, getFontSize} from "@repo/utils";
import {useRouter} from "next/router";
import {BREAKPOINTS} from "../../../styles/themeSettings";
import Button from "../common/Button";
import Image from "../common/Image"
import DateInfo from "../common/DateInfo";

const SingleThumbnailWrapper = styled.div`
    width: 100%;
	height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
	cursor: pointer;
	&.desktop {
		display: none;
		@media (min-width: ${({theme}) => theme?.breakpoints?.desktop || 1024}px) {
			display: flex;
		}
	}
	&.mobile {
		display: flex;
		@media (min-width: ${({theme}) => theme?.breakpoints?.desktop || 1024}px) {
			display: none;
		}
	}
    p.read-time {
      font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
      line-height: ${getFontSize(20, BREAKPOINTS.MOBILE)};
      @media (min-width: ${({theme}) => theme?.breakpoints?.tablet || 768}px) {
        font-size: ${getFontSize(14, BREAKPOINTS.TABLET)};
        line-height: ${getFontSize(54, BREAKPOINTS.TABLET)};
      }
        @media (min-width: ${({theme}) => theme?.breakpoints?.desktop || 1024}px) {
            font-size: ${getFontSize(14, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(54, BREAKPOINTS.DESKTOP)};
        }
    }
  p.title-mobile {
    font-size: ${getFontSize(16, BREAKPOINTS.MOBILE)};
    line-height: ${getFontSize(28, BREAKPOINTS.MOBILE)};
    @media (min-width: ${({theme}) => theme?.breakpoints?.tablet || 768}px) {
      font-size: ${getFontSize(18, BREAKPOINTS.TABLET)};
      line-height: ${getFontSize(30, BREAKPOINTS.TABLET)};
      margin-bottom: ${getCssSizeValue(50, BREAKPOINTS.TABLET)};
    }
  }
    p.title {
		span {
			background-image: linear-gradient(180deg,transparent 97%, var(--secondary-color) 0);
			background-size: 0 100%;
			background-repeat: no-repeat;
			text-decoration: none;
			transition: background-size 0.4s ease 0s;
		}
		&.hover {
			span {
				transition: background-size 0.4s ease 0s;
				background-size: 100% 100%;
			}
		}
        @media (min-width: ${({theme}) => theme?.breakpoints?.desktop || 1024}px) {
            font-size: ${getFontSize(34, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(54, BREAKPOINTS.DESKTOP)};
            margin-bottom: ${getCssSizeValue(33, BREAKPOINTS.DESKTOP)};
        }
    }
  &.mobile {
    flex-direction: row;
    column-gap: ${getCssSizeValue(29, BREAKPOINTS.MOBILE)};
    margin-bottom: 25px;
    @media (min-width: ${({theme}) => theme?.breakpoints?.tablet || 768}px) {
      column-gap: ${getCssSizeValue(38, BREAKPOINTS.TABLET)};
    }
  }
`

const SingleCardContainer = styled.div`
	overflow: hidden;
	position: relative;
`

const SingleCard = styled.div`
    width: 100%;
    height: auto;
    position: relative;
    aspect-ratio: ${({aspectRatio}) => aspectRatio ? aspectRatio : 16/9};
	transition: transform 0.3s ease-in-out;
	transform: scale(1);
	&.hover {
		transition: transform 0.3s ease-in-out;
		transform: scale(1.05)
	}
  	&.mobile {
	  width: ${getCssSizeValue(129, BREAKPOINTS.MOBILE)};
      @media (min-width: ${({theme}) => theme?.breakpoints?.tablet || 768}px) {
        width: ${getCssSizeValue(174, BREAKPOINTS.TABLET)};
      }
    }
`

const StyledCardWithTextsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    p.title {
        position: relative;
        z-index: 1;
    }
  &.mobile {
    justify-content: space-between;
    width: calc(100% - ${getCssSizeValue(129, BREAKPOINTS.MOBILE)} - ${getCssSizeValue(29, BREAKPOINTS.MOBILE)});
    @media (min-width: ${({theme}) => theme?.breakpoints?.tablet || 768}px) {
      width: calc(100% - ${getCssSizeValue(174, BREAKPOINTS.TABLET)} - ${getCssSizeValue(38, BREAKPOINTS.TABLET)});
    }
  }
`


const LoadMoreButtonWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
`

const AllFiltrationTabsWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	column-gap: 10px;
  	margin-top: ${getCssSizeValue(50, BREAKPOINTS.DESKTOP)};
`

const StyledPleoTag = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 106px;
    height: 33px;
    z-index: 2;
    border-radius: 0 0 0 13px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  	//color: var(--primary-color);
    &.secondary {
        color: var(--primary-color);
    }
    &.primary {
        color: var(--secondary-color);
    }
    p {
        font-weight: ${({theme}) => theme.fontWeight.bold};
        font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
        line-height: ${getFontSize(54, BREAKPOINTS.MOBILE)};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(14, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(54, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme?.breakpoints?.desktop || 1024}px) {
            font-size: ${getFontSize(14, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(54, BREAKPOINTS.DESKTOP)};
            margin-bottom: ${getCssSizeValue(33, BREAKPOINTS.DESKTOP)};
        }
    }
    &.pleo-digital {
        background-color: #E84F44;
    }
    &.pleo-design {
        background-color: #2AC1D7;
    }
    &.pleo-method {
        background-color: #9747FF;
    }    
  	&.pleo-group {
        background-color: #1a303c;
    }
`

const CategoriesWrapper = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: 15px;
    margin-top: 10px;
    &.mobile {
    margin: 0;
    flex-wrap: wrap;
    p.category {
        text-transform: uppercase;
      	color: #355868;
        font-size: ${getFontSize(10, BREAKPOINTS.MOBILE)};
        line-height: ${getFontSize(14, BREAKPOINTS.MOBILE)};
        font-weight: ${({theme}) => theme.fontWeight.bold};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(10, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(24, BREAKPOINTS.TABLET)};
        }
    }
  }
    p.category {
        text-transform: uppercase;
      	color: #355868;
        font-size: ${getFontSize(16, BREAKPOINTS.MOBILE)};
        line-height: ${getFontSize(24, BREAKPOINTS.MOBILE)};
        font-weight: ${({theme}) => theme.fontWeight.bold};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(16, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(24, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme?.breakpoints?.desktop || 1024}px) {
            font-size: ${getFontSize(16, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(24, BREAKPOINTS.DESKTOP)};
        }
    }
`

const StyledReadTimeAndDateWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: ${getCssSizeValue(8, BREAKPOINTS.MOBILE)};
	margin-bottom: 0;
    color: #355868;
    @media (min-width: ${({theme}) => theme?.breakpoints?.tablet || 768}px) {
        margin-top: ${getCssSizeValue(8, BREAKPOINTS.TABLET)};
    }
    @media (min-width: ${({theme}) => theme?.breakpoints?.desktop || 1024}px) {
        margin-top: ${getCssSizeValue(8, BREAKPOINTS.DESKTOP)};
        margin-bottom: ${getCssSizeValue(18, BREAKPOINTS.DESKTOP)};
    }
	p {
		line-height: normal!important;
        font-weight: 400;
	}
    p.mobile {
        display: none;
        @media (min-width: ${({theme}) => theme?.breakpoints?.tablet || 768}px) {
            display: flex;
        }
    }
`

const SingleArticleCard = ({data, globals, locale, buttonVariant, isCurrentPageGroup, hidePleoTags, handleClick}) => {
    const title = data?.blogPageName || '';
    const createdAt = data?._createdAt || '';
    const slug = data?.slug || '';
    const wordsCounter = data?.wordsCount?.words || 1;
    const wordsPerMinute = 200;
    const readingTimeInMinutes = Math.ceil(wordsCounter / wordsPerMinute) || 1;
    const assetUrl = data?.thumbnailImage?.url || '';
    const assetWidth = data?.thumbnailImage?.width || 0;
    const assetHeight = data?.thumbnailImage?.height || 1;
    const aspectRatio = assetWidth / assetHeight === 0 ? '16/9' : assetWidth / assetHeight;
    const pleoCategory = data?.pleoCategory || '';
    const categories = data?.categories || [];
    const categoriesWithoutDashes = categories.map((item) => item.replace(/-/g, ' '));
    const readArticleButtonText = globals?.readArticleButtonText || '';
    const {push} = useRouter();
    const readingTimeText = locale === 'pl' ? 'przeczytasz w' : 'read';
    const readTimeFullText = locale === 'pl' ? `${readingTimeText} ${readingTimeInMinutes} min` : `${readingTimeInMinutes} min ${readingTimeText}`;
    const handleOnClick = (slug) => {
        if(!isCurrentPageGroup) {
            // const newWindow = window.open(`https://pleo-ultimate-monorepo-group.vercel.app${locale=== 'pl' ? '/pl/' :'/'}news/${slug}`, '_blank', 'noopener,noreferrer')
            // if (newWindow) newWindow.opener = null
        } else {
            push(`/news/${slug}`);
        }
    };
    const [hover, setHover] = useState(false)
    const handleMouseEnter = () => {
        setHover(true);
    };
    const handleMouseLeave = () => {
        setHover(false);
    };
    return (
        <>
            <SingleThumbnailWrapper
                onPointerEnter={handleMouseEnter}
                onPointerLeave={handleMouseLeave}
                // onClick={() => handleOnClick(slug)}
                onClick={ isCurrentPageGroup ? () => handleOnClick(slug) : handleClick}
                key={title}
                className={hover ? 'hover desktop' : "desktop"}
            >
                <StyledCardWithTextsWrapper>
                    <SingleCardContainer>
                        {!hidePleoTags && <StyledPleoTag className={`${pleoCategory} ${buttonVariant}`}>pleo {pleoCategory.split('-')[1]}</StyledPleoTag>}
                        <SingleCard className={`${hover ? 'hover' : ''} `} aspectRatio={aspectRatio}>
                            <Image aspectRatio={aspectRatio} src={assetUrl} sizes={"100%"}/>
                        </SingleCard>
                    </SingleCardContainer>
                    <CategoriesWrapper>
                        {categoriesWithoutDashes.map((item) => <p className="category" key={item}>{item}</p>)}
                    </CategoriesWrapper>
                    <StyledReadTimeAndDateWrapper>
                        <p className="read-time">{readTimeFullText}</p>
                        <DateInfo isBlog date={createdAt} locale={locale}/>
                    </StyledReadTimeAndDateWrapper>
                    <p className={`${hover ? 'hover title' : 'title'} `}><span>{title}</span></p>
                </StyledCardWithTextsWrapper>
                <Button
                    className={`${hover ? 'hover' : ''} `}
                    variant={buttonVariant}>{readArticleButtonText}</Button>
            </SingleThumbnailWrapper>
            <SingleThumbnailWrapper onClick={() => handleOnClick(slug)} className="mobile" key={`mobile-${title}`}>
                <SingleCard className="mobile" aspectRatio={aspectRatio}>
                    <Image aspectRatio={aspectRatio} src={assetUrl} sizes={"100%"}/>
                </SingleCard>
                <StyledCardWithTextsWrapper className="mobile">
                    <CategoriesWrapper className="mobile">
                        {categoriesWithoutDashes.map((item) => <p className="category" key={item}>{item}</p>)}
                    </CategoriesWrapper>
                    <p className="title-mobile">{title}</p>
                    <StyledReadTimeAndDateWrapper>
                        <p className="read-time mobile">{readTimeFullText}</p>
                        <DateInfo isBlog className='mobile-card' date={createdAt} locale={locale}/>
                    </StyledReadTimeAndDateWrapper>
                </StyledCardWithTextsWrapper>
            </SingleThumbnailWrapper>
        </>
    );
};

export default SingleArticleCard;
