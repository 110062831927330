import { useCallback, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import CommonImage from './Image';
import CommonVideo from './Video';
import HorizontalHeader from "./HorizontalHeader";
import FloatingVideo from "../WebGL/FloatingVideo";
import MockupVideo from "../../assets/video/band-white-horizontal.mp4";
import PageContainer from "../pageContainer/PageContainer";
import {
	getColorScheme,
	getPaddingsClassFromData,
	getIdWithoutSpecialSymbols,
	setCssVars
} from "@repo/utils";
import  {useScaleFadeIn} from "../../animations/ScaleFadeIn";
import { gsap, useGSAP } from '@repo/utils/gsap'
import useAnimationDebug from "../../animations/useAnimationDebug";
import {CURSOR_TYPES} from "@repo/utils/constants";
import {useCursor} from "../Cursor/Cursor";
import {useIsDesktop, useWindowSize} from "../../../index";
import useAnimationFrame from "../../hooks/useAnimationFrame";
import {easing} from "maath";
import dynamic from "next/dynamic";
import {FullScreenCursor} from "./FullScreenVideo";

const FullScreenVideo = dynamic(() => import('./FullScreenVideo'), {
	ssr: false,
	loading: () => null
})


const StyledSingleAssetWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	position: relative;
	background: ${({$backgroundColor}) => $backgroundColor ? $backgroundColor : 'transparent'};
	&.full {
		width: 100%;
	}
	&.center {
		width: ${({assetWidth}) => assetWidth === 0 ? '100%' : `${assetWidth}px`};
		//max-width: 100%;
		max-width: calc(100% - 2 * var(--paddingX));
		margin: 0 auto;
	}
	&.expand-on-scroll-wrapper {
		place-items: center;
		width: 100%;
		height: auto;
		//background: var(--secondary-color)
		max-width: unset;
		margin: 0;
		aspect-ratio: ${({$aspectRatio}) => $aspectRatio ? $aspectRatio : 16/9};
	}

	//fixes edge case scenario where top of the element on init is within or exactly at scroll trigger boundaries
	//so intersection does not get detected thus not firing actual animation
	//and since animation initial state is applied, object ends up being hidden
	margin-top: ${({$backgroundColor}) => $backgroundColor ? '-1px' : '1px'} !important;
`;

const StyledCarouselTextWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
	width: 100%;
	opacity: 0.33;
`;

const Video = styled(CommonVideo)`
	aspect-ratio: ${({ $aspectRatio }) => $aspectRatio || '16/9'};
	overflow: hidden;
	position: relative;
	cursor: ${({ theme }) => (theme.mode !== 'a11y' ? 'none' : 'auto')};

	// Fix stutter effect on safari
	&:after {
		content: '';
		width: 100%;
		height: 100%;
		background-color: transparent;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 10;
	}

	iframe {
		pointer-events: none;
		position: relative;
	}
	
	&.expand-on-scroll {
		iframe{
			//fixes issue on iphone
			margin: 0 -1%; 
			translate: 1% 0;
			scale: 1.02;
		}
	}
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		&.expand-on-scroll {
			iframe{
				margin: unset;
				scale: unset;
				translate: unset;
			}
			height: auto;
			width: 50%;
		}
	}
`

const Image = styled(CommonImage)`
	aspect-ratio: ${({ $aspectRatio }) => $aspectRatio || '16/9'};
	overflow: hidden;
	position: relative;
	cursor: ${({ theme }) => (theme.mode !== 'a11y' ? 'none' : 'auto')};
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		&.expand-on-scroll {
			height: auto;
			width: 50%;
		}
	}
`

const ThreeImageContainer = styled.div`
	
`

const ThreeImageWrapper = styled.div`
    width: 100%;
    aspect-ratio: ${({ aspectRatio }) => aspectRatio};
    height: auto;
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        width: 50%;
    }
`



export const Asset = ({ id, asset, aspectRatio, data, sizes='100vw', videoTrigger= { current: undefined }, videoPlaying=true,...props }) => {
	const assetContentType = asset?.mimeType || '';
	const assetUrl = asset?.url || '';
	const externalVideoUrl = data?.externalVideoUrl?.url || '';
	const isAssetTypeImage = (assetContentType && assetContentType.includes('image')) || false;
	const isExternalVideoAdded = externalVideoUrl !== '';
	const finalVideoUrl = isExternalVideoAdded ? externalVideoUrl : assetUrl;
	const animationExpandOnScroll = data?.animationExpandOnScroll
	const ref = useRef()
	const debug = useAnimationDebug()

	useGSAP(() => {
		if (!animationExpandOnScroll) return

		gsap.to(ref.current, {
			scrollTrigger: {
				trigger: '.expand-on-scroll-wrapper',
				start: 'top top+=15%',
				endTrigger: '.expand-on-scroll-wrapper',
				end: `bottom bottom`,
				scrub: 0.5,
				markers: debug
			},
			width: '101%',
			ease: 'linear'
		})
	}, {
		dependencies: [debug],
		revertOnUpdate: true
	})

	return isAssetTypeImage ?
		(
			<Image
				id={id}
				src={assetUrl}
				alt={'asset'}
				sizes={sizes}
				$aspectRatio={aspectRatio}
				className={`${animationExpandOnScroll ? 'expand-on-scroll' : ''}`}
				ref={ref}
				{...props}
			/>
		) :
		(
			<Video
				id={id}
				src={finalVideoUrl}
				autoplay={videoPlaying}
				$aspectRatio={aspectRatio}
				target={videoTrigger}
				className={`${animationExpandOnScroll ? 'expand-on-scroll' : ''}`}
				ref={ref}
				{...props}
			/>
		)
}

const SingleAsset = ({ data }) => {
	const id = getIdWithoutSpecialSymbols(data?.id);
	const asset = data?.asset || '';
	const carouselText = data?.carouselText || '';
	const animation3d = data?.animation3d || false;
	const colorScheme = getColorScheme(data)
	const paddings = getPaddingsClassFromData(data)
	const assetVariant = data?.assetVariant || 'full-with-paddings';
	const assetWidth = asset?.width || 0;
	const assetHeight = asset?.height || 1;
	const aspectRatio = assetWidth / assetHeight === 0 ? '16/9' : assetWidth / assetHeight;
	const windowSize = useWindowSize()
	const animationExpandOnScroll = data?.animationExpandOnScroll
	const fullScreenData = useMemo(() => {
		const src = data?.fullScreenVideo?.link?.url || ''
		return ({
			enabled: Boolean(data?.fullScreenVideo),
			src
		})
	}, [data])
	const backgroundHex = data?.backgroundColor?.hex || null
	const bg = data?.background || null
	const imageRef = useRef()
	const AssetRef = useRef()
	const CursorRef = useRef()
	const [fullScreen, setFullScreen] = useState(false)
	const isDesktop = useIsDesktop()
	const cursor = useCursor()
	const CursorPos = useRef({
		x: 0,
		y: 0
	})
	const CursorPrevPos = useRef({
		x: 0,
		y: 0
	})

	useScaleFadeIn({
		el: AssetRef,
		onInView: true
	})

	const handleOpenFullScreenVideo = useCallback(() => {
		if(!fullScreenData.enabled) return
		setFullScreen(true)
	},[fullScreenData.enabled])

	const handleCloseFullScreenVideo = useCallback(() => {
		if(!fullScreenData.enabled) return
		setFullScreen(false)
	},[fullScreenData.enabled])



	const handleOnMouseMove = useCallback((e) => {
		if(!fullScreenData.enabled || !isDesktop) return
		const pos = cursor.position
		const bounds = AssetRef.current.getBoundingClientRect()
		const boundsCenter = bounds.top + bounds.height / 2
		CursorPos.current = {
			x: pos.x,
			y: boundsCenter - windowSize.height/2 + pos.y
		}
	},[fullScreenData.enabled, cursor, windowSize.height])

	const handleOnMouseLeave = useCallback(() => {
		if(!fullScreenData.enabled || !isDesktop) return
		CursorPos.current = {
			x: 0,
			y: 0
		}
	},[fullScreenData.enabled, cursor, isDesktop])

	useAnimationFrame((time, delta) => {
		if(!fullScreenData.enabled || !isDesktop || !CursorRef.current) return
		// console.log(CursorPos.current.x, CursorPrevPos.current.x);
		const diff = Math.abs(CursorPrevPos.current.x - CursorPos.current.x)
		if(diff < 0.0001) return
		easing.damp2(CursorPrevPos.current, CursorPos.current, 0.1, delta)
		setCssVars(CursorRef.current, { x: `${CursorPrevPos.current.x}px`, y: `${CursorPrevPos.current.y}px`});
	},[fullScreenData.enabled, isDesktop, CursorRef])


	return !animation3d ? (
			<PageContainer id={id} data-color-scheme={colorScheme} className="full-width relative" >
				{carouselText !== '' && (
					<StyledCarouselTextWrapper>
						<HorizontalHeader carouselText={carouselText} id={id}/>
					</StyledCarouselTextWrapper>
				)}

				<StyledSingleAssetWrapper
					ref={AssetRef}
					assetWidth={assetWidth}
					$aspectRatio={aspectRatio}
					className={(assetVariant === 'full-with-paddings') ? `${paddings} ${assetVariant} ${animationExpandOnScroll ? 'expand-on-scroll-wrapper' : 'px-main'}` : assetVariant === 'center' ?  `${paddings} ${assetVariant} ${animationExpandOnScroll ? 'expand-on-scroll-wrapper' : ''}` : `${paddings} ${assetVariant}`}
					$backgroundColor={backgroundHex}
					data-cursor={fullScreenData.enabled ? CURSOR_TYPES.HIDE : undefined}
					onClick={handleOpenFullScreenVideo}
					onPointerMove={handleOnMouseMove}
					onPointerLeave={handleOnMouseLeave}
				>
				<Asset
					id={id}
					key={asset.id}
					aspectRatio={aspectRatio}
					asset={asset}
					data={data}
					videoTrigger={AssetRef}
					videoPlaying={!fullScreen}
				/>
				{fullScreenData.enabled && <FullScreenCursor ref={CursorRef} /> }
				</StyledSingleAssetWrapper>
				{fullScreenData.enabled && (
					<FullScreenVideo
						src={fullScreenData.src}
						isOpen={fullScreen}
						handleClose={handleCloseFullScreenVideo}
					/>
				)}
			</PageContainer>
		) : (
			<ThreeImageContainer id={id} className={`${paddings}`}>
				<ThreeImageWrapper aspectRatio={aspectRatio}>
					<FloatingVideo
						ref={imageRef}
						src={asset?.url || ''}
						video={MockupVideo}
						isSingleAssetImage
						// video={videoUrl}
					/>
				</ThreeImageWrapper>
			</ThreeImageContainer>
		)
};

export default SingleAsset;
