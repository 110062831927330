import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import styled from 'styled-components';
import {useCursor} from "../Cursor/Cursor";
import { ScrollTrigger, gsap, useGSAP, ScrollToPlugin } from '@repo/utils/gsap'
import {getCssSizeValue, getFontSize, getHtmlFromRedactorField, setCssVars} from "@repo/utils";
import {BREAKPOINTS} from "../../../styles/themeSettings";
import {ServiceNavigationBlobbyWave, ShortArrowLeft, ShortArrowRight} from "../common/SVGR";
import Button from "../common/Button";
import {
    useExtendedTheme,
    useIsDesktop,
    useIsMobile,
    useLoadedManager,
    useScrollContext,
    useWindowSize
} from "../../../index";
import revealAnimationContainer from "../common/RevealAnimationContainer";
import BlobPortal from "./Blob";
import PortalLens from "../common/StaticLens/PortalLens";
import BandVideo from '../../assets/video/band-white-square.mp4'
import Icon from "../common/Icon/Icon";
import {CURSOR_TYPES, ICONS} from "@repo/utils/constants";
import Transition from "../common/StaticLens/Transition";
import Tag from "../common/Tag";
import {AnimatePresence, motion} from "framer-motion";
import {useRouter} from "next/router";
import useIsTablet from "../../hooks/useIsTablet";
import useMobileDevice from "../../hooks/useMobileDevice";
import useMediaQuery from "../../hooks/useMediaQuery";
import Pizza from "./Piece";

function hexToRgba(hex, alpha = 1) {
    hex = hex.replace('#', '');
    let r, g, b;
    if (hex.length === 3) {
        r = parseInt(hex[0] + hex[0], 16);
        g = parseInt(hex[1] + hex[1], 16);
        b = parseInt(hex[2] + hex[2], 16);
    } else if (hex.length === 6) {
        r = parseInt(hex.substring(0, 2), 16);
        g = parseInt(hex.substring(2, 4), 16);
        b = parseInt(hex.substring(4, 6), 16);
    } else {
        throw new Error('Invalid hex color');
    }
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}


export const ID = 'areas-of-expertise-module'

const REVEAL_ANIMATION_TARGET_CLASS = 'areas-of-expertise-reveal-up-animation-target';

const Wrapper = styled.div`
    position: relative;
    width: 100%;
`

const StyledAreasOfExpertiseContainer = styled.div`
    width: 100%;
    height: ${({$height}) => $height ? $height : 120}vh;
    overflow: hidden;
    //background: ${({$backgroundColor, theme}) => $backgroundColor ? $backgroundColor : theme.colors.lightGray};
    position: relative;
    display: block;
    @media(min-width: 1024px) {
        display: block;
    }
`

const StyledAreasOfExpertisePinnedWrapper = styled.div`
    //background-color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
`

const StyledHeaderContainer = styled.div`
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`

const StyledHeader = styled.div`
	width: 100%;
    opacity: 0;
    transform: translateY(100px);
    text-wrap: nowrap;
    //position: absolute;
    // top: ${getCssSizeValue(70, BREAKPOINTS.MOBILE)};
	color: var(--secondary-color);
	//font-weight: ${({ theme }) => theme.fontWeight ? theme.fontWeight.semiBold : 600};
    font-weight: ${({theme}) => theme.fontWeight.regular};
    font-size: ${getFontSize(30, BREAKPOINTS.MOBILE)};
    text-transform: uppercase;
    text-align: center;
    line-height: normal;
    //margin-bottom: ${getCssSizeValue(33, BREAKPOINTS.MOBILE)};
    letter-spacing: -1.6px;
    position: absolute;
    top: ${getCssSizeValue(60, BREAKPOINTS.MOBILE)};
    em {
        font-weight: ${({ theme }) => theme.fontWeight.light};
        font-style: italic;
    }
    @media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        //top: ${getCssSizeValue(100, BREAKPOINTS.TABLET)};
        font-size: ${getFontSize(55, BREAKPOINTS.TABLET)};
        margin-bottom: ${getCssSizeValue(35, BREAKPOINTS.TABLET)};
        //margin-top: ${getCssSizeValue(35, BREAKPOINTS.TABLET)};
        letter-spacing: -3px;
        position: absolute;
        top: ${getCssSizeValue(75, BREAKPOINTS.TABLET)};
    }
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        text-wrap: initial;
        text-align: left;
		font-size: ${getFontSize(61, BREAKPOINTS.DESKTOP)};
        line-height: ${getFontSize(68, BREAKPOINTS.DESKTOP)};
        letter-spacing: -2.44px;
        //padding-left: calc(${getCssSizeValue(115, BREAKPOINTS.DESKTOP)} - var(--paddingX));
        //padding-left: ${getCssSizeValue(115, BREAKPOINTS.DESKTOP)};
        //margin-bottom: ${getCssSizeValue(70, BREAKPOINTS.DESKTOP)};
        margin-bottom: 0;
        margin-top: 0;
        //position: absolute;
        // top: ${getCssSizeValue(60, BREAKPOINTS.DESKTOP)};
        position: relative;
        top: unset;
	}
    @media(min-width: 1440px){
        margin-bottom: ${getCssSizeValue(70, BREAKPOINTS.DESKTOP)};
        //top: ${getCssSizeValue(10, BREAKPOINTS.DESKTOP)};
        top: unset;
        @media(min-height: 1000px) {
            top: unset;
            // top: ${getCssSizeValue(70, BREAKPOINTS.DESKTOP)};
        }
    }
`;

const StyledSingleServiceWrapper = styled.div`
    width: 100%;
    //background-color: ${({bgColor}) => bgColor};
    height: 100vh;
    display: flex;
    cursor: pointer;
    //padding: 10svh 0;
    flex-direction: column;
    justify-content: center;
    //justify-content: flex-start;
    padding: 30px 0;
    align-items: center;
    position: relative;
    @media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        padding: 50px 0 15px;
    }
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        flex-direction: row;
        justify-content: center;
        //justify-content: space-between;
        align-items: center;
        padding: 0;
        //padding-left: var(--paddingX);
        //padding-right: var(--paddingX);
    }
`

const StyledLeftPartContainer = styled.div`
    //height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    //padding-top: 100px;
    //justify-content: space-between;
    //padding-top: calc((100vh - 254px) / 2);
    //padding-bottom: calc((100vh - 254px) / 2);
    //z-index: 16;
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        display: flex;
        height: 100%;
        //width: auto;
        width: calc((100% - 500px) / 2);
        align-items: flex-start;
        justify-content: space-between;
        padding-top: calc((100vh - 500px) / 2);
        padding-bottom: calc((100vh - 500px) / 2);
        padding-left: var(--paddingX);
    }
    @media(min-width: 1440px) {
        padding-left: var(--paddingX);
        //width: calc((100% - 676px - 2 * var(--paddingX)) / 2);
        padding-top: calc((100vh - 642px) / 2);
        padding-bottom: calc((100vh - 642px) / 2);
        width: calc((100% - 676px) / 2);
        position: relative;
        z-index: 16;
    }
`

const StyledRightPartContainer = styled.div`
    position: relative;
    flex-direction: column;
    //justify-content: flex-start;
    justify-content: center;
    //padding-top: calc((100vh - 254px) / 2);
    //padding-bottom: calc((100vh - 254px) / 2);
    padding-left: var(--paddingX);
    padding-right: var(--paddingX);
    z-index: 16;
    display: flex;
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        display: flex;
        padding-left: 0;
        //padding-right: 0;
        height: 100%;
        //padding-top: calc((100vh - 642px) / 2);
        //padding-bottom: calc((100vh - 642px) / 2);
        padding-right: var(--paddingX);
        width: calc((100% - 500px) / 2);
    }
    @media(min-width: 1440px) {
        padding-right: var(--paddingX);
        //width: calc((100% - 676px - 2 * var(--paddingX)) / 2);
        width: calc((100% - 676px) / 2);
    }
`


const ServiceNameContainer = styled.div`
    position: relative;
    overflow: hidden;
    opacity: 0;
    width: calc(100% - 2 * var(--paddingX));
    margin-top: 57px;
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        //width: calc((100% - 476px - 2 * var(--paddingX)) / 2);
        width: 100%;
        margin-top: 0;
        padding-right: 5px;
    }
    @media(min-width: 1440px) {
        //width: calc((100% - 676px - 2 * var(--paddingX)) / 2);
        width: 100%;
    }
`

const StyledServiceNameWrapper = styled(motion.div)`
    //width: ${getCssSizeValue(400, BREAKPOINTS.DESKTOP)};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px 0;
    //text-align: right;
    //width: calc((100% - 476px - 2 * var(--paddingX)) / 2);
    //width: calc(100% - 2 * var(--paddingX));
    margin-bottom: ${getCssSizeValue(40, BREAKPOINTS.MOBILE)};
    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        //margin-bottom: ${getCssSizeValue(35, BREAKPOINTS.TABLET)};
        margin-bottom: ${getCssSizeValue(10, BREAKPOINTS.TABLET)};
    }
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        //width: calc((100% - 476px - 2 * var(--paddingX)) / 2);
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        text-align: left;
        margin-bottom: 0;
        row-gap:  ${getCssSizeValue(23, BREAKPOINTS.DESKTOP)};
    }
    @media(min-width: 1440px) {
        //width: calc((100% - 676px - 2 * var(--paddingX)) / 2);
        width: 100%;
    }
`

const ServiceIndex = styled.div`
    width: ${getCssSizeValue(29, BREAKPOINTS.MOBILE)};
    height: ${getCssSizeValue(29, BREAKPOINTS.MOBILE)};
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
    line-height: normal;
    margin-right: ${getCssSizeValue(14, BREAKPOINTS.MOBILE)};
    border: ${({$accentColor}) => `2px solid ${$accentColor}`};
    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        font-size: ${getFontSize(14, BREAKPOINTS.TABLET)};
        width: ${getCssSizeValue(37, BREAKPOINTS.TABLET)};
        height: ${getCssSizeValue(37, BREAKPOINTS.TABLET)};
        margin-right: ${getCssSizeValue(21, BREAKPOINTS.TABLET)};
    }
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        width: ${getCssSizeValue(35, BREAKPOINTS.DESKTOP)};
        height: ${getCssSizeValue(35, BREAKPOINTS.DESKTOP)};
        font-size: ${getFontSize(14, BREAKPOINTS.DESKTOP)};
        margin-right: ${getCssSizeValue(35, BREAKPOINTS.DESKTOP)};
    }
`

const ServiceName = styled.div`
    //color: var(--secondary-color);
    color: ${({$accentColor}) => `${$accentColor}`};
    text-transform: lowercase;
    font-size: ${getFontSize(22, BREAKPOINTS.MOBILE)};
    line-height: ${getFontSize(30, BREAKPOINTS.MOBILE)};
    font-weight: ${({theme}) => theme.fontWeight.regular};
    letter-spacing: -0.88px;
    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        font-size: ${getFontSize(36, BREAKPOINTS.TABLET)};
        line-height: ${getFontSize(45, BREAKPOINTS.TABLET)};
        letter-spacing: -1.44;
    }
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        font-size: ${getFontSize(48, BREAKPOINTS.DESKTOP)};
        line-height: ${getFontSize(48, BREAKPOINTS.DESKTOP)};
        letter-spacing: -1.92px;
    }
`

const ServiceDescription = styled.div`
    display: none;
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        text-align: left;
        display: initial;
        color: var(--secondary-color);
        font-size: ${getFontSize(18, BREAKPOINTS.DESKTOP)};
        line-height: ${getFontSize(31, BREAKPOINTS.DESKTOP)};
        padding-left: ${({$paddingLeft}) => `${$paddingLeft}px`};
        padding-right: ${getCssSizeValue(25, BREAKPOINTS.DESKTOP)};
    }
`

const StyledVideoMockupForMobile = styled.div`
    display: block;
    position: relative;
    width: 254px;
    height: 254px;
    border-radius: 50%;
    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        width: 360px;
        height: 360px;
    }
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        display: none;
    }
`

const StyledVideoWrapper = styled.div`
    //width: 250px;
    //height: 250px;
    width: 270px;
    height: 270px;
    position: absolute;
    background-color: ${(props) => props.bgColor};
    top: calc(50% - 125px - 100px);
    left: calc(50% - 135px);
    //background-color: rgba(25,100,200,0.2);
    opacity: 1;
    border-radius: 50%;
    //overflow: hidden;
    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        width: 360px;
        height: 360px;
        //position: absolute;
        //top: calc(50% - 180px - 100px);
        top: calc(50% - 180px - 50px);
        left: calc(50% - 180px);
        //position: relative;
    }
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        //border: 2px solid red;
        position: relative;
        width: 476px;
        //width: 676px;
        height: 476px;
        top: unset;
        left: unset;
        margin-left: ${getCssSizeValue(76, BREAKPOINTS.DESKTOP)};
        margin-right: ${getCssSizeValue(76, BREAKPOINTS.DESKTOP)};
        //height: 676px;
        // width: ${getCssSizeValue(676, BREAKPOINTS.DESKTOP)};
        // height: ${getCssSizeValue(676, BREAKPOINTS.DESKTOP)};
        &.mobile-device {
            width: 360px;
            height: 360px;
        }
    }
    @media(min-width: 1440px) {
        //width: 642px;
        width: 702px;
        //height: 642px;
        height: 702px;
        background-color: ${(props) => props.bgColor};
        transform: scale(0);
        opacity: 0;
    }
    video {
        object-fit: cover;
        height: 100%;
        //position: absolute;
        //top: 0;
        //left: 0;
        //z-index: 15;
    }
`

const PlaceholderCircle = styled.div`
    position: absolute;
    z-index: 10;
    background-color: ${(props) => props.bgColor};
    width: 254px;
    height: 254px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    //display: none;
    border-radius: 50%;
    overflow: hidden;
    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        width: 336px;
        height: 336px;
    }
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        width: 454px;
        height: 454px;
    }
    @media(min-width: 1440px) {
        width: 678px;
        height: 678px;
    }
    video {
        object-fit: cover;
        height: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 15;
    }
`

const StyledSectionLine = styled.div`
    //width: 344px;
    width: 140px;
    height: 1px;
    background-color: black;
    position: absolute;
    z-index: 1;
    //z-index: 0;
    top: 50%;
    left: 50%;
    transform-origin: 0% 50%;
    transform: rotate(${(props) => props.angle}deg);
    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        width: 190px;
    }
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        width: 250px;
    }
    @media(min-width: 1440px) {
        width: 363px;
    }
`

const StyledRangeContainer = styled.div`
    position: relative;
    overflow: hidden;
    //padding: 0 0 20px;
    opacity: 0;
    width: calc(100%);
    min-height: ${getCssSizeValue(300, BREAKPOINTS.MOBILE)};
    @media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        min-height: ${getCssSizeValue(300, BREAKPOINTS.TABLET)};
        width: calc(100% - 2 * var(--paddingX));
    }
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        //width: calc((100% - 476px - 2 * var(--paddingX)) / 2);
        width: 100%;
        min-height: unset;
        //padding: 50px 0;
        padding: 20px 0;
    }
    @media(min-width: 1440px) {
        //width: calc((100% - 676px - 2 * var(--paddingX)) / 2);
        //width: calc((100% - 676px) / 2);
        width: 100%;
        padding: 0;
    }
`

const StyledRangeWrapper = styled(motion.div)`
    width: 100%;
`

const RangeText = styled.div`
    color: var(--secondary-color);
    font-size: ${getFontSize(16, BREAKPOINTS.MOBILE)};
    line-height: ${getFontSize(30, BREAKPOINTS.MOBILE)};
    margin-bottom: ${getCssSizeValue(22, BREAKPOINTS.MOBILE)};
    font-weight: ${({theme}) => theme.fontWeight.bold};
    letter-spacing: -0.64px;
    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        font-size: ${getFontSize(18, BREAKPOINTS.TABLET)};
        line-height: ${getFontSize(35, BREAKPOINTS.TABLET)};
        margin-bottom: ${getCssSizeValue(27, BREAKPOINTS.TABLET)};
    }
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        margin-bottom: ${getCssSizeValue(38, BREAKPOINTS.DESKTOP)};
        font-size: ${getFontSize(18, BREAKPOINTS.DESKTOP)};
        line-height: normal;
        letter-spacing: -0.72px;
    }
`

const StyledCompetencesTagWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    column-gap: ${getCssSizeValue(8, BREAKPOINTS.MOBILE)};
    row-gap: ${getCssSizeValue(10, BREAKPOINTS.MOBILE)};
    flex-wrap: wrap;
    width: 100%;
    overflow: hidden;
    max-height: calc(${getCssSizeValue(10, BREAKPOINTS.MOBILE)} * 2 + 3 * 35px + 8px);
     margin-bottom: ${getCssSizeValue(25, BREAKPOINTS.MOBILE)};
    //margin-bottom: ${getCssSizeValue(15, BREAKPOINTS.MOBILE)};
    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        margin-bottom: ${getCssSizeValue(20, BREAKPOINTS.TABLET)};
        column-gap: ${getCssSizeValue(10, BREAKPOINTS.TABLET)};
        row-gap: ${getCssSizeValue(10, BREAKPOINTS.TABLET)};
    }
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        overflow: auto;
        max-height: unset;
        //margin-bottom: ${getCssSizeValue(85, BREAKPOINTS.DESKTOP)};
        margin-bottom: 0;
        column-gap: ${getCssSizeValue(10, BREAKPOINTS.DESKTOP)};
        row-gap: ${getCssSizeValue(15, BREAKPOINTS.DESKTOP)};
    }
`

const MoreTags = styled.p`
    color: ${({accentColor}) => `${accentColor}`};
    font-size: ${getFontSize(16, BREAKPOINTS.MOBILE)};
    line-height: ${getFontSize(30, BREAKPOINTS.MOBILE)};
    margin-bottom: ${getCssSizeValue(12, BREAKPOINTS.MOBILE)};
    font-weight: ${({theme}) => theme.fontWeight.bold};
    letter-spacing: -0.64px;
    margin-top: -10px;
    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        font-size: ${getFontSize(18, BREAKPOINTS.TABLET)};
        line-height: ${getFontSize(35, BREAKPOINTS.TABLET)};
        margin-bottom: ${getCssSizeValue(27, BREAKPOINTS.TABLET)};
    }
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        display: none;
        margin-bottom: ${getCssSizeValue(38, BREAKPOINTS.DESKTOP)};
        font-size: ${getFontSize(18, BREAKPOINTS.DESKTOP)};
        line-height: normal;
        letter-spacing: -0.72px;
    }
    
`

const ButtonWrapper = styled.div`
    width: 100%;
    //display: flex;
    display: none;
    flex-direction: row;
    justify-content: center;
    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        justify-content: flex-start;
    }
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        display: none;
        &.mobile-device-landscape {
            display: flex;
            margin-top: 20px;
        }
        //display: flex;
    }
    
`

const ScrollDownInfo = styled.div`
    position: absolute;
    bottom: -80px;
    left: 50%;
    transform: translateX(-50%);
    display: none;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .icon {
        height: 15px;
        opacity: 0;
        transform: translateY(-100px);
    }
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        display: flex;   
    }
    p {
        color: #051D27;
        font-size: 18px;
        font-style: italic;
        font-weight: 600;
        line-height: 24px;
        opacity: 0;
        transform: translateY(-100px);
    }
`

const AbsoluteMaskContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    //background-color: black;
    background-color: transparent;
    //opacity: 1;
    opacity: 0;
    //opacitY: 0.5;
    overflow: hidden;
    //display: none;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 15;
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        background-color: transparent;
        opacity: 0;
        //background-color: black;
        //opacity: 0.5;
        display: flex;
    }
    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`

const VideoWrapper = styled.div`
  position: relative;
  //width: 100vw; /* Adjust based on your needs */
  //height: 100vh;
    width: 80vw;
    height: 80vh;
    transition: all 0.5s ease-in-out 0.2s;
    &.active {
        width: calc(100vw + 200px);
        height: calc(100vh + 200px);
        transition: all 0.6s ease-in-out;
        @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
            width: 100vw;
            height: 100vh;
        }
    }
`;
const Mask = styled.div`
    position: absolute;
    width: 254px;
    //width: 500px;
    height: 254px;
    //height: 500px;
    border-radius: 50%;
    transition: all 0.7s ease-in-out;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    //top: calc(50% - 125px - 100px + 20px);
    //margin-left: 20px;
    margin-bottom: 180px;
    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        width: 336px;
        height: 336px;
    }
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        width: 470px;
        height: 470px;
        margin-bottom: 0;
    }
    @media(min-width: 1440px) {
        width: 676px;
        height: 676px;
    }
    &.active {
        width: 200vw;
        height: 200vh;
        transition: all 0.65s ease-in-out 0.15s;
        transform-origin: 0 200px;
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            transform-origin: 0 130px;
        }
        @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
            transform-origin: initial;
            width: 200vw;
            height: 200vw;
            transition: all 0.65s ease-in-out 0.15s;
        }
    }
`

const ServiceNavigationMobileContainer = styled.div`
    position: absolute;
    top: calc(50% - 125px - 100px + 20px); // 270 - 229 /2
    right: 0;
    transform: translateX(50px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    z-index: 16;
    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        top: calc(50% - 180px - 50px + 66px)
    }
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        display: none;
    }
    &.left {
        right: unset;
        left: 0;
        opacity: 0;
        transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
        transform: translateX(-50px) rotate(180deg);
    }
    &.visible-left {
        opacity: 1;
        transform: translateX(0) rotate(180deg);
        transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    }   
    &.visible {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    }
    svg {
        path {
            fill: ${({$accentColor}) => `${$accentColor}`};
            transition: fill 0s ease-in-out 0.1s;
        }
    }
`


const ArrowContainer = styled.div`
    width: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    svg {
        path {
            fill: white;
        }
    }
    &.left {
        transform: translate(-50%, -50%) rotate(180deg);
    }
`

const AreasOfExpertise = ({data}) => {
    const areasOfExpertiseRef = useRef();
    const pinnedWrapperRef = useRef();
    const BlobRef = useRef();
    const IconWrapperRef = useRef();
    const headingRef = useRef();
    const videoWrapperRef = useRef();
    const piecesRef = useRef();
    const scrollDownRef= useRef();
    const absoluteMaskContainerRef= useRef();
    const heading = data?.heading || '';
    const rangeText = data?.rangePlaceholder || '';
    const buttonText = data?.buttonText || '';
    const services = data?.chooseServices || [];
    const [activeItem, setActiveItem] = useState({...services[0], backgroundColor: null, id: -1})
    const [prevActiveItemId, setPrevActiveItemId] = useState(null)
    const isDesktop = useIsDesktop()
    const isMobileDevice = useMobileDevice()
    const orientationLandscape = useMediaQuery(`(orientation: landscape)`);
    const windowSize = useWindowSize()
    const portalLens = useCursor((state) => state.portalLens)
    const blobScale = windowSize.width > 1440 ? 1 : 0.75;
    const {setTheme, palette} = useExtendedTheme()
    const [initPalette, setInitPalette] = useState(palette)
    const router = useRouter();
    const [blobVideoSource, setBlobVideoSource] = useState(BandVideo)
    const serviceName = activeItem?.serviceName || ''
    const serviceDescription = activeItem?.serviceDescription || ''
    const serviceSlug = activeItem?.slug || '';
    const areaColorBackground = activeItem?.areaColorBackground?.hex || '#fff'
    const borderColor = activeItem?.borderColor?.hex || '#fff';
    const videoFile = activeItem?.videoFile?.url || '';
    const competences = activeItem?.chooseCompetences || [];
    const externalVideoUrl = activeItem?.externalVideoUrl?.url || '';
    const externalVideoWidth = activeItem?.externalVideoUrl?.width || 1;
    const externalVideoHeight = activeItem?.externalVideoUrl?.height || 1;
    const externalVideoAspectRatio = externalVideoWidth/externalVideoHeight
    const [isBlobVisible, setIsBlobVisible] = useState(true);
    const [isModulePinned, setIsModulePinned] = useState(false);
    // const setTheme = useCallback((props) => {
    //     console.log(props.palette);
    //     setExtendedTheme(props)
    // },[])

    useEffect(() => {
        // portalLens.visible = true
        portalLens.visible = isBlobVisible
    },[isBlobVisible])

    useGSAP(() => {
        ScrollTrigger.create({
            id: 'pinned-areas-of-expertise-wrapper',
            trigger: areasOfExpertiseRef.current,
            start: 'top top',
            end: 'bottom bottom',
            // markers: true,
            pin: pinnedWrapperRef.current,
            pinSpacing: false,
            onEnter: () => {
                // setRotationMultiplier(activeItem.id);
                setIsModulePinned(true);
                setIsBlobVisible(false);
                gsap.to(videoWrapperRef.current, {
                    autoAlpha: 1,
                    duration: 0.6,
                    delay: 0.2,
                    ease: "back.out",
                    scale: 1,
                })
                gsap.to(piecesRef.current.querySelectorAll('.number'), {
                    opacity: isDesktop ? 0.33 : 1,
                    delay: 0.6,
                    scale: 1,
                    // duration: 0.5,
                    stagger: 0.2,
                    ease: "back.out",
                })
                gsap.to(scrollDownRef.current.children, {
                    opacity: 1,
                    stagger: 0.3,
                    delay: 1.5,
                    y: 0,
                    ease: "back.out"
                })
                gsap.to('#service-container', {
                    opacity: 1,
                    duration: 0.2,
                    delay: 0.4,
                    ease: "power2.inOut"
                })
                gsap.to('#range-container', {
                    opacity: 1,
                    duration: 0.2,
                    delay: 0.4,
                    ease: "power2.inOut"
                })
                gsap.to(headingRef.current, {
                    translateY: "0",
                    opacity: 1,
                    duration: 0.35,
                    delay: 0.2,
                    ease: "back.out"
                })
                if(isDesktop) {
                    // gsap.to(headingRef.current, {
                    //     translateY: "0",
                    //     opacity: 1,
                    //     duration: 0.35,
                    //     delay: 0.2,
                    //     ease: "back.out"
                    // })
                    // if(windowSize.height < 850) {
                    if(windowSize.height < 720) {
                        gsap.to(headingRef.current, {
                            translateY: "-200px",
                            duration: 0.2,
                            delay: 1,
                            ease:  "power2.inOut"
                        })
                    }
                }
            },
            onLeaveBack: () => {
                setIsBlobVisible(true);
                setIsModulePinned(false)
                setBlobVideoSource(BandVideo)
                setTheme({
                    palette: initPalette
                })
                gsap.to(videoWrapperRef.current, {
                    autoAlpha: 0,
                    duration: 0.3,
                    // ease:"power2.in",
                    scale: 0,
                })
                gsap.to(piecesRef.current.querySelectorAll('.number'), {
                    opacity: 0,
                    delay: 0.6,
                    scale: 5,
                    // duration: 0.5,
                    stagger: 0.15,
                    ease: "back.out",
                })
                gsap.to(scrollDownRef.current.children, {
                    opacity: 0,
                    stagger: 0.3,
                    delay: 1,
                    y: -100,
                    ease: "back.out"
                })
                gsap.to(IconWrapperRef.current, {
                    opacity: 0,
                    duration: 0,
                    ease: "none"
                })
            },
            onLeave: () => {
                setBlobVideoSource(BandVideo)
                setIsModulePinned(false);
                setTheme({
                    palette: initPalette
                })
                gsap.to(IconWrapperRef.current, {
                    opacity: 0,
                    duration: 0,
                    ease: "none"
                })
                if(isDesktop && windowSize.height >= 850 || !isDesktop) {
                    gsap.to(headingRef.current, {
                        translateY: "-200px",
                        duration: 0.2,
                        delay: 0.1,
                        ease:  "power1.inOut"
                    })
                }
                gsap.to('#service-wrapper', {
                    translateY: "-200px",
                    opacity: 0,
                    duration: 0.3,
                    delay: 0.1,
                    ease: "power1.inOut"
                })
                gsap.to('#range-wrapper', {
                    translateY: "-150px",
                    opacity: 0,
                    duration: 0.3,
                    delay: 0.1,
                    ease: "power1.inOut"
                    // ease: "none"
                })
            },
            onEnterBack: () => {
                setIsModulePinned(true);
                setTheme({
                    palette: {
                        'primary': services[services.length - 1].areaColorBackground?.hex,
                        'secondary': initPalette.secondary,
                        'tertiary': initPalette.tertiary
                    }
                })
                setBlobVideoSource(services[services.length - 1].videoFile.url)
                // console.log('on enter back');
                // setBlobVideoSource(services[services.length - 1].videoFile.url)
                gsap.to(IconWrapperRef.current, {
                    opacity: 1,
                    duration: 0,
                    ease: "none"
                })
                gsap.to('#range-wrapper', {
                    translateY: "0",
                    opacity: 1,
                    duration: 0.4,
                    ease: "power1.inOut"
                })
                gsap.to('#service-wrapper', {
                    translateY: "0",
                    opacity: 1,
                    duration: 0.4,
                    delay: 0.2,
                    ease: "power1.inOut"
                })
                if(isDesktop && windowSize.height >= 850 || !isDesktop) {
                    gsap.to(headingRef.current, {
                        translateY: "0",
                        duration: 0.4,
                        delay: 0.6,
                        ease: "power1.inOut"
                    })
                }
            }
        })

        services.forEach((item, index) => {
            // const startOffset= (index / services.length) * 90
            const startOffset= (index / services.length) * 90
            ScrollTrigger.create({
                id: `service-index-${index}`,
                trigger: areasOfExpertiseRef.current,
                start: `top+=${startOffset}% top`,
                endTrigger: areasOfExpertiseRef.current,
                invalidateOnRefresh: true,
                // markers: true,
                onEnter: () => {
                    // console.log('enter', index);
                    if(index === 0) {
                        gsap.to(IconWrapperRef.current, {
                            opacity: 1,
                            duration: 0,
                            ease: "none"
                        })
                        // setTheme({palette: {
                        //         'primary': item.areaColorBackground?.hex,
                        //         'secondary':initPalette.secondary,
                        //         'tertiary': initPalette.tertiary
                        //     }
                        // })
                    }
                    setPrevActiveItemId(index - 1)
                    setActiveItem({...services[index], id: index})
                    setBlobVideoSource(services[index].videoFile.url)
                    // setTimeout(() => {
                    //     setTheme({palette: {
                    //             'primary': item.areaColorBackground?.hex,
                    //             'secondary':initPalette.secondary,
                    //             'tertiary': initPalette.tertiary
                    //         }
                    //     })
                    // },500)

                    setTimeout(() => {
                        setTheme({palette: {
                                'primary': item.areaColorBackground?.hex,
                                'secondary':initPalette.secondary,
                                'tertiary': initPalette.tertiary
                            }
                        })
                    },70)
                },
                onLeaveBack: () => {
                    // console.log('leave back', index);
                    if (index === 0) {
                        setActiveItem({...services[0], backgroundColor: null, id: -1})
                        setBlobVideoSource(BandVideo)
                        setTheme({
                            palette: initPalette
                        })
                        gsap.to(IconWrapperRef.current, {
                            opacity: 0,
                            duration: 0,
                            ease: "none"
                        })
                        gsap.to('#service-container', {
                            opacity: 0,
                            duration: 0.2,
                        })
                        gsap.to('#range-container', {
                            opacity: 0,
                            duration: 0.2,
                        })
                        gsap.to(headingRef.current, {
                            translateY: "100px",
                            opacity: 0,
                            duration: 0.25,
                            delay: 0,
                            ease:  "power3.inOut"
                        })
                        if(isDesktop) {
                            if(windowSize.height < 850) {
                                gsap.to(headingRef.current, {
                                    opacity: 0,
                                    duration: 0,
                                    delay: 0,
                                })
                            }
                            // gsap.to(headingRef.current, {
                            //     translateY: "100px",
                            //     opacity: 0,
                            //     duration: 0.25,
                            //     delay: 0,
                            //     ease:  "power3.inOut"
                            // })
                        }
                    } else {
                        setActiveItem({...services[index-1], id: index - 1})
                        setBlobVideoSource(services[index-1].videoFile.url)
                        // setTimeout(() => {
                        //     setTheme({
                        //         palette: {
                        //             'primary': services[index - 1].areaColorBackground?.hex,
                        //             'secondary': initPalette.secondary,
                        //             'tertiary': initPalette.tertiary
                        //         }
                        //     })
                        // },500)

                        setTheme({
                            palette: {
                                'primary': services[index - 1].areaColorBackground?.hex,
                                'secondary': initPalette.secondary,
                                'tertiary': initPalette.tertiary
                            }
                        })
                    }
                },
                onLeave: () => {
                    // console.log('leave', index);
                    if(index === services.length - 1) {
                            gsap.to(IconWrapperRef.current, {
                                opacity: 0,
                                duration: 0,
                                ease: "none"
                            })
                        setBlobVideoSource(BandVideo);
                        setTheme({
                            palette: initPalette
                        })
                    }
                },
            })
        })
    },{ scope: areasOfExpertiseRef, dependencies: [windowSize.width], revertOnUpdate: true })

    // MOBILE LOGIC
    const Step1ProgressRef = useRef(0)
    const context = useMemo(() => ({
        step1: Step1ProgressRef,
        lensVisible: true,
    }), [])
    const updateContext = useCallback(({ progress }) => {
        context.step1.current = progress
    },[context, isDesktop])
    const [isMobileBlobVisible, setIsMobileBlobVisible] = useState(false);
    const isTablet = useIsTablet();
    const isMobile = useIsMobile()
    const scroll = useScrollContext()
    const loaded = useLoadedManager()

    useGSAP(() => {
        if(!isDesktop){
            ScrollTrigger.create({
                id: `pin-blob-${ID}`,
                // trigger: BlobRef.current,
                trigger: BlobRef.current,
                endTrigger: areasOfExpertiseRef.current,
                // start: 'top top+=21%',
                // start: isTablet ? 'center center-=50px' : 'center center-=100px',
                start: isTablet && orientationLandscape ? 'center center' : isTablet && !orientationLandscape ? 'center center-=50px' : 'center center-=100px',
                // start: 'center center',
                end: 'bottom bottom',
                pin: true,
                // markers: true,
                onEnter: () => {
                  setIsMobileBlobVisible(true)
                },
                onEnterBack: () => {
                    setIsMobileBlobVisible(true)
                },
                onLeave: () => {
                    setIsMobileBlobVisible(false)
                },
                onUpdate: () => {
                    setCssVars(BlobRef.current, { 'pg-step1': context.step1.current })
                }
            })
        }
    }, {
        dependencies: [isDesktop, orientationLandscape, windowSize.width],
        revertOnUpdate: true,
    })
    const handleServiceClick = (e) => {
        const { target } = e
        const isTag = target.classList.contains('tag')
        if (isTag) return
        return router.push(`/services/${serviceSlug}`)
    }

    const handleNextService = () => {

        if(activeItem.id < services.length - 1) {
            setActiveItem({...services[activeItem.id + 1], id: activeItem.id + 1})
            setTimeout(() => {
                setTheme({palette: {
                        'primary': services[activeItem.id + 1].areaColorBackground?.hex,
                        'secondary':initPalette.secondary,
                        'tertiary': initPalette.tertiary
                    }
                })
            },70)
            gsap.to(window, {
                scrollTo: {
                    y: 50 + (areasOfExpertiseRef?.current?.getBoundingClientRect()?.top + window.scrollY) + 3 * 0.9 * window.innerHeight * (activeItem.id + 1), // Adjust based on sections
                    autoKill: false,
                },
                duration: 1,
                onComplete: () => ScrollTrigger.refresh(),
            });
        } else {
            gsap.to(window, {
                scrollTo: {
                    y: 1.9 * window.innerHeight + (areasOfExpertiseRef?.current?.getBoundingClientRect()?.top + window.scrollY) + 3 * 0.9 * window.innerHeight * (services.length), // Adjust based on sections
                    autoKill: false,
                },
                duration: 1,
                onComplete: () => ScrollTrigger.refresh(),
            });
        }
    }
    const handlePreviousService = () => {
        if(activeItem.id >= 1) {
            setActiveItem({...services[activeItem.id - 1], id: activeItem.id - 1})
            setTimeout(() => {
                setTheme({palette: {
                        'primary': services[activeItem.id - 1].areaColorBackground?.hex,
                        'secondary':initPalette.secondary,
                        'tertiary': initPalette.tertiary
                    }
                })
            },70)
            gsap.to(window, {
                scrollTo: {
                    y: 50 + (areasOfExpertiseRef?.current?.getBoundingClientRect()?.top + window.scrollY) + 3 * 0.9 * window.innerHeight * (activeItem.id - 1),
                    autoKill: false,
                },
                duration: 1,
                onComplete: () => ScrollTrigger.refresh(),
            });
        } else {
            gsap.to(window, {
                scrollTo: {
                    y: areasOfExpertiseRef?.current?.getBoundingClientRect()?.top,
                    autoKill: false,
                },
                duration: 1,
                onComplete: () => ScrollTrigger.refresh(),
            });
        }
    }

    // const handleTagClick = (e, slug) => {
    //     e.stopPropagation();
    //     router.push(slug)
    // }

    const serviceNameWrapperRef = useRef(null)
    const serviceNameWrapperLeftBound = useRef(0)


    useEffect(() => {
        if (!serviceNameWrapperRef.current) return
        const offsetLeft = serviceNameWrapperRef.current.offsetLeft
        serviceNameWrapperLeftBound.current = offsetLeft
    }, [serviceNameWrapperRef.current, windowSize.width]);


    //scroll to section if id is in url
    useEffect(() => {
        const { webgl, html } = loaded
        if(!webgl || !html) return
        const hash = router.asPath.split('#')[1]; //find id
        if(!hash) return
        //findIndex of service to scroll to
        const serviceIndex = services.findIndex((service) => service.slug === hash)
        if(serviceIndex < 0) return
        const height = (windowSize?.height || 0) * 2.75
        //calculate value to add to top of wrapper
        let offset = serviceIndex * height
        if(offset === 0){
            //if its first service increment offset to make sure it triggers animation
            offset += 1
        }
        //top of wrapper + estimated section top pos
        const final = areasOfExpertiseRef.current.getBoundingClientRect().top + offset

        // logTable({
        //     target: final,
        //     offset,
        //     serviceIndex,
        //     height
        // })

        //scroll.instance.scrollTo not working on mobile
        window.scroll({
            top: final,
            left: 0,
            behavior: 'instant'
        })
        // console.log('scroll');

    }, [router.asPath, loaded]);


    const [expanded, setExpanded] = useState(false);
    const handleTimeUpdate = (e) => {
        localStorage.setItem("videoTime", e.target.currentTime);
        // console.log('current time');
        // console.log(e.target.currentTime);
    };

    const handleClickMask = () => {
        setExpanded(!expanded);
        absoluteMaskContainerRef.current.style.opacity = 1;
        absoluteMaskContainerRef.current.style.zIndex = 100;
        areasOfExpertiseRef.current.style.zIndex = 100;
        // absoluteMaskContainerRef.current.style.zIndex = 17;
        //TODO odkomentować
        setTimeout(() => {
            router.push(`/services/${serviceSlug}`)
        },600)
    }

    return (
        <Wrapper>
            <StyledAreasOfExpertiseContainer
                ref={areasOfExpertiseRef}
                bgColor={areaColorBackground}
                // $height={services.length * (isDesktop ? 150 : 300)}
                $height={services.length * 300}
                $backgroundColor={activeItem?.areaColorBackground?.hex}
            >
                {/*<FixedActiveItem>{activeItem.id}</FixedActiveItem>*/}
                <StyledAreasOfExpertisePinnedWrapper ref={pinnedWrapperRef}>
                    {/*<StyledHeader dangerouslySetInnerHTML={getHtmlFromRedactorField(heading)}/>*/}
                    <StyledSingleServiceWrapper
                        // onClick={handleServiceClick}
                        // data-cursor={CURSOR_TYPES.CHECK}
                        bgColor={areaColorBackground}
                    >
                        {/*<StyledHeader ref={headingRef} dangerouslySetInnerHTML={getHtmlFromRedactorField(heading)}/>*/}
                        <ServiceNavigationMobileContainer id='service-nav-button-right'
                                                          className={(isModulePinned && activeItem.id < services.length - 1) ? 'visible' : ''}
                                                          $accentColor={borderColor} onClick={activeItem.id !== services.length - 1 ? handleNextService : null}>
                            <ServiceNavigationBlobbyWave/>
                            <ArrowContainer>
                                <ShortArrowRight/>
                            </ArrowContainer>
                        </ServiceNavigationMobileContainer>
                        <ServiceNavigationMobileContainer $accentColor={borderColor} className={(isModulePinned && activeItem.id > 0) ? 'visible-left left' : 'left'} onClick={activeItem.id > 0 ? handlePreviousService : null}>
                            <ServiceNavigationBlobbyWave/>
                            <ArrowContainer className='left'>
                                <ShortArrowLeft/>
                            </ArrowContainer>
                        </ServiceNavigationMobileContainer>
                        <StyledLeftPartContainer
                            data-cursor={activeItem.id > 0 ? CURSOR_TYPES.SERVICES_PREVIOUS : CURSOR_TYPES.PILL}
                            data-cursor-color={borderColor}
                            onClick={activeItem.id > 0 ? handlePreviousService : null}>
                            <StyledHeader ref={headingRef} $paddingLeft={serviceNameWrapperLeftBound.current} dangerouslySetInnerHTML={getHtmlFromRedactorField(heading)}/>
                            <ServiceNameContainer id='service-container'>
                                <AnimatePresence mode="wait">
                                    <StyledServiceNameWrapper
                                        id="service-wrapper"
                                        key={activeItem?.id || ''}
                                        initial={{y: '100%'}}
                                        animate={{y: 0}}
                                        exit={{y: '-300%'}}>
                                        <ServiceIndex ref={serviceNameWrapperRef} $accentColor={borderColor}><span>{`0${activeItem.id + 1}`}</span></ServiceIndex>
                                        <ServiceName $accentColor={borderColor}>
                                            {serviceName}
                                        </ServiceName>
                                    </StyledServiceNameWrapper>
                                </AnimatePresence>
                                {!!(isMobile || isTablet) && (
                                    <AnimatePresence mode="wait">
                                        <StyledServiceNameWrapper
                                            id="service-wrapper"
                                            key={activeItem?.id || ''}
                                            // initial={{opacity: 0}}
                                            // animate={{opacity: 1}}
                                            // exit={{opacity: 0}}
                                            initial={{y: '100%'}}
                                            animate={{y: 0}}
                                            exit={{y: '-300%'}}>

                                            <ServiceDescription $paddingLeft={serviceNameWrapperLeftBound.current}>
                                                <div dangerouslySetInnerHTML={getHtmlFromRedactorField(serviceDescription)} />
                                            </ServiceDescription>
                                        </StyledServiceNameWrapper>
                                    </AnimatePresence>
                                )}
                            </ServiceNameContainer>
                        </StyledLeftPartContainer>
                        <StyledVideoMockupForMobile/>
                        <AbsoluteMaskContainer
                            ref={absoluteMaskContainerRef}
                            // onClick={() => setExpanded(!expanded)}
                            onClick={handleClickMask}
                        >
                            <Mask
                                className={expanded ? 'active' : ''}
                                // initial={{ width: 500, height: 500, borderRadius: "50%" }}
                                // animate={
                                //     expanded
                                //         // ? { width: "100vw", height: "100vh", borderRadius: "0%" }
                                //         ? { width: "200vw", height: "200vw", borderRadius: "50%" }
                                //         : {}
                                // }
                                // transition={{ duration: 1, ease: "easeInOut" }}
                            >
                                <VideoWrapper className={expanded ? 'active' : ''} data-cursor={CURSOR_TYPES.CHECK}>
                                    {videoFile && (<video
                                        key={videoFile}
                                        preload="auto"
                                        muted
                                        loop
                                        autoPlay={true}
                                        onTimeUpdate={handleTimeUpdate}
                                        playsInline
                                    >
                                        <source src={videoFile} type="video/mp4"/>
                                    </video>)}
                                </VideoWrapper>
                            </Mask>
                        </AbsoluteMaskContainer>
                        <StyledVideoWrapper
                            ref={videoWrapperRef}
                            className={isMobileDevice ? 'mobile-device' : ''}
                            data-cursor={CURSOR_TYPES.CHECK}
                            bgColor={hexToRgba(borderColor, 0.25)}
                            // onClick={handleServiceClick}
                        >
                            <ScrollDownInfo ref={scrollDownRef}>
                                <Icon name={ICONS.ARROWDOWN} color='#051D27'/>
                                <p>scroll</p>
                            </ScrollDownInfo>
                            {Array.from({ length: services.length + 1 }).map((_, index) => (
                            <StyledSectionLine angle={(index * 180 / services.length) - 180} key={index}/>
                            ))}
                            <PlaceholderCircle bgColor={borderColor}>
                                {videoFile && (<video
                                key={videoFile}
                                preload="auto"
                                muted
                                loop
                                autoPlay={true}
                                playsInline
                            >
                                <source src={videoFile} type="video/mp4"/>
                            </video>)}
                            </PlaceholderCircle>
                            <Pizza numberOfItems={services.length} color={'transparent'} activeIndex={activeItem.id} prevActiveItemId={prevActiveItemId} ref={piecesRef}/>
                            {/*<StyledIconWrapper*/}
                            {/*    rotationMultiplier={activeItem.id}*/}
                            {/*    ref={IconWrapperRef}*/}
                            {/*>*/}
                            {/*    <Icon className='areas-of-expertise-delay' name={ICONS.AREAS_OF_EXPERTISE_PIECE} color={borderColor}/>*/}
                            {/*</StyledIconWrapper>*/}
                            {/*<PortalLens*/}
                            {/*    visible*/}
                            {/*    mobile={{*/}
                            {/*        ref: BlobRef,*/}
                            {/*        // visible: isMobileBlobVisible,*/}
                            {/*        visible: true,*/}
                            {/*        autoplay: true, // zeby film leciał*/}
                            {/*        hasWhiteBand: true,*/}
                            {/*        blobVideoSrc: blobVideoSource,*/}
                            {/*        // className: 'expertise'*/}
                            {/*        className: ID*/}
                            {/*    }}*/}
                            {/*    context={{*/}
                            {/*        // src: videoFile*/}
                            {/*        src: blobVideoSource,*/}
                            {/*        blobScale: blobScale*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    <BlobPortal/>*/}
                            {/*</PortalLens>*/}
                            {/*{videoFile && (<video*/}
                            {/*    key={videoFile}*/}
                            {/*    preload="auto"*/}
                            {/*    muted*/}
                            {/*    loop*/}
                            {/*    autoPlay={true}*/}
                            {/*    playsInline*/}
                            {/*>*/}
                            {/*    <source src={videoFile} type="video/mp4"/>*/}
                            {/*</video>)}*/}
                            {/*{externalVideoUrl && <Video src={externalVideoUrl}/>}*/}
                            {/*{externalVideoUrl &&(*/}
                            {/*    <StyledReactPlayerWrapper externalVideoAspectRatio={externalVideoAspectRatio}>*/}
                            {/*        <ReactPlayer*/}
                            {/*            url={externalVideoUrl}*/}
                            {/*            volume={0}*/}
                            {/*            // width="100%"*/}
                            {/*            // height="100%"*/}
                            {/*            wrapper="figure"*/}
                            {/*            muted*/}
                            {/*            loop*/}
                            {/*            playsinline={true}*/}
                            {/*            playing={true}*/}
                            {/*            // playing={false}*/}
                            {/*            // fallback={<Fallback/>}*/}
                            {/*            controls={false}*/}
                            {/*            // onPause={() => PlayerRef.current.seekTo(0)}*/}
                            {/*        />*/}
                            {/*    </StyledReactPlayerWrapper>*/}
                            {/*)}*/}
                        </StyledVideoWrapper>
                        <StyledRightPartContainer data-cursor={activeItem.id !== services.length - 1 ? CURSOR_TYPES.SERVICES_NEXT : CURSOR_TYPES.PILL} data-cursor-color={borderColor} onClick={activeItem.id !== services.length - 1 ? handleNextService : null}>
                            <StyledRangeContainer id='range-container'>
                                <AnimatePresence mode="wait">
                                    <StyledRangeWrapper id="range-wrapper"
                                                        key={activeItem?.id || ''}
                                                        initial={{y: '120%'}}
                                                        animate={{y: 0}}
                                                        transition={{delay: 0}}
                                                        exit={{y: '-120%'}}>
                                        <RangeText>{rangeText}</RangeText>
                                        <StyledCompetencesTagWrapper>
                                            {competences.map((item, index) => {
                                                const pathWithNoHash = router.asPath.split('#')[0];
                                                const href = `/competence/${item.slug}?origin=${pathWithNoHash}-id-${activeItem?.slug || ''}`
                                                return (
                                                    <Tag
                                                        key={item?.competenceName || index}
                                                        href={href}
                                                        accentColor={borderColor}
                                                    >
                                                        {item.competenceName}
                                                    </Tag>
                                                )
                                            })}
                                        </StyledCompetencesTagWrapper>
                                        {competences.length > 5 && <MoreTags accentColor={borderColor}>+ more</MoreTags>}
                                        {/*<RevealWrapper>*/}
                                        <ButtonWrapper
                                            className={isMobileDevice && orientationLandscape ? 'mobile-device-landscape' : ''}
                                        >
                                            <Button
                                                as="link"
                                                href={`/services/${serviceSlug}`}
                                                variant="secondary">
                                                {buttonText}
                                            </Button>
                                        </ButtonWrapper>
                                        {/*</RevealWrapper>*/}
                                    </StyledRangeWrapper>
                                </AnimatePresence>
                            </StyledRangeContainer>
                        </StyledRightPartContainer>
                    </StyledSingleServiceWrapper>
                </StyledAreasOfExpertisePinnedWrapper>
            </StyledAreasOfExpertiseContainer>
            {isBlobVisible && <div>
                <PortalLens
                    visible
                    mobile={{
                        ref: BlobRef,
                        // visible: isMobileBlobVisible,
                        // visible: true,
                        visible: true,
                        autoplay: true, // zeby film leciał
                        hasWhiteBand: true,
                        blobVideoSrc: blobVideoSource,
                        // className: 'expertise'
                        className: ID
                    }}
                    context={{
                        // src: videoFile
                        ...context,
                        src: blobVideoSource,
                        blobScale: blobScale,
                    }}
                >
                    <BlobPortal/>
                </PortalLens>
            </div>}
            {isBlobVisible && <Transition className='under-content' id={`${ID}-step1`} start="top" onUpdate={updateContext}/>}
        </Wrapper>
    );
};


export default AreasOfExpertise;
