import {usePageTransitionContext} from "./index";
import styled, {keyframes} from "styled-components";
import {AnimatePresence, motion} from "framer-motion";
import {Icon} from "../../../index";
import {ICONS} from "@repo/utils/constants";
import {getCssSizeValue} from "@repo/utils";
import {useEffect, useState} from "react";
import {BREAKPOINTS} from "../../../styles/themeSettings";

const colorChangeAnimation = keyframes`
    0% {
        fill: #EE6255;
    }
    25% {
        fill: #1BBDD5
    }
    50% {
        fill: #8973F9;
    }
    75% {
        fill: #47B68D;
    }
    100% {
        fill: #EE6255;
    }
`

const LoaderContainer = styled(motion.div)`
    position: relative;
    width: ${getCssSizeValue(38, BREAKPOINTS.MOBILE)};
    
    @media (min-width: ${({theme}) => theme?.breakpoints?.tablet || 768}px) {
        width: ${getCssSizeValue(44, BREAKPOINTS.TABLET)};
    }

    @media (min-width: ${({theme}) => theme?.breakpoints?.desktop || 1024}px) {
        width: ${getCssSizeValue(40)};
    }
    
    .icon {
        max-width: unset;
    }
    
    svg {
        path,
        circle {
            animation: ${colorChangeAnimation} 4s infinite ease;
            fill: ${({$color}) => $color || 'var(--secondary-color)'};
        }

        width: 100%;
        height: 100%;
    }
`

const loaderVariants = {
    hidden: {opacity: 0},
    visible: {opacity: 1},
    exit: {opacity: 0}
};

export default function Loader() {
    const {isLoaded} = usePageTransitionContext()

    // Prevents FOC
    const [isFirstRender, setIsFirstRender] = useState(true)

    useEffect(() => {
        setIsFirstRender(false)
    }, []);

    return (
        <AnimatePresence mode='wait'>
            {!isFirstRender && !isLoaded && (
                <LoaderContainer
                    variants={loaderVariants}
                    initial='hidden'
                    animate='visible'
                    exit='exit'
                >
                    <Icon name={ICONS.PRELOADER}/>
                </LoaderContainer>
            )}
        </AnimatePresence>
    )
}