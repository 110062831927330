export const PLEO_PREFIX = 'pleo-';

export const COLORS_LIST = {
	PRIMARY: 'primary',
	SECONDARY: 'secondary',
	TERTIARY: 'tertiary',
};

export const THEME_MODES = {
	LIGHT: 'light',
	DARK: 'dark',
	A11Y: 'a11y',
};
export const THEME_COLOR_SCHEMES = {
	MAIN: 'main',
	EXTRA: 'extra',
	PLEO_MAIN: `${PLEO_PREFIX}main`,
	PLEO_EXTRA: `${PLEO_PREFIX}extra`,
};

export const DEFAULT_MODE = THEME_MODES.LIGHT
export const DEFAULT_COLOR_SCHEME = THEME_COLOR_SCHEMES.MAIN

export const COLORS = {
	black: 'rgb(28,28,28)',
	richBlack: '#051D27',
	white: 'rgba(255, 255, 255, 1)',
	lightGray: 'rgba(238, 239, 241, 1)',
	dimGray: 'rgba(68, 99, 117, 1)',
	darkGray: 'rgb(40,40,40)',
	darkBlue: 'rgb(11, 34, 78)',
	// twilightBlue: 'rgba(5, 29, 39, 1)', old
	twilightBlue: 'rgba(4, 27, 40, 1)',
	blue: 'rgb(15, 73, 177)',
	purple: 'rgb(145, 152, 229)',
	red: 'rgb(235, 89, 77)',
	gray: 'rgb(204,204,204)',
	gold: 'rgb(255,215,0)',
	dimmedGray: '#D7D7D7',
	darkDarkGray: '#4B5356',
	groupGray: '#AAB2B3',
	designBlue: '#1ABFD7',
	charcoal: '#0D394B',
	aliceBlue: '#D6E3E7',
	gunmetal: '#092530'
};

export const LOGO_COLORS = {
	LIGHT: COLORS.white,
	DARK: COLORS.twilightBlue,
}

export const BREAKPOINTS = {
	LARGE_DESKTOP: 'large-desktop',
	DESKTOP: 'desktop',
	TABLET: 'tablet',
	MOBILE: 'mobile',
};

const PLEO_PALETTE = {
	light: {
		main: {
			primary: COLORS.lightGray,
			secondary: COLORS.twilightBlue,
			tertiary: COLORS.red,
		},
		extra: {
			primary: COLORS.twilightBlue,
			secondary: COLORS.lightGray,
			tertiary: COLORS.red,
		},
	},
	dark: {
		main: {
			primary: COLORS.twilightBlue,
			secondary: COLORS.lightGray,
			tertiary: COLORS.red,
		},
		extra: {
			primary: COLORS.lightGray,
			secondary: COLORS.twilightBlue,
			tertiary: COLORS.red,
		},
	},
};
export const initialPalette = {
	...PLEO_PALETTE,
	a11y: {
		primary: COLORS.black,
		secondary: COLORS.gold,
		tertiary: COLORS.gold,
	},
};

export const theme = {
	colors: COLORS,
	isDesktop: false,
	isMobileDevice: false,
	fontWeight: {
		thin: 100,
		light: 200,
		book: 300,
		regular: 400,
		semiBold: 600,
		bold: 700,
		heavy: 800,
		black: 900,
	},
	spacing: {
		[BREAKPOINTS.MOBILE]: {
			xs: 10,
			s: 20,
			m: 50,
			l: 80,
			xl: 100,
		},
		[BREAKPOINTS.TABLET]: {
			xs: 20,
			s: 50,
			m: 90,
			l: 150,
			xl: 200,
		},
		[BREAKPOINTS.DESKTOP]: {
			xs: 50,
			s: 100,
			m: 150,
			l: 220,
			xl: 300,
		},
	},
	breakpoints: {
		[BREAKPOINTS.TABLET]: 768,
		[BREAKPOINTS.DESKTOP]: 1024,
		[BREAKPOINTS.LARGE_DESKTOP]: 2560,
	},
	palette: {
		primary: initialPalette.light.main.primary,
		secondary: initialPalette.light.main.secondary,
		tertiary: initialPalette.light.main.tertiary,
	},
};

export const a11y = {
	palette: {
		primary: initialPalette.a11y.primary,
		secondary: initialPalette.a11y.secondary,
		tertiary: initialPalette.a11y.tertiary,
	},
};
