import React, {startTransition, useMemo, useRef, memo} from 'react';
import {gsap} from "@repo/utils/gsap";
import {PORTAL_LENS_RADIUS} from "@repo/utils/constants";
import {useCursor} from "../Cursor/Cursor";
import { Vector3 } from 'three';
import PortalVideo from "../WebGL/Portal/Video3D";
import BandVideo from "../../assets/video/band-dark-square.mp4";
import {useFrame, useThree} from "@react-three/fiber";
import {useIsomorphicLayoutEffect} from "../../../index";
import {TARGET_ID} from "./index";
import getPositionZ from "../common/StaticLens/getPositionZ";

//scale down portal initially
const PORTAL_SCALE = 0

const Blob = ({ _visible = false, context, ...props}) => {
    const MeshRef = useRef()
    const three = useThree()
    const portalLens = useCursor((state) => state.portalLens)
    const moveVector = useMemo(() => portalLens.position.clone(), [portalLens.position]);
    // const scaleVector = useMemo(() => new Vector3(0, 0, 0), [])
    const scaleVector = useMemo(() => new Vector3(PORTAL_SCALE, PORTAL_SCALE,PORTAL_SCALE), [])
    const step1Scale = useMemo(() => {
        return (three.viewport.width/PORTAL_LENS_RADIUS) * 0.03
    }, [three.viewport.width])


    const steps = useMemo(() => ([
        {
            timeline: gsap.timeline({ paused: true }),
            animations: [
                {
                    target: scaleVector,
                    x: step1Scale,
                    y: step1Scale,
                    z: step1Scale
                },
            ]
        },
        {
            timeline: gsap.timeline({ paused: true }),
            animations: [
                {
                    target: scaleVector,
                    x: 1,
                    y: 1,
                    z: 1,
                },
                {
                    target: moveVector,
                    x: 0,
                    y: 0,
                    z: getPositionZ(PORTAL_LENS_RADIUS, three.viewport)
                },
            ]
        },
    ]),[three.viewport, step1Scale])

    useIsomorphicLayoutEffect(() => {
        const { size: windowSize, viewport } = three
        const target = document.getElementById(TARGET_ID)
        if(target) {
            const bounds = target.getBoundingClientRect()
            const heightThird = bounds.height / 3 //assumes text is in 3 rows
            const coords3d = {
                y: (-heightThird / windowSize.height * viewport.height) * 0.95
            }
            moveVector.setY(coords3d.y)
        }

        steps.forEach((step) => {
            step.animations.forEach((animation) => {
                step.timeline.to(animation.target, {
                    x: animation.x,
                    y: animation.y,
                    z: animation.z,
                    ease: 'none'
                }, 0 ) //important for animations to be applied simultaneously
            })
        })

        // console.log(moveVector);

        return () => {
            steps.forEach((step) => step.timeline.revert())
            scaleVector.setScalar(PORTAL_SCALE)
        }
    },[steps, three.viewport, step1Scale])

    useFrame(() => {
        const step1PG = context.step1.current
        const step2PG = context.step2.current
        steps[0].timeline.progress(step1PG)
        if(step1PG >= 1){
            steps[1].timeline.progress(step2PG)
            if(portalLens.current){
                portalLens.current.material.opacity = 1.0 - step2PG
            }
        }
        //update props of both lens and portal
        //position && scale
        portalLens.position.copy(moveVector)
        portalLens.scale.copy(scaleVector)

        startTransition(() => {
            MeshRef.current.position.copy(portalLens.position)
            MeshRef.current.scale.copy(portalLens.scale)
            MeshRef.current.visible = _visible
        })
    })

    return (
        <group ref={MeshRef}>
            <PortalVideo
                src={BandVideo}
                visible={context.lensVisible}
            />
        </group>
    )
};

export default memo(Blob);
