import {partition} from "@repo/utils";
// import {getCookie, setCookie} from "cookies-next";

const STORY_KEY = 'story'
export function getStoryItems(){
    return JSON.parse(localStorage.getItem(STORY_KEY)) || []
    // const cookie = getCookie(STORY_KEY) || '[]'
    // const parsed = JSON.parse(cookie)
    // return parsed
}
export function setStoryItems(items=[]){
    const str = JSON.stringify(items)
    return localStorage.setItem(STORY_KEY, str)
    // return setCookie(STORY_KEY, str, {
    //     sameSite: 'none',
    //     secure: true,
    //     maxAge: 60 * 60 * 24 * 7, // 1 week
    // })
}

export function findItemIndex(rowIndex=-1, row=[], texts=[]){
    const item = row[rowIndex]
    return texts.indexOf(item)
}
export function updateStoryItem(id='', itemIndex=0, row = [], texts=[]){
    if(id?.length <= 0) return
    const textIndex = findItemIndex(itemIndex, row, texts)
    if(textIndex < 0) return;
    const prevList = getStoryItems()
    const [[prevItem], cleanList] = partition(prevList, (item) => item?.id === id)
    // Update indexes and sort
    const updatedIndexes = prevItem?.indexes.filter((i) => i !== textIndex) || [];
    const updatedItem = { id, indexes: [...updatedIndexes, textIndex].sort((a, b) => a - b) }
    const updatedList = [...cleanList, updatedItem]

    // console.log(text, hoveredBreakItem, updatedList, cleanList);

    setStoryItems(updatedList)
}

export const TITLE_BREAK_PREFIX = 'break-'
export const TITLE_BREAK_ACTIVE_PREFIX = 'active-'
export const TITLE_BREAK_HIDDEN_PREFIX = 'hidden-'

export function removePrefixes(text){
    return text.replace(new RegExp(`(${TITLE_BREAK_PREFIX}|${TITLE_BREAK_ACTIVE_PREFIX}|${TITLE_BREAK_HIDDEN_PREFIX})`, 'g'), '')
}

export function getPrefixes(text){
    return ({
        isBreak: text.includes(TITLE_BREAK_PREFIX),
        isActive: text.includes(TITLE_BREAK_ACTIVE_PREFIX),
        isHidden: text.includes(TITLE_BREAK_HIDDEN_PREFIX)
    })
}

function extractBrkClass(inputString) {
    const regex = /class=".*?(data-break-[^"]*?)"/;
    const match = inputString.match(regex);
    return match ? match[1] : "";
}

export function formatHTML(content, prefix = TITLE_BREAK_PREFIX) {
    // Remove opening, closing <p> tags and non-breaking spaces
    let cleanStr = content.replace(/<p>|<\/p>|&nbsp;/g, '');
    cleanStr = cleanStr.replace(/&oacute;/g, 'ó');
    // Split the string based on <br /> tags creating array of paragraphs
    let paragraphs = cleanStr.split(/<br \/>/g);

    // Initialize result
    let result = [];

    paragraphs.forEach(paragraph => {
        // Handle mixed content within the paragraph, looking for <span> and <em> tags
        let processedText = [];
        let lastIndex = 0;
        let regex = /<em(\sclass="[^\"]*")?>|<\/em>|<span data-color="([^"]+)">|<\/span>/g;
        let inEmTag = false;
        let currentPrefix = "";
        // Parse through the paragraph with regex
        let match;
        let breakId = ''
        while ((match = regex.exec(paragraph)) !== null) {
            // Text before any tag
            let textSegment = paragraph.substring(lastIndex, match.index);
            if (textSegment) {
                processedText.push({ text: currentPrefix + textSegment.trim(), breakId });
            }

            if (match[0].startsWith('<span')) {
                // Entering a span tag with color
                currentPrefix = `color-${match[1]}-`; // Set prefix to color value
            } else if (match[0] === '</span>') {
                // Exiting a span tag
                currentPrefix = inEmTag ? prefix : ""; // Reset prefix depending on em tag status
            } else if (match[0].includes('<em') ) {
                // Entering an em tag
                inEmTag = true;
                const { isActive, isHidden } = getPrefixes(match[0])
                if(isActive) {
                    currentPrefix = `${TITLE_BREAK_ACTIVE_PREFIX}${currentPrefix}`
                } else {
                    if(isHidden) currentPrefix = `${TITLE_BREAK_HIDDEN_PREFIX}${currentPrefix}`
                }
                breakId = extractBrkClass(match[0]).replace('data-break-', '')
                currentPrefix += prefix; // Append em prefix to current prefix
                // console.log(currentPrefix);
            } else if (match[0].includes('em>')) {
                // Exiting an em tag
                inEmTag = false;
                breakId = ''
                currentPrefix = removePrefixes(currentPrefix) // Remove em prefix
            }
            lastIndex = regex.lastIndex;
        }
        // Handle any trailing text after the last tag
        if (lastIndex < paragraph.length) {
            processedText.push({ text: currentPrefix + paragraph.substring(lastIndex).trim(), breakId });
        }
        // const trimmedText = processedText.map((text) => text.trim())
        // Add processed paragraph to result
        result.push(processedText);
    });
    return result;
}

export function getOnlyBreaksTexts(array){
    return array.flat().filter((el) => el.text.includes(TITLE_BREAK_PREFIX))
}

function extractColorFromPrefix(prefix) {
    const regex = /^color-#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})/;
    const match = prefix.match(regex);
    if (match) {
        return '#' + match[1];
    } else {
        return null;
    }
}

const COLOR_PREFIX_LENGTH = 14;
export function getColoredText(text){
    const specifiedColor = extractColorFromPrefix(text);
    return {
        color: specifiedColor,
        text: specifiedColor ? text.slice(COLOR_PREFIX_LENGTH) : text
    }
}

export const ANIMATION = {
    INIT_SHOW: 'init-show'
}

