import React, {forwardRef, useImperativeHandle, useRef} from "react";
import styled from "styled-components";
import {useIsDesktop, useWindowSize} from "../../../index";

const PizzaContainer = styled.div`
    position: relative;
    width: 270px; // 254 + 8 + 8
    height: 270px;
    border-radius: 50%;
    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        width: 360px;
        height: 360px;
    }
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        width: 474px;
        height: 474px;
    }
    @media(min-width: 1440px) {
        width: 702px;
        height: 702px;
    }
`;

const PizzaSlice = styled.svg`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: rotate(${(props) => props.angle - (props.index * props.sliceAngle)}deg);
    &.active {
        transition: transform 0.5s ease-in-out;
        //transform: rotate(${(props) => `calc(${props.angle} + 30}deg)`});
        //transform: ${({angle}) => `rotate(calc(${angle}deg + 30deg))`};

    }
`;

const TemporaryContainer = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    transform: rotate(${({index, sliceAngle}) => -90 + index * sliceAngle}deg);
`

const Number = styled.div`
    position: absolute;
    //top: 50%;
    top: ${({totalSlices}) => totalSlices > 5 ? `3px` : totalSlices === 5 ? `5px` : `10px`};
    //z-index: 11;
    //left: calc(50% - 5px);
    //left: calc(50% + 30px);
    left: ${({totalSlices}) => totalSlices > 5 ? `calc(50% + 30px)` : totalSlices === 5 ? `calc(50% + 37px)` : `calc(50% + 50px)`};
    //left: 0;
    scale: 5;
    transform-origin: center;
    transform: rotate(15deg);
    //transform: rotate(${(props) => props.angle}deg);
    //transform: rotate(${(props) => props.angle}deg) translate(300px, 200px) rotate(${(props) => -props.angle}deg);
    //transform: rotate(${(props) => props.angle - 4}deg) translate(350px, 110px) rotate(${(props) => -props.angle}deg);
    //transform: rotate(${(props) => props.angle}deg) translate(320px, 110px) rotate(${(props) => -props.angle}deg);
    //transform: rotate(${(props) => props.angle - 4}deg) translate(320px, 110px) rotate(${({angle, index}) => index === 0 ? (-angle - 65) : index === 1 ? (-angle - 45) : index === 2 ? (-angle - 20) : index === 3 ? (-angle + 20) : index === 4 ? (-angle + 45) :(-angle + 65) }deg);
    //transform: rotate(${(props) => props.angle}deg) translate(320px, 110px) rotate(${({angle, index}) => index === 0 ? (-angle - 65) : index === 1 ? (-angle - 45) : index === 2 ? (-angle - 20) : index === 3 ? (-angle + 20) : index === 4 ? (-angle + 45) :(-angle + 65) }deg);
    font-size: 9px;
    font-weight: 600;
    opacity: 0;
    color: #051D27;
    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        //left: calc(50% + 40px);
        left: ${({totalSlices}) => totalSlices > 5 ? `calc(50% + 40px)` : totalSlices === 5 ? `calc(50% + 50px)` : `calc(50% + 70px)`};
        top: ${({totalSlices}) => totalSlices > 5 ? `6px` : totalSlices === 5 ? `10px` : `17px`};
    }
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        font-size: 12px;
        font-weight: 800;
        //top: 9px;
        top: ${({totalSlices}) => totalSlices > 5 ? `6px` : totalSlices === 5 ? `9px` : `25px`};
        //left: calc(50% + 75px);
        //left: calc(50% + 125px);
        left: ${({totalSlices}) => totalSlices > 5 ? `calc(50% + 55px)` : totalSlices === 5 ? `calc(50% + 65px)` : `calc(50% + 125px)`};
    }
    @media (min-width: 1440px) {
        font-size: 12px;
        font-weight: 800;
        //top: 9px;
        top: ${({totalSlices}) => totalSlices > 5 ? `9px` : totalSlices === 5 ? `14px` : `25px`};
        //left: calc(50% + 75px);
        //left: calc(50% + 125px);
        left: ${({totalSlices}) => totalSlices > 5 ? `calc(50% + 75px)` : totalSlices === 5 ? `calc(50% + 95px)` : `calc(50% + 125px)`};
    }
    &.active {
        //color: white;
    }
`;

const PizzaPieceComponent = ({ index, totalSlices, color, className, isDesktop, width }) => {
    // const angle =  -180 + (index * 180) / totalSlices;
    const angle =  -180 + 90 + (index * 180) / totalSlices;
    const sliceAngle = 180 / totalSlices;
    const largeArcFlag = sliceAngle > 180 ? 1 : 0;

    // Calculate end point of slice arc
    // const x = 351 + 351 * Math.cos((sliceAngle * Math.PI) / 180);
    const radius = isDesktop && width >= 1440 ? 351 : isDesktop && width < 1440 ? 250 : 135;
    const x = radius + radius * Math.cos((sliceAngle * Math.PI) / 180);
    // const y = 351 + 351 * Math.sin((sliceAngle * Math.PI) / 180);
    const y = radius + radius * Math.sin((sliceAngle * Math.PI) / 180);
    // console.log({angle})
    return (
        <TemporaryContainer index={index} sliceAngle={sliceAngle}>
            <PizzaSlice
                className={className}
                angle={angle}
                index={index}
                sliceAngle={sliceAngle}
                // viewBox={isDesktop ? "0 0 702 702" : "0 0 270 270"}
                viewBox={ isDesktop && width >= 1440 ? "0 0 702 702" : isDesktop && width < 1440 ? "0 0 500 500" : "0 0 270 270"}
            >
                <path
                    d={`M${radius},${radius} L${radius*2},${radius} A${radius},${radius} 0 ${largeArcFlag},1 ${x},${y} Z`}
                    fill={color}
                    stroke="black"
                    strokeWidth="0"
                    strokeLinejoin="round"
                    strokeLinecap="round"  />
            </PizzaSlice>
            <Number className={`${className} number`} index={index} angle={angle} totalSlices={totalSlices}>{`0${index + 1}`}</Number>
            {/*<Number angle={angle}>{angle}</Number>*/}
        </TemporaryContainer>
    );
};

const Pizza = forwardRef(function Pizza({numberOfItems, color, activeIndex, prevActiveItemId, piecesRef}, fref){
    const WrapperRef = useRef()
    const totalSlices = numberOfItems; // Adjust for more or fewer slices
    useImperativeHandle(fref, () => WrapperRef.current, [])
    const isDesktop = useIsDesktop();
    const {width} = useWindowSize()
    return (
        <PizzaContainer ref={WrapperRef}>
            {Array.from({ length: totalSlices }, (_, i) => (
                <PizzaPieceComponent
                    className={activeIndex === i ? 'active' : prevActiveItemId === i ? 'prev' : ''}
                    key={i}
                    index={i}
                    isDesktop={isDesktop}
                    width={width}
                    totalSlices={totalSlices}
                    color={activeIndex === i ? '#fff' : color}  />
            ))}
        </PizzaContainer>
    );
});

export default Pizza;
