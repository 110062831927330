import React, {
	useMemo,
	useRef,
	useState,
	memo,
	forwardRef,
	useImperativeHandle,
	useCallback

} from 'react';
import LazyReactPlayer from 'react-player/lazy';
import styled from 'styled-components';
import {gsap, useGSAP, ScrollTrigger} from "@repo/utils/gsap";
import { v4 } from 'uuid';
import {useWindowSize} from "../../../index";
import useAnimationDebug from "../../animations/useAnimationDebug";

const Wrapper = styled.div`
	display: flex;
	width: 100%;
	max-width: 100vw;
	height: 100%;
	position: relative; 
	z-index: 1;
	-webkit-backface-visibility: hidden;
	-webkit-transform: translateZ(0);
	-webkit-transform-style: preserve-3d;
	overflow: hidden;
	margin: 0;

	figure{
		//animation: fade-in var(--transition-duration) ease-in-out;
		display: flex;
		position: relative;
		//&:before {
		//	content: '';
		//	position: absolute;
		//	top: 0;
		//	left: 0;
		//	width: 100%;
		//	height: 100%;
		//}
	}
	video {
		object-fit: cover;
	}
`;

const Loading = styled.div`
	--shine-color: rgba(211, 211, 211, 1);
	--shine-bg-color:  transparent;
	display: flex;
	width: 100%;
	height: 100%;
	inset: 0;
	position: absolute;
	z-index: 1;
	justify-content: center;
	align-items: center;
	text-align: center;
	opacity: 0.8;
	background: linear-gradient(to right, var(--shine-bg-color) 0%, var(--shine-color) 50%, var(--shine-bg-color) 100%);
	background-size: 200% auto;
	animation: shine 1s ease-in-out infinite;
	transition: all var(--ease) var(--color-transition-duration);
	@keyframes shine {
        to {
            background-position: 200% center;
        }
    }	
	&.hidden{
		visibility: hidden;
		opacity: 0;
		pointer-events: none;
	}	
`
const ProgressBar = styled.span`
	--progress: 0;
	display: block;
	position: absolute;
	width: 90vw;
	height: 4px;
	//background-color: ${({ theme }) => theme.colors.gray};
	z-index: 1;
	margin: 0 5vw;
	bottom: 3vw;
	
	&:after{
		content: ' ';
		position: absolute;
		inset: 0;
		background-color: ${({ theme }) => theme.colors.darkBlue};
		transform-origin: left;
		scale: var(--progress) 1;
	}
`

const Player = ({
	src,
	playsinline=true,
	muted=true,
	playing=false,
	controls,
	progress=false,
	fallback=null
}) => {
	const PlayerRef = useRef()
	const ProgressBarRef = useRef()
	if(src?.length <= 0) return null

	return (
		<>
			<LazyReactPlayer
				ref={PlayerRef}
				url={src}
				width="100%"
				height="100%"
				wrapper="figure"
				muted={muted}
				volume={muted ? 0 : 0.8}
				loop
				playsinline={playsinline}
				playing={playing}
				onPause={() => PlayerRef.current.seekTo(0)}
				onProgress={(props) => {
					if(!progress) return
					const { played } = props
					if (played > 0.01){
						gsap.to(ProgressBarRef.current, {
							'--progress': played,
							duration: 1,
							ease: 'none'
						})
					} else {
						gsap.set(ProgressBarRef.current, {
							'--progress': played,
						})
					}
				}}
				fallback={fallback}
				controls={controls}
			/>
			{progress && (
				<>
					<ProgressBar ref={ProgressBarRef}/>
				</>
			)}
		</>
	)
}



const Video = forwardRef(
	function Video({
		id='',
		src = '',
		className='',
		autoplay=false,
		visible=true,
		playing=undefined,
		children,
		muted=true,
		controls=false,
		playsinline=true,
		target= { current: undefined },
	   	setIsPlaying,
	    progress=false,
		fallback=null,
		...props
	   },
	   fref
	){
	const standardizedId = useMemo(() => {
		const uuid = id || v4();
		return `video-${uuid}`.replaceAll('/--|- -/g', '-'); //replace "--" or "- -" with "-"
	}, [id]); //use passed id or generate one
	const WrapperRef = useRef(null);
	const [inViewport, setInViewport] = useState(false);
	const [hover, setHover] = useState(false)
	const windowSize = useWindowSize()
	const debug = useAnimationDebug()
	const isPlaying = useMemo(() => {
		let state
		if(typeof playing !== 'undefined') {
			state = playing
		} else {
			if(autoplay){
				if(autoplay === 'force') {
					state = true
				} else {
					state = inViewport
				}
			} else {
				state = hover;
			}

		}
		if(typeof setIsPlaying === 'function'){
			setIsPlaying(state)
		}

		return state

	},[inViewport, hover, autoplay, playing, setIsPlaying])

	const handlePointerEnter = useCallback(() => {
		setHover(true)
	},[])

	const handlePointerLeave = useCallback(() => {
		setHover(false)
	},[])

	useImperativeHandle(fref, () => WrapperRef.current, []);


	useGSAP(() => {
		const el = target?.current || WrapperRef.current;
		if(autoplay && autoplay !== 'force' && el){
			ScrollTrigger.create({
				id: standardizedId,
				trigger: el,
				start: 'top bottom+=100px',
				end: 'bottom top',
				markers: debug,
				// invalidateOnRefresh: true,
				onUpdate: ({ isActive }) => {
					setInViewport(isActive)
				}
			})
		}
	},{
		dependencies: [autoplay, standardizedId, windowSize.width, debug, target],
		revertOnUpdate: true,
	})

	// useEffect(() => {
	// 	// console.log('isPlaying:', isPlaying, 'src:', src);
	// 	logTable({ isPlaying, src })
	// }, [isPlaying]);


	return (
		<Wrapper
			id={standardizedId}
			ref={WrapperRef}
			className={`${className} video`}
			onPointerEnter={handlePointerEnter}
			onPointerLeave={handlePointerLeave}
			{...props}
		>
			<Player
				src={src}
				playing={isPlaying}
				muted={muted}
				playsinline={playsinline}
				controls={controls}
				progress={progress}
				fallback={fallback}
			/>
			{children}
		</Wrapper>
	);
})

export default memo(Video);
