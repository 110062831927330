import React, {useRef} from 'react';
import styled from 'styled-components';
import {
	getColorScheme,
	getCssSizeValue,
	getFontSize,
	getHtmlFromRedactorField,
	getIdWithoutSpecialSymbols,
	getPaddingsClassFromData, removeTags
} from '@repo/utils';
import PageContainer from "../pageContainer/PageContainer";
import {BREAKPOINTS} from "../../../styles/themeSettings";
import Reveal from "../../animations/Reveal";
import {useLinesReveal} from "../../animations/LinesReveal";

const StyledWrapper = styled.div`
	border-radius: 0.5rem;
	color: var(--secondary-color);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	position: relative;
	transition: color var(--color-transition-duration) var(--ease);
`;

const StyledHeader = styled.div`
	//color: #041B28;
	color: var(--secondary-color);
	transition: color var(--color-transition-duration) var(--ease);
	font-weight: 600;
	font-size: ${getFontSize(22, BREAKPOINTS.MOBILE)};
	line-height: ${getFontSize(24, BREAKPOINTS.MOBILE)};
	margin-bottom: ${getCssSizeValue(33, BREAKPOINTS.MOBILE)};
	@media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
		font-size: ${getFontSize(26, BREAKPOINTS.TABLET)};
		line-height: ${getFontSize(32, BREAKPOINTS.TABLET)};
		margin-bottom: ${getCssSizeValue(25, BREAKPOINTS.TABLET)};
	}
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        font-size: ${getFontSize(36, BREAKPOINTS.DESKTOP)};
        line-height: ${getFontSize(56, BREAKPOINTS.DESKTOP)};
        margin-bottom: ${getCssSizeValue(70, BREAKPOINTS.DESKTOP)};
    }
`

const StyledDescription = styled.div`
	//color: #152731;
	color: var(--secondary-color);
	font-weight: 300; //book
	width: 100%;
	font-size: ${getFontSize(16, BREAKPOINTS.MOBILE)};
	line-height: ${getFontSize(28, BREAKPOINTS.MOBILE)};
	@media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
		font-size: ${getFontSize(22, BREAKPOINTS.TABLET)};
		line-height: ${getFontSize(32, BREAKPOINTS.TABLET)};
	}
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		font-size: ${getFontSize(28, BREAKPOINTS.DESKTOP)};
		line-height: ${getFontSize(46, BREAKPOINTS.DESKTOP)};
		width: 50%;
	}
`

const HeaderAndDescription = ({ data }) => {
	const header = data?.header || '';
	const description = data?.description || '';
	// const redactor = getHtmlFromRedactorField(description);
	const redactor = getHtmlFromRedactorField(removeTags(description, ['p', 'span'])); //tag removing so animation works fine

	const id  = getIdWithoutSpecialSymbols(data?.id);
	const colorScheme = getColorScheme(data)
	const paddings = getPaddingsClassFromData(data)
	const DescRef = useRef()

	// useLinesReveal({
	// 	el:DescRef,
	// 	onInView: true,
	// 	disabled: !description
	// })
	return (
		<PageContainer id={id} className="full-width" data-color-scheme={colorScheme}>
			<StyledWrapper
				className={`${paddings} px-main`}
			>
					{header && (
						<StyledHeader>
							<Reveal id={`header-desc-${data?.id || ''}`} onInView animationVars={{
								delay: 0.3
							}}>
								{header}
							</Reveal>
						</StyledHeader>
					)}
					{description && (
						<Reveal onInView start="70%">
							<StyledDescription ref={DescRef} dangerouslySetInnerHTML={redactor} />
						</Reveal>
					)}
			</StyledWrapper>
		</PageContainer>
	);
};
export default HeaderAndDescription;
