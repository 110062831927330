import styled from 'styled-components';
import Logo from '@/components/Menu/shared/Logo';
import {
    Button,
    LetsTalkButton,
    Link,
    useScrollContext,
    useSpecifyColor,
    useHideTopBarOnScroll,
    StoryButton, useSetIsMenuTransparentOnPageLoad,
} from "@repo/ui";
import {getCssSizeValue} from "@repo/utils";
import {BREAKPOINTS} from "@repo/ui/themeSettings";
import {useMemo} from "react";
import {CONTENT_TYPES, CURSOR_TYPES} from "@repo/utils/constants";
import LanguageChange from "@/components/Menu/shared/LanguageChange";
import {useRouter} from "next/router";
import {setIsModalVisible, setModalData} from "@/state/modalSlice";
import {useDispatch, useSelector} from "react-redux";

const StyledTopRow = styled.div`
    z-index: 99;
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: ${getCssSizeValue(15, BREAKPOINTS.MOBILE)};
    padding-bottom: ${getCssSizeValue(15, BREAKPOINTS.MOBILE)};
    position: fixed;
    transition: all var(--color-transition-duration) var(--ease);
    transform: translateY(-100%);

    &.menu-small {
        padding-top: ${getCssSizeValue(10, BREAKPOINTS.MOBILE)};
        padding-bottom: ${getCssSizeValue(10, BREAKPOINTS.MOBILE)};
    }

    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        padding-top: ${getCssSizeValue(25, BREAKPOINTS.TABLET)};
        padding-bottom: ${getCssSizeValue(15, BREAKPOINTS.TABLET)};

        &.menu-small {
            padding-top: ${getCssSizeValue(10, BREAKPOINTS.TABLET)};
            padding-bottom: ${getCssSizeValue(10, BREAKPOINTS.TABLET)};
        }
    }

    @media (min-width: ${({theme}) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        padding-top: ${getCssSizeValue(45, BREAKPOINTS.DESKTOP)};
        padding-bottom: ${getCssSizeValue(10, BREAKPOINTS.DESKTOP)};

        &.menu-small {
            padding-top: ${getCssSizeValue(10, BREAKPOINTS.DESKTOP)};
            padding-bottom: ${getCssSizeValue(10, BREAKPOINTS.DESKTOP)};
        }
    }
`;

const StyledTopRowBackground = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
    top: 0;
    left: 0;
    transition: all var(--color-transition-duration) var(--ease);
    background: var(--primary-color);

    &.transparent {
        background: transparent;
    }
`

const StyledRightPartWrapper = styled.div`
    position: relative;
    z-index: 10;
    display: flex;
    align-items: center;
    gap: ${getCssSizeValue(20, BREAKPOINTS.DESKTOP)};
    margin-right: ${getCssSizeValue(10, BREAKPOINTS.MOBILE)};

    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        margin-right: ${getCssSizeValue(10, BREAKPOINTS.DESKTOP)};
    }
`

const StyledContactButtonWrapper = styled.div`
    display: none;
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        display: initial;
    }
`

const StyledLogoWrapper = styled.div`
	z-index: 1000;
`;

const StyledSingleNavItem = styled(Button)`
    text-transform: lowercase;
    font-weight: ${props => props.theme.fontWeight.semiBold};
    color: var(--secondary-color);
`

const TopRow = ({ firstMenuItem, contactButtonText, contactButtonLink, menuItems = [], globals }) => {
    const { isMenuTransparent } = useSelector(state => state.menu)
    const router = useRouter()
    const modalHeader = globals?.modalHeader || ''
    const modalDescription = globals?.modalDescription || '';
    const modalButtonTextClose = globals?.buttonTextClose || '';
    const modalButtonTextOpen = globals?.buttonTextOpen || '';

    const dispatch = useDispatch()
    const { locale } = useRouter()

    const bgColor = useSpecifyColor()
    const contactButtonLinkSlug = contactButtonLink ? `/${contactButtonLink?.slug}` : '';
    const { asPath } = useRouter()
    const normalizedPath = useMemo(() => asPath === "/" ? "home" : asPath, [asPath])

    const { instance: { __lenis } } = useScrollContext()
    const {isMenuSmall} = useHideTopBarOnScroll()
    useSetIsMenuTransparentOnPageLoad()

    const handleContactClick = () => {
        if (router.asPath.includes('contact')) {
            __lenis.scrollTo(0)
        } else {
            router.push(contactButtonLinkSlug, contactButtonLinkSlug, { scroll: false })
        }
    }

    return (
        <StyledTopRow
            data-cursor={CURSOR_TYPES.HTML}
            $bgColor={bgColor}
            id="menu-top-row"
            className={`menu-top-row px-main ${isMenuSmall ? 'menu-small' : ''}`}
        >
            <StyledLogoWrapper>
                <Logo firstMenuItem={firstMenuItem}/>
            </StyledLogoWrapper>
            <StyledTopRowBackground className={`top-row-background ${isMenuTransparent ? 'transparent' : ''}`}/>
            <StyledRightPartWrapper>
                {
                    menuItems.map((item, index) => {
                        const id = item?.id || index
                        const text = item?.link?.menuContext?.previewText || ''
                        const slug = item?.link?.slug || ''
                        const href = slug === 'home' ? '/' : `/${slug}`
                        const isActiveRoute = normalizedPath.includes(slug)
                        const typename = item?.link?.__typename || ''

                        const isBlogPage = typename === CONTENT_TYPES.GROUP_SINGLE_PAGE_RECORD

                        return (
                            isBlogPage ? (
                                <div
                                    key={id}
                                    onClick={() => {
                                    dispatch(setModalData({
                                        header: modalHeader,
                                        description: modalDescription,
                                        closeButtonText: modalButtonTextClose,
                                        openButtonText: modalButtonTextOpen,
                                        externalUrl: `${process.env.PLEO_GROUP_URL}${locale=== 'pl' ? '/pl/' :'/'}news`
                                    }))
                                    dispatch(setIsModalVisible(true))
                                }}>
                                    <StyledSingleNavItem
                                        variant='unstyled'
                                        key={id}
                                        data-cursor={CURSOR_TYPES.LINK}
                                        className={`underline-animation ${isActiveRoute ? 'active' : ''}`}
                                    >
                                        {text}
                                    </StyledSingleNavItem>
                                </div>
                                ) : (
                                <Link href={href} key={id}>
                                    <StyledSingleNavItem
                                        variant='unstyled'
                                        key={id}
                                        data-cursor={CURSOR_TYPES.LINK}
                                        className={`underline-animation ${isActiveRoute ? 'active' : ''}`}
                                    >
                                        {text}
                                    </StyledSingleNavItem>
                                </Link>
                                )
                        )
                    })
                }
                <StoryButton/>
                <StyledContactButtonWrapper>
                    <LetsTalkButton onClick={handleContactClick} pleo="design">
                        {contactButtonText}
                    </LetsTalkButton>
                </StyledContactButtonWrapper>
                <LanguageChange />
            </StyledRightPartWrapper>
        </StyledTopRow>
    );
};

export default TopRow;
