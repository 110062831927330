import React, {forwardRef, memo, useImperativeHandle, useRef} from 'react';
import NextImage from 'next/image';
import styled from 'styled-components';
import {IMAGE_FALLBACK} from "@repo/utils/constants";
import {precise} from "@repo/utils";

const DEFAULT_FOCAL_POINT = { X: 0.5, Y: 0.5 };
const DEFAULT_SIZES = '100%';

const Container = styled.figure`
	display: flex;
	width: 100%;
	height: 100%;
	position: relative; //When using next js image with fill prop, the parent element must have position: relative
	overflow: hidden;
	z-index: 1;
	margin: 0;

	&.transparent-3d{
		opacity: 0;
		//visibility: hidden;
	}
`;

const defaultAlt = 'image'

export function formatImageCMSData(data,showMeta=false){
	const width = data?.width || IMAGE_FALLBACK.width;
	const height = data?.height || IMAGE_FALLBACK.height;
	return {
		url: data?.url || IMAGE_FALLBACK.url,
		alt: data?.alt || IMAGE_FALLBACK.alt,
		width,
		height,
		placeholder: data?.blurUpThumb || IMAGE_FALLBACK.placeholder,
		blurUpThumb: data?.blurUpThumb || IMAGE_FALLBACK.blurUpThumb,
		focalPoint: data?.focalPoint || IMAGE_FALLBACK.focalPoint,
		aspectRatio: data?.aspectRatio || precise((width/height) || 16/9, 2),
		metaData: {
			author: data?.author || null,
			copyright: data?.copyright || null,
			title: data?.title || null,
		},
		showMetaData: showMeta
	};
}

// eslint-disable-next-line no-redeclare
const Image = forwardRef(function Image(
	{
		src = '',
		alt: passedAlt = defaultAlt,
		sizes = DEFAULT_SIZES,
		priority = false,
		placeholder = 'empty',
		blurDataURL = '',
		fit = 'cover',
		focalPoint = { x: DEFAULT_FOCAL_POINT.X, y: DEFAULT_FOCAL_POINT.Y },
		fill=true,
		children,
		visible=true,
		className='',
		...props
	},
	fref,
) {
	const ContainerRef= useRef()

	useImperativeHandle(fref, () => ContainerRef.current, [])
	const alt = passedAlt || defaultAlt
	if(!visible) return null
	return (
		<Container ref={ContainerRef} className={`image ${className}`} {...props}>
			<NextImage
				src={src}
				fill={fill}
				priority={priority}
				placeholder={placeholder}
				blurDataURL={blurDataURL}
				alt={alt}
				loading="eager"
				sizes={sizes}
				style={{
					objectFit: fit,
					objectPosition: `${(focalPoint?.x) * 100}% ${(focalPoint?.y) * 100}%`,
				}}
			/>
			{children}
		</Container>
	);
});

export default memo(Image);
