import React, {forwardRef, useEffect, useImperativeHandle, useMemo} from 'react';
import styled, { css } from 'styled-components';
import NextLink from 'next/link';
import {getCssSizeValue, getFontSize, getSlug, handleSetPositionOnMouseMove} from "@repo/utils";
import {BREAKPOINTS} from "../../../styles/themeSettings";
import {CURSOR_TYPES} from "@repo/utils/constants";
import {useExpandBackgroundAnimation, useHover} from "../../../index";


const styles = css`
	display: flex;
	align-items: center;
	column-gap: 14px;
	font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
	transition: all var(--color-transition-duration) var(--ease);
	font-family: var(--font);
	text-decoration: none;
	position: relative;
	cursor: pointer;
	width: fit-content;
	height: fit-content;
	white-space: nowrap;
	overflow: hidden;
	@media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
		font-size: ${getFontSize(16, BREAKPOINTS.MOBILE)};
	}
	.children {
		position: relative;
		z-index: 1;
		display: flex;
		align-items: center;
		column-gap: 20px;
	}
	span{
		display: block;
		position: relative;
		width: 5px;
		height: 5px;
		border-radius: 50%;
		border: solid 2px var(--color, white);
		transition: all var(--color-transition-duration) var(--ease);
	}
	&.unstyled {
		overflow: initial;
	}
	&:not(&.unstyled){
		font-weight: ${({ theme }) => theme?.fontWeight?.semiBold || 600};
		padding:
			${getCssSizeValue(12, BREAKPOINTS.MOBILE)}
			${getCssSizeValue(20, BREAKPOINTS.MOBILE)}
			${getCssSizeValue(12, BREAKPOINTS.MOBILE)}
			${getCssSizeValue(22, BREAKPOINTS.MOBILE)}
		;
		line-height: 13px;
		border-radius: 100px;
		outline: solid 2px transparent;
		background-color: var(--supp-color, transparent);
		color: var(--color, white);
		border: solid 2px var(--color, white);
		@media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
			padding:
				${getCssSizeValue(14, BREAKPOINTS.MOBILE)}
				${getCssSizeValue(20, BREAKPOINTS.MOBILE)}
				${getCssSizeValue(14, BREAKPOINTS.MOBILE)}
				${getCssSizeValue(22, BREAKPOINTS.MOBILE)}
		;}
		&.primary{
			--background: white;
			--color: ${({ theme }) => theme?.colors?.twilightBlue || 'black'};
			--supp-color: #EEEFF1;
			border: solid 2px white;
		}
		&.secondary{
			--background: #224553;
			--color: ${({ theme }) => theme?.colors?.white || 'white'};
			--supp-color: ${({ theme }) => theme?.colors?.twilightBlue || 'black'};
			border: solid 2px #224553;
		}
		&.tertiary{
			--color: ${({ theme }) => theme?.colors?.twilightBlue || 'twilightBlue'};
			--supp-color: ${({ theme }) => theme?.colors?.white || 'white'};
			--background: #EEEFF1;
			border: solid 2px #EEEFF1;
		}
		&.quaternary {
			--color: ${({ theme }) => theme?.colors?.twilightBlue || 'black'};
			--supp-color: transparent;
			border: none;
			--background: transparent;
			&:hover, &.hover{
				--color: ${({ theme }) => theme?.colors?.twilightBlue || 'white'};
				//--supp-color: ${({ theme }) => theme?.colors?.twilightBlue || 'black'};
			}
		}
		&.quinary {
			--color: ${({ theme }) => theme?.colors?.white || 'white'};
			--supp-color: transparent;
			border: none;
			--background: transparent;

			&:hover, &.hover{
				--color: ${({ theme }) => theme?.colors?.white || 'white'};
			}
		}

		&.contact-submit {
			--color: ${({ theme }) => theme?.colors?.twilightBlue || 'black'};
			--supp-color: ${({ theme }) => theme?.colors?.white || 'white'};
			border: solid 2px transparent;
			&:hover{
				--color: ${({ theme }) => theme?.colors?.white || 'white'};
				--supp-color: transparent;
				border: solid 2px var(--color);
			}
		}
		
		&.lets-talk{
			border: unset !important;
			@media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
			padding:
				${getCssSizeValue(15, BREAKPOINTS.MOBILE)}
				${getCssSizeValue(21, BREAKPOINTS.MOBILE)}
				${getCssSizeValue(15, BREAKPOINTS.MOBILE)}
				${getCssSizeValue(23, BREAKPOINTS.MOBILE)}
		;
				//padding: 15px 21px 15px 23px;
		}
			--color: ${({ theme }) => theme?.colors?.twilightBlue || 'black' } !important;
			--supp-color: ${({ theme }) => theme?.colors?.white || 'white'} !important;

			&:hover{
				--color: ${({ theme }) => theme?.colors?.white || 'white' } !important;
				--supp-color: ${({ theme }) => theme?.colors?.twilightBlue || 'black' } !important;
				--background: ${({ theme }) => theme?.colors?.twilightBlue || 'black' } !important;
				&.digital {
					--supp-color: ${({ theme }) => theme?.colors?.red || 'red' } !important;
					--background: ${({ theme }) => theme?.colors?.red || 'red' } !important;
				}
				&.design {
					--supp-color: ${({ theme }) => theme?.colors?.designBlue || 'blue' } !important;
					--background: ${({ theme }) => theme?.colors?.designBlue || 'blue' } !important;
				}
				&.group {
					--supp-color: ${({ theme }) => theme?.colors?.groupGray || 'gray' } !important;
					--background: ${({ theme }) => theme?.colors?.groupGray || 'gray' } !important;
				}
			}
			
		}

		&.no-border {
			border: none;
			background-color: transparent;
		}
	}
	
	&.unstyled{
		border: none;
		background-color: transparent;
		span{
			display: none;
		}
	}
	&.break-text {
		white-space: initial;
		overflow-wrap: break-word;
	}

	&[disabled=disabled], &:disabled {
		opacity: 0.6;
	}
`;

const ButtonBackground = styled.div`
    position: absolute;
    background: var(--background);
    border-radius: 50%;
    top: var(--y, 50%);
    left: var(--x, 50%);
    transform: translate(-50%, -50%);
    z-index: 0;
    width: var(--width);
    height: var(--height);
`

const BTN = styled.button`
	${styles}
`;
const Anchor = styled.a`
	${styles}
`;
const Link = styled(NextLink)`
	${styles}
`;

function getTag(tag = '') {
	switch (tag) {
		case 'a':
			return Anchor;
		case 'button':
			return BTN;
		case 'link':
			return Link;
		default:
			return BTN;
	}
}

export function getButtonBlockData(data){
	const text = data?.button?.text || ''
	const link = getSlug(data?.button?.link?.slug || '')
	const typename = data?.button?.link?.__typename || ''
	const variant = data?.button?.variant || 'secondary';
	return {
		text,
		link,
		typename,
		variant,
		exists: Boolean(link) || Boolean(text)
	}
}



const Button = forwardRef(function Button(
	{
		as = 'button',
		variant='secondary',
		className='',
		cursor = CURSOR_TYPES.BUTTON_WITHOUT_BORDER,
		children, ...props }, fref) {
	const Tag = useMemo(() => getTag(as), [as]);
	const additionalProps = {}
;
	const dataCursorProps = (variant === 'secondary' ? CURSOR_TYPES.BUTTON_WITHOUT_BORDER_WHITE : cursor )
	if(variant !=='unstyled' && cursor !== null){
		if(className.includes('lets-talk')) {
			console.log("let's talk")
		}
		additionalProps['data-cursor'] = dataCursorProps;
	}

	const [ref, isHovering] = useHover({ onlyDesktop: true })
	const hoverTl = useExpandBackgroundAnimation(ref, true)

	useEffect(() => {
		const isHoveredInParent = ref.current.classList.contains('hover');
		(isHovering || isHoveredInParent )? hoverTl.current.play() : hoverTl.current.reverse()
	}, [isHovering, ref, className]);

	useImperativeHandle(
		fref,
		() => ref.current,
		[],
	);

	return (
		<Tag
			ref={ref}
			scroll={false}
			className={`button ${className} ${variant}`} {...additionalProps} {...props}
			onMouseMove={handleSetPositionOnMouseMove}
		>
			{variant !== 'unstyled' && (
				<ButtonBackground
					className={variant}
				/>
			)}
			<div ref={fref} className='children'>{children}</div>
			<span className="dot"/>
		</Tag>
	);
});
export default Button;
