import styled from "styled-components";
import {
    getColorScheme,
    getCssSizeValue,
    getFontSize, getHtmlFromRedactorField,
    getIdWithoutSpecialSymbols,
    getPaddingsClassFromData
} from "@repo/utils";
import {BREAKPOINTS} from "../../../styles/themeSettings";
import useEmblaCarousel from "embla-carousel-react";
import Client from "./Client";
import {Reveal} from "../../../index";
import {CURSOR_TYPES} from "@repo/utils/constants";

const StyledWrapper = styled.section`
    position: relative;
    color: var(--secondary-color);
`

const StyledHeader = styled.div`
    padding: 0 var(--paddingX);
    margin-bottom: ${getCssSizeValue(45, BREAKPOINTS.MOBILE)};
    font-size: ${getFontSize(24, BREAKPOINTS.MOBILE)};
    line-height: ${getFontSize(31, BREAKPOINTS.MOBILE)};
    letter-spacing: -0.96px;
    
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        margin-bottom: ${getCssSizeValue(60, BREAKPOINTS.TABLET)};
        font-size: ${getFontSize(48, BREAKPOINTS.TABLET)};
        line-height: ${getFontSize(56, BREAKPOINTS.TABLET)};
        letter-spacing: -3px;
    }
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        margin-bottom: ${getCssSizeValue(86, BREAKPOINTS.DESKTOP)};
        font-size: ${getFontSize(48, BREAKPOINTS.DESKTOP)};
        line-height: ${getFontSize(56, BREAKPOINTS.DESKTOP)};
        letter-spacing: -1.92px;
    }
`

const StyledCarouselContainer = styled.div`
    width: 100%;
`

const EmblaViewPort = styled.div`
	overflow: hidden;
`

const EmblaContainer = styled.div`
    backface-visibility: hidden;
    display: flex;
    touch-action: pan-y pinch-zoom;
    gap: ${getCssSizeValue(15, BREAKPOINTS.MOBILE)};

    > :first-child { 
        margin-left: var(--paddingX);
    }
    
    > :last-child {
        margin-right: var(--paddingX);
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        gap: ${getCssSizeValue(35, BREAKPOINTS.DESKTOP)};
    }
`

export default function ClientsAboutUs({ data }) {
    const id = getIdWithoutSpecialSymbols(data?.id || '')
    const paddings = getPaddingsClassFromData(data)
    const colorScheme = getColorScheme(data)

    const header = data?.header || ''
    const buttonText = data?.buttonText || ''
    const clients = data?.clients || []

    const [emblaRef, emblaApi] = useEmblaCarousel( { loop: false, dragFree: true, align: 'start' })

    return (
        <StyledWrapper
            id={id}
            className={`${paddings}`}
            data-color-scheme={colorScheme}
        >
            <Reveal onInView>
                <StyledHeader dangerouslySetInnerHTML={getHtmlFromRedactorField(header)} />
            </Reveal>

            <StyledCarouselContainer data-cursor={CURSOR_TYPES.SLIDER}>
                <EmblaViewPort ref={emblaRef}>
                    <EmblaContainer>
                        {clients.map(client => <Client key={client?.id || ''} client={client} buttonText={buttonText} /> )}
                    </EmblaContainer>
                </EmblaViewPort>
            </StyledCarouselContainer>
        </StyledWrapper>
    )
}