import {Alignment, Fit, Layout, useRive} from "@rive-app/react-canvas";

const RIVE_INIT = {
    src: '/new_card.riv',
    layout: new Layout({
        fit: Fit.Cover,
        alignment: Alignment.Center,
    }),
    autoplay: true,
}

export default function NewCardModalRive({ className = '' }) {
    const { rive, RiveComponent }  = useRive({
        ...RIVE_INIT
    });

    return (
        <RiveComponent className={className} />
    )
}