import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import styled from 'styled-components';
import FloatingVideo from "../../WebGL/FloatingVideo";
import {gsap, ScrollTrigger, useGSAP, ScrambleTextPlugin, Flip} from "@repo/utils/gsap";
import {getFontSize, getHtmlFromRedactorField, getIdWithoutSpecialSymbols} from "@repo/utils";
import {BREAKPOINTS} from "../../../../styles/themeSettings";
import lottie from 'lottie-web';
import LottiePleo from '../../../assets/lottie/Lottie-PLEO_transparent-blob.json';
import {CURSOR_TYPES} from "@repo/utils/constants";
import dynamic from "next/dynamic";
import {FullScreenCursor} from "../../common/FullScreenVideo";
import {useIsDesktop} from "../../../../index";

const FullScreenVideo = dynamic(() => import('../../common/FullScreenVideo'), {
    ssr: false,
    loading: () => null
})

export const LOTTIE_WRAPPER_ID = 'module-wrapper-lottie'

const StyledThreeImageContainer = styled.div`
    margin: 30svh 0 0;

    @media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        margin: 0 0 0;
    }

    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        margin: 30svh 0 0;
    }
`

const ThreeImageWrapper = styled.div`
    //width: 100%;
    position: relative;
    width: 100%;
    aspect-ratio: ${({ aspectRatio }) => aspectRatio};
    height: auto;
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        width: 50%;
    }
`

const StyledWhoWeAreContainer = styled.div`
    background-color: var(--primary-color);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
    transition: background-color var(--color-transition-duration) var(--ease);
`

const StyledLargeTextContainer = styled.div`
    width: 100%;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
`

const StyledLargeText = styled.div`
    color: #FFF;
    font-size: ${getFontSize(160, BREAKPOINTS.MOBILE)};
    line-height: ${getFontSize(136, BREAKPOINTS.MOBILE)};
    font-style: normal;
    font-weight: 900;
    letter-spacing: 0;
    @media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        font-size: ${getFontSize(320, BREAKPOINTS.TABLET)};
        line-height: ${getFontSize(280, BREAKPOINTS.TABLET)};
    }
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        font-size: ${getFontSize(800, BREAKPOINTS.DESKTOP)};
        letter-spacing: 0;
        line-height: ${getFontSize(650, BREAKPOINTS.DESKTOP)};
        margin-left: 0;
    }
`

const StyledSingleWhoWeAreBlock = styled.div`
    height: 300svh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 10svh;
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        padding-top: 12svh;
    }
    span.navy {
        color: #051D27;
        font-size: ${getFontSize(65, BREAKPOINTS.MOBILE)};
        line-height: ${getFontSize(65, BREAKPOINTS.MOBILE)};
        font-weight: 900;
        text-transform: uppercase;
        @media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
            font-size: ${getFontSize(129, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(129, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
            font-size: ${getFontSize(309, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(309, BREAKPOINTS.DESKTOP)};
        }
        @media(min-width: 1921px) {
            font-size: ${getFontSize(370, BREAKPOINTS.LARGE_DESKTOP)};
            line-height: ${getFontSize(370, BREAKPOINTS.LARGE_DESKTOP)};
        }
    }
    span.white {
        color: #FFF;
        font-size: ${getFontSize(65, BREAKPOINTS.MOBILE)};
        line-height: ${getFontSize(65, BREAKPOINTS.MOBILE)};
        font-weight: 900;
        text-transform: uppercase;
        @media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
            font-size: ${getFontSize(129, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(129, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
            font-size: ${getFontSize(309, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(309, BREAKPOINTS.DESKTOP)};
        }
        @media(min-width: 1921px) {
            font-size: ${getFontSize(370, BREAKPOINTS.LARGE_DESKTOP)};
            line-height: ${getFontSize(370, BREAKPOINTS.LARGE_DESKTOP)};
        }
    }
    .first-navy {
        opacity: 0;
    }
    .visible {
        opacity: 1;
    }
    .hidden {
        opacity: 0;
    }
    .first {
        opacity: 0;
    }
    &.hidden {
        display: none;
    }
    &.opacity {
        opacity: 0;
    }
`

const StyledHeaderWrapper = styled.div`
    width: 100%;
    span.white {
        position: relative;
    }
`

const StyledImageAndDescriptionWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    text-align: center;
    margin-top: 15px;
    @media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        text-align: left;
    }
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        justify-content: flex-end;
        text-align: left;
        margin-top: 0;
    }
`

const StyledImageWrapper = styled.div`
    max-height: 100%;
    height: 90vw;
    width: 100%;
    @media(min-width: 768px) and (orientation: portrait) {
        height: 80vw;
    }
    @media(min-width: 768px) and (orientation: landscape) {
        height: 70svh;

    }
    @media(min-width: 1024px) and (orientation: landscape) {
        height: 80svh;
    }
    canvas {
        aspect-ratio: 1;
        width: 100%;
        height: auto;
        object-fit: contain;
        //margin-bottom: 50px;
    }
`

const StyledLottieContainer = styled.div`
    width: 100%;
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    //bottom: 0;
    bottom: 80px;
    @media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        width: 80%;
        left: 10%;
        bottom: 50px;
        justify-content: center;
    }
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        padding-right: 100px;
        width: 67%;
        height: 100%;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-end;
        bottom: unset;
        left: 0;
    }
`

const StyledDescriptionWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: #051D27;
    font-size: ${getFontSize(22, BREAKPOINTS.MOBILE)};
    font-style: italic;
    font-weight: 300;
    line-height: normal;
    @media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        width: 80%;
        font-size: ${getFontSize(24, BREAKPOINTS.TABLET)};
        align-items: flex-start;
    }
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        width: 33%;
        justify-content: flex-start;
        align-items: flex-start;
        padding-left: 45px;
        font-size: ${getFontSize(34, BREAKPOINTS.DESKTOP)};
    }
`

const StyledFixedPleoDestination = styled.div`
    position: absolute;
    right: unset;
    left: 0;
    //top: 9%;
    top: 0;
    width: 100%;
    //height: 70%;
    height: 100%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    div {
        //border: 1px solid deeppink;
        opacity: 0;
        font-size: ${getFontSize(65, BREAKPOINTS.MOBILE)};
        line-height: ${getFontSize(55, BREAKPOINTS.MOBILE)};
        //line-height: 60px;
    }
    @media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        //height: 60%;
        //top: 17%;
        height: 100%;
        top: 0;
        div {
            //line-height: 120px;
            font-size: ${getFontSize(129, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(113, BREAKPOINTS.TABLET)};
        }
    }
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        height: 100%;
        top: 0;
        right: unset;
        left: 0;
        width: 100%;
        div {
            opacity: 0;
            font-size: ${getFontSize(309, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(232, BREAKPOINTS.DESKTOP)};
        }
    }
    @media(min-width: 1921px) {
        div {
            font-size: ${getFontSize(370, BREAKPOINTS.LARGE_DESKTOP)};
            line-height: ${getFontSize(309, BREAKPOINTS.LARGE_DESKTOP)};
        }
    }
`

const StyledSliderContainer = styled.div`
    display: block;
    height: 100svh;
    position: relative;
    width: 100%;
`

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(Flip);
gsap.registerPlugin(ScrambleTextPlugin)
const WhoWeAreCommon = ({ data, setInThirdSlide }) => {
    const largeText = data?.largeText || '';
    const id = getIdWithoutSpecialSymbols(data?.id);
    const sectionHeader = data?.sectionHeader || '';
    const whoWeAreBlocks = data?.whoWeAreBlocks || [];
    const imageUrl = data?.image?.url || '';
    const videoUrl = data?.video?.url || '';
    const videoWidth = data?.video?.width || 0;
    const videoHeight = data?.video?.height || 1;
    const videoAspectRatio = videoWidth / videoHeight === 0 ? '16/9' : videoWidth / videoHeight;
    const imageWidth = data?.image?.width || 0;
    const imageHeight = data?.image?.height || 1;
    const imageRef = useRef()
    const aspectRatio = imageWidth / imageHeight === 0 ? '16/9' : imageWidth / imageHeight;
    const wrapperRef = useRef();
    const blockRef = useRef();
    const initialTextFirstPart = whoWeAreBlocks?.[0]?.textFirstPart || '';
    const initialTextSecondPart = whoWeAreBlocks?.[0]?.textSecondPart || '';
    const initialDescription = whoWeAreBlocks?.[0]?.description || '';
    const [textFirstPartState, setTextFirstPartState] = useState(initialTextFirstPart);
    const [textSecondPartState, setTextSecondPartState] = useState('PLEO');
    const [description, setDescription] = useState(initialDescription);
    const [fullScreen, setFullScreen] = useState(false)
    const isDesktop = useIsDesktop()
    const fullScreenData = useMemo(() => {
        const src = data?.fullScreenVideo?.link?.url || ''
        return ({
            enabled: Boolean(data?.fullScreenVideo),
            src
        })
    }, [data])


    const handleOpenFullScreenVideo = useCallback(() => {
        if(!fullScreenData.enabled) return
        setFullScreen(true)
    },[fullScreenData.enabled])

    const handleCloseFullScreenVideo = useCallback(() => {
        if(!fullScreenData.enabled) return
        setFullScreen(false)
    },[fullScreenData.enabled])

    const handleStaggerTextAnimation = (key) => {
        const target = document.querySelectorAll('.slide-text-wrapper');
        const elements = target?.[0]?.children || null;
        if (elements && (key === 0 || key === 1 )) {
            gsap.fromTo(
                target[0].children,
                {
                    y: '+=60',
                    autoAlpha: 0,
                },
                {
                    stagger: 0.2,
                    y: 0,
                    autoAlpha: 1,
                    duration: 0.8,
                    ease: 'power4.easeOut',
                },
            );
        }
    };
    const handleFirstPartTextAnimation = (selector, key, className) => {
        gsap.to(selector, {
            duration: 1,
            scrambleText: {
                text: whoWeAreBlocks[key].textFirstPart,
                // text: 'PE',
                speed: 0.3,
                newClass: className,
                chars:whoWeAreBlocks[key].textFirstPart
                // chars:'PE'
            }
        });
    }
    const handleSecondPartTextAnimation = (selector, key, className) => {
        gsap.to(selector, {
            duration: 1,
            delay: 0.2,
            scrambleText: {
                text: whoWeAreBlocks[key].textSecondPart,
                speed: 0.6,
                newClass: className,
                chars:whoWeAreBlocks[key].textSecondPart
            }
        });
    }
    useGSAP(() => {
        const flip = Flip.fit('#large-text', '#pleo-destination-0', {
            scale: true,
            // duration: 0.7,
            duration: 0.5,
            ease: 'none',
            onComplete: () => {
                // ScrollTrigger.getById('large-text').enable(false);
                gsap.to('#large-text', {
                    opacity: 0,
                    // opacity: 1,
                    ease: 'none',
                    duration: 0.05
                })                 ;
                gsap.to('.first', {
                    opacity: 1,
                    ease: 'none',
                    duration: 0.2
                })
                gsap.to('.first-navy', {
                    opacity: 1,
                    duration: 0.1
                })
                gsap.to('#text-first-part', {
                    duration: 1.4,
                    ease: "power3.inOut",
                    scrambleText: {
                        text: whoWeAreBlocks[0].textFirstPart,
                        speed: 0.5,
                        chars: whoWeAreBlocks[0].textFirstPart
                    }
                });
                gsap.to('#text-first-part' , {
                    opacity: 1,
                    duration: 0
                })
                gsap.to('#text-second-part' , {
                    opacity: 1,
                    duration: 0.3,
                    delay: 0,
                })
                gsap.to('#text-second-part', {
                    duration: 1,
                    delay: 0.3,
                    scrambleText: {
                        text: whoWeAreBlocks[0].textSecondPart,
                        // text: 'PLEO',
                        speed: 0.3,
                        // chars:'PLEO',
                        chars:whoWeAreBlocks[0].textSecondPart,
                        newClass: 'white visible'
                    }
                });
            }
        });

        ScrollTrigger.create({
            id:"flip-animation",
            trigger: '#large-text',
            start: 'top top',
            scrub: true,
            pinSpacing: true,
            animation: flip,
            // invalidateOnRefresh: true,
            // toggleActions: 'play pause resume reverse',
            // markers: true,
            onEnter: () => {
                // ScrollTrigger.getById('large-text').disable(false);
            },
            onEnterBack: () => {
                gsap.to('#text-second-part', {
                    duration: 0.2,
                    scrambleText: {
                        text: 'PLEO',
                        speed: 0.3,
                        chars:'PLEO'
                    }
                });
                gsap.to('#text-second-part' , {
                    opacity: 0,
                    duration: 0
                })
                gsap.to('#text-first-part' , {
                    opacity: 0,
                    duration: 0.3,
                })
                gsap.to('#large-text', {
                    opacity: 1,
                    ease: 'none',
                    duration: 0,
                })
            },
            onLeave: () => {
            }
        })

        ScrollTrigger.create({
            id: 'home-vertical-slider',
            trigger: '#slider-wrapper',
            pin: true,
            scroller: null,
            start: 'top top',
            scrub: 1,
            end: '+=900%',
            pinSpacing: true,
        });
        const slides = document.querySelectorAll('.vertical-slide');
        slides.forEach((slide, key) => {
            ScrollTrigger.create({
                id: `single-slide-${key}`,
                trigger: slide,
                start: 'top top',
                endTrigger: slide,
                end: 'bottom top',
                // markers: true,
                scrub: true,
                pinSpacing: false,
                onEnter: () => {
                    if(key === 0) {
                        gsap.to('#large-text', {
                            duration: 0,
                            delay: 0.2,
                            scrambleText: {
                                // text: 'PLEO',
                                text: 'OPLE',
                                speed: 0.6,
                                chars: 'OPLE'
                                // chars: 'PLEO'
                            }
                        });
                    }
                    if(key === 1) {
                        setTextFirstPartState(whoWeAreBlocks[1].textFirstPart)
                        setDescription(whoWeAreBlocks[1].description)
                        handleFirstPartTextAnimation('#text-first-part', key, 'white')
                        handleSecondPartTextAnimation('#text-second-part', key, 'navy')
                        gsap.to('#text-first-part', {
                            color: '#FFF',
                            duration: 0.3,
                            ease: "power2.inOut"
                        })
                        gsap.to('.slide-text-wrapper', {
                            transition: 'all 1s ease-in-out',
                            duration: 0.8,
                            ease: 'power1.easeOut',
                        });
                    }
                    if(key === 2) {
                        setDescription(whoWeAreBlocks[2].description)
                        handleFirstPartTextAnimation('#text-first-part', key, 'white')
                        handleSecondPartTextAnimation('#text-second-part', key, 'navy')
                        gsap.to('.slide-text-wrapper', {
                            transition: 'all 1s ease-in-out',
                            duration: 0.8,
                            ease: 'power1.easeOut',
                        });
                    }
                },
                onEnterBack: () => {
                    if (key === 0) {
                        setDescription(whoWeAreBlocks[0].description)
                        handleFirstPartTextAnimation('#text-first-part', 0, 'navy')
                        handleSecondPartTextAnimation('#text-second-part', 0, 'white')
                        gsap.to('#text-first-part', {
                            color: '#051D27',
                            duration: 0.3,
                            ease: "power2.inOut"
                        })
                        gsap.to('#text-second-part', {
                            color: '#FFF',
                            duration: 0.3,
                            ease: "power2.inOut"
                        })
                    }
                    if (key === 1) {
                        setDescription(whoWeAreBlocks[1].description)
                        handleFirstPartTextAnimation('#text-first-part', 1, 'white')
                        handleSecondPartTextAnimation('#text-second-part', 1, 'navy')
                        gsap.to('.slide-text-wrapper', {
                            duration: 0.8,
                            ease: 'easeInOut',
                        });
                    }
                },
                onLeave: () => {
                    handleStaggerTextAnimation(key);
                },
                onLeaveBack: () => {
                    handleStaggerTextAnimation(key - 1);
                    if(key === 0) {
                        gsap.to('#large-text', {
                            duration: 0.7,
                            delay: 0.3,
                            scrambleText: {
                                text: 'PLEO',
                                speed: 0.6,
                                // newClass: className,
                                // chars: 'OPLE'
                                chars: 'PLEO'
                            }
                        });
                    }
                },

            });
        });
        return() => {
            const shipAnimationST0 = ScrollTrigger.getById('single-slide-0');
            const shipAnimationST1 = ScrollTrigger.getById('single-slide-1');
            const shipAnimationST2 = ScrollTrigger.getById('single-slide-2');
            shipAnimationST0?.kill();
            shipAnimationST1?.kill();
            shipAnimationST2?.kill();
        }
    },{scope: wrapperRef, dependencies: []})
    const [currentFrame, setCurrentFrame] = useState(0);
    const [totalFramesNumber, setTotalFramesNumber] = useState(0);

    const lottieTween = useRef();


    const LottieScrollTrigger = (vars) => {
        const playhead = { frame: 0 };
        const target = gsap.utils.toArray(vars.target)[0];
        const st = {
            id: 'pleo-animation',
            trigger: target,
            pin: true,
            ease: "none",
            start: 'top top',
            invalidateOnRefresh: true,

            // markers: true,
            scrub: 1,
        };
        const container = '.lottie-container';
        let animationItem = lottie.loadAnimation({
            container: document.querySelector(container),
            // renderer: vars.renderer || 'svg', // WORKS ON ALL DEVICES BUT NOT iPhone13
            renderer: 'canvas', // WORKS ON ALL DEVICES
            loop: false,
            autoplay: false,
            animationData: vars.path,
            name: 'lightAnimation',
        });
        for (let p in vars) {
            // let users override the ScrollTrigger defaults
            st[p] = vars[p];
        }
        setTotalFramesNumber(animationItem.totalFrames);
        animationItem.goToAndStop(currentFrame, true);
        animationItem.addEventListener('DOMLoaded', function () {
            gsap.to(playhead, {
                frame: animationItem.totalFrames - 1,
                ease: 'none',
                onUpdate: () => {
                    setCurrentFrame(playhead.frame);
                    const inThirdSlide = playhead.frame > 600
                    setInThirdSlide(inThirdSlide)
                    animationItem.goToAndStop(playhead.frame, true);
                },
                scrollTrigger: st,
            });
            ScrollTrigger.sort();
            ScrollTrigger.refresh();
        });
        return animationItem;
    };

    useEffect(() => {
        const ctx = gsap.context(() => {
            if (!lottieTween.current) {
                lottieTween.current = LottieScrollTrigger({
                    target: `#${LOTTIE_WRAPPER_ID}`,
                    path: LottiePleo,
                    speed: 'medium',
                    start: 'top top',
                    end: '+=900%',
                    pin: '.main-gsap',
                    scrub: true,
                });
            }
        });
        return () => ctx && ctx.revert();
    }, []);

    const cursorEnabled = fullScreenData.enabled && !isDesktop

    return (
        <>
            {fullScreenData.enabled && (
                <FullScreenVideo
                    src={fullScreenData.src}
                    isOpen={fullScreen}
                    handleClose={handleCloseFullScreenVideo}
                />
            )}
            <StyledThreeImageContainer
                data-cursor={fullScreenData.enabled ? CURSOR_TYPES.CHECK : undefined}
                onClick={handleOpenFullScreenVideo}
            >
                {/*<FixedText>{currentFrame}/{totalFramesNumber}</FixedText>*/}
                <ThreeImageWrapper aspectRatio={videoAspectRatio}>
                    {cursorEnabled && <FullScreenCursor /> }
                    <FloatingVideo
                        ref={imageRef}
                        // video={MockupVideo}
                        video={videoUrl}
                    />
                </ThreeImageWrapper>
            </StyledThreeImageContainer>
            <StyledWhoWeAreContainer id={id} data-cursor={CURSOR_TYPES.HTML} ref={wrapperRef}>
                <StyledLargeTextContainer id='large-text-container'>
                    <StyledLargeText id={'large-text'}>{largeText}</StyledLargeText>
                </StyledLargeTextContainer>
                <StyledSliderContainer className="slides" id="slider-wrapper">
                    <StyledLottieContainer id={LOTTIE_WRAPPER_ID}>
                        <StyledImageWrapper className="lottie-container" id="module-wrapper"/>
                    </StyledLottieContainer>
                    {whoWeAreBlocks.map((item, key) => {
                        const id = item?.id || ''
                        const title = item?.title || ''
                        const textFirstPart = item?.textFirstPart || ''
                        const textSecondPart = item?.textSecondPart || ''
                        const desc = item?.description || ''
                        const imageUrl = item?.image?.url || ''
                        return (
                            <StyledSingleWhoWeAreBlock
                                className={key === 2 ? 'hidden vertical-slide px-main' : key === 1 ? 'vertical-slide px-main opacity' : 'vertical-slide px-main'} id={`block-${key}`}
                                key={title || id || key}>
                                <StyledHeaderWrapper>
                                    <span id="text-first-part" className={key === 0 ? 'first-navy navy' : 'navy'}>{textFirstPartState}</span>
                                    <span className={key === 0 ? 'first white' : 'white'}>
                                        <span id='text-second-part'>{textSecondPartState}</span>
                                        {/*<span id='text-second-part'>PLEO</span>*/}
                                        {/*<StyledFixedPleoDestination id={`pleo-destination-${key}`}/>*/}
                                        <StyledFixedPleoDestination>
                                            <div id={`pleo-destination-${key}`}>{textSecondPartState}</div>
                                        </StyledFixedPleoDestination>
                                    </span>
                                </StyledHeaderWrapper>
                                <StyledImageAndDescriptionWrapper className='slide-text-wrapper'>
                                    <StyledDescriptionWrapper
                                        dangerouslySetInnerHTML={getHtmlFromRedactorField(description)}/>
                                </StyledImageAndDescriptionWrapper>
                            </StyledSingleWhoWeAreBlock>
                        )
                    })}
                </StyledSliderContainer>
            </StyledWhoWeAreContainer>
        </>
    );
};

export default WhoWeAreCommon;
