import styled from "styled-components";
import {
    getAspectRatioFromImage,
    getCssSizeValue,
    getFontSize,
    getHtmlFromRedactorField,
    isStringEmpty
} from "@repo/utils";
import {BREAKPOINTS, COLORS} from "../../../styles/themeSettings";
import {Image} from "../../../index";
import {useCallback, useMemo} from "react";
import {useRouter} from "next/router";
import {formatImageCMSData} from "../common/Image";

const EmblaSlide = styled.div`
    user-select: none;
    display: flex;
    flex: 0 0 80%;
    gap: ${getCssSizeValue(15, BREAKPOINTS.MOBILE)};

    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        flex: 0 0 90%;
        gap: ${getCssSizeValue(30, BREAKPOINTS.TABLET)};
    }

    @media (min-width: ${({theme}) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        flex: 0 0 60%;
        gap: ${getCssSizeValue(35)};
    }
`

const Asset = styled.div`
    display: none;

    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        display: block;
        aspect-ratio: 550/767;
        width: 50%;
        overflow: hidden;

        .image {
            height: 100%;
        }
    }
`

const TextAuthorWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    aspect-ratio: 310/552;
    background: ${({$projectColor}) => $projectColor || 'white'};

    padding: ${getCssSizeValue(22, BREAKPOINTS.MOBILE)} ${getCssSizeValue(24, BREAKPOINTS.MOBILE)} ${getCssSizeValue(40, BREAKPOINTS.MOBILE)};

    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        aspect-ratio: 550/767;
        width: 50%;
        gap: ${getCssSizeValue(30, BREAKPOINTS.TABLET)};
    }

    @media (min-width: ${({theme}) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        gap: ${getCssSizeValue(50, BREAKPOINTS.DESKTOP)};
        padding: ${getCssSizeValue(22)} ${getCssSizeValue(24)} ${getCssSizeValue(40)};
    }
`

const Text = styled.div`
    overflow: auto;
    height: 95%;
    -ms-overflow-style: none;
    scrollbar-width: none;
    font-size: ${getFontSize(16, BREAKPOINTS.MOBILE)};
    line-height: ${getFontSize(31, BREAKPOINTS.MOBILE)};
    color: ${({$textColor}) => $textColor || COLORS.twilightBlue};

    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) { 
        height: 90%;
        font-size: ${getFontSize(16, BREAKPOINTS.TABLET)};
        line-height: ${getFontSize(31, BREAKPOINTS.TABLET)};
    }

    @media (min-width: ${({theme}) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        font-size: ${getFontSize(24, BREAKPOINTS.DESKTOP)};
        line-height: ${getFontSize(36, BREAKPOINTS.DESKTOP)};
    }
`

const Author = styled.div`
    padding-top: ${getCssSizeValue(30, BREAKPOINTS.MOBILE)};
    font-weight: ${props => props.theme.fontWeight.semiBold};
    font-size: ${getFontSize(16, BREAKPOINTS.MOBILE)};
    line-height: ${getFontSize(36, BREAKPOINTS.MOBILE)};
    color: ${({$textColor}) => $textColor || COLORS.twilightBlue};

    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        font-size: ${getFontSize(16, BREAKPOINTS.TABLET)};
        line-height: ${getFontSize(36, BREAKPOINTS.TABLET)};
    }

    @media (min-width: ${({theme}) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        font-size: ${getFontSize(16, BREAKPOINTS.DESKTOP)};
        line-height: ${getFontSize(36, BREAKPOINTS.DESKTOP)};
    }
`

const StyledImage = styled(Image)`
    aspect-ratio: ${({$aspectRatio}) => $aspectRatio || '16/9'};
    width: 100%;
    height: auto;
`

export default function Client({client}) {
    const router = useRouter()
    const text = client?.text || ''
    const author = client?.author || ''
    const slug = client?.project?.slug || ''
    const projectColor = client?.project?.projectColor?.cssRgb || ''
    const textColor = client?.textColor?.cssRgb || ''
    const externalAsset = formatImageCMSData(client?.asset || {})
    const projectAsset = formatImageCMSData(client?.project?.coverPortrait)

    const imageSource = !isStringEmpty(externalAsset.url) ? externalAsset : projectAsset

    const handleClick = () => {
        router.push(`/projects/${slug}`)
    }

    return (
        <EmblaSlide>
            <Asset onClick={handleClick}>
                <StyledImage
                    src={imageSource.url}
                    alt={imageSource.alt}
                    sizes={'100vw'}
                    $aspectRatio={imageSource.aspectRatio}
                />
            </Asset>
            <TextAuthorWrapper data-lenis-prevent $projectColor={projectColor} onClick={handleClick} >
                <Text data-lenis-prevent $textColor={textColor} dangerouslySetInnerHTML={getHtmlFromRedactorField(text)}/>
                <Author $textColor={textColor}>{author}</Author>
            </TextAuthorWrapper>
        </EmblaSlide>
    )
}