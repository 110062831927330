import React, { useRef, memo } from 'react';
import {useVideoTexture} from "@react-three/drei";
import {gsap, useGSAP} from "@repo/utils/gsap";
import {COLOR_TRANSITION} from "../../../../styles/globalStyles";
import {PORTAL_LENS_RADIUS} from "@repo/utils/constants";

const Video3D = ({ src = '', autoplay=true, visible=false, stencil={}, ...props }) => {
    const MeshRef = useRef();
    const texture = useVideoTexture(src, { start: visible && autoplay });

    useGSAP(() => {
        const opacity = visible ? 1 : 0
        const delay = visible ? COLOR_TRANSITION : 0

        gsap.to(MeshRef.current.material, {
            delay,
            duration: COLOR_TRANSITION,
            opacity
        })
    },{
        dependencies: [visible],
        revertOnUpdate: true
    })


    return (
        <mesh ref={MeshRef} renderOrder={-1}>
            {/*<planeGeometry args={[sizes.width, sizes.height, 1]}/>*/}
            <circleGeometry args={[PORTAL_LENS_RADIUS, 32]}/>
            <meshBasicMaterial
                map={texture}
                opacity={0}
                transparent
                toneMapped={false}
                {...stencil}
            />
            {/*<meshBasicMaterial*/}
            {/*    map={texture}*/}
            {/*    opacity={0}*/}
            {/*    transparent*/}
            {/*    toneMapped={false}*/}
            {/*    // depthTest={false}*/}
            {/*/>*/}
        </mesh>
    )
};
export default memo(Video3D);
