import React, {useRef} from 'react';
import Text from '../common/Text';
// import Button from '../common/Button';
import styled from 'styled-components';
import {getCssSizeValue, getFontSize, getHtmlFromRedactorField} from '@repo/utils';
import { BREAKPOINTS } from '../../../styles/themeSettings';
import {Button, Icon} from "../../../index";
import {ICONS} from "@repo/utils/constants";
import {getButtonBlockData} from "../common/Button";
import {useLinesReveal} from "../../animations/LinesReveal";

const Wrapper = styled.div`
	//animation: fade-in 1s var(--ease);
	display: flex;
	position: relative;
	flex-direction: column;
	row-gap: 10svh;
	order: 0;
	padding-top: 1px; //added
	.desc {
		display: flex;
		flex-direction: column;
	}
	.text{
		font-size: ${getFontSize(22, BREAKPOINTS.MOBILE)};
	}
	
	@media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
		.text{
			font-size: ${getFontSize(24, BREAKPOINTS.TABLET)};
			line-height: ${getFontSize(40, BREAKPOINTS.TABLET)};
		}
	}
	
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		row-gap: 25svh;
		.desc {
			max-width: 55vw;
		}
		.text {
			font-size: ${getFontSize(48, BREAKPOINTS.DESKTOP)};
			line-height: ${getFontSize(56, BREAKPOINTS.DESKTOP)};
		}
	}
`;
const Container = styled.div`
	display: flex;
	flex-direction: column;
	
	.button{
		margin-top: 20px;
	}
`;

const Catchphrase = styled.div`
	display: flex;
	height: max-content;
	column-gap: 10px;
	flex-wrap: wrap;
	align-items: center;
	
	.rocket{
		--size: ${getCssSizeValue(22, BREAKPOINTS.MOBILE)};
		max-width: unset;
		width: var(--size);
		aspect-ratio: 1;
		svg {
			transition: transform 1s var(--ease);
			transform: translate(0, 0);
		}

		&:hover {
			svg {
				transform: translate(50%, -50%);
			}
		}
		@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
			--size: ${getCssSizeValue(22, BREAKPOINTS.TABLET)};
		}
		
		@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
			--size: ${getCssSizeValue(50, BREAKPOINTS.DESKTOP)};
		}
	}
`;


const Description = ({ data }) => {
	const { slogan= [], description } = data
	const redactor = getHtmlFromRedactorField(description)
	const buttonData = getButtonBlockData(data)
	const DescriptionRef = useRef()

	useLinesReveal({
		el: DescriptionRef,
		onInView: true,
		depth: 1
	})
	return (
		<Wrapper  className="px-main" >
			<Text ref={DescriptionRef} as="div" dangerouslySetInnerHTML={redactor} className="desc"/>
			<Container className="container desc">
				<Catchphrase className="catchphrase">
					<Text>{slogan[0] || ''}</Text>
					<Icon name={ICONS.ROCKET}/>
					<Text>{slogan[1] || ''}</Text>
				</Catchphrase>
				<Button as="link" variant="primary" href={buttonData.link}>
					{buttonData.text}
				</Button>
			</Container>
		</Wrapper>
	);
};

export default Description;
