import React, {createContext, forwardRef, useImperativeHandle, useMemo, useRef} from 'react';
import {formatHTML, getOnlyBreaksTexts} from "./utils";
import useCustomContext from "../../../hooks/useCustomContext";
import dynamic from "next/dynamic";
import { useIsDesktop } from '../../../../index';

const ClassicTextWithBreaks = dynamic(() => import('./Classic'), {
    ssr: false,
});
const StaticTextWithBreaks = dynamic(() => import('./Static'), {
    ssr: false,
});

export const VARIANT = {
    CLASSIC: 'classic',
    STATIC: 'static'
}

const TextWithBreaksContext = createContext({
    variant: VARIANT.CLASSIC,
    text: '',
    formatted: [],
    breaks: {},
    webGL: false,
    color: undefined,
    animation: ''
});

const TextWithBreaks = forwardRef(
    function TextWithBreaks({
        data,
        className='',
        variant:vt =VARIANT.CLASSIC,
        imageLoadPriority = false,
        color='',
        webGL: gl=false,
        left=false,
        animation=undefined,
        ...props
    },
    fref){
    const WrapperRef = useRef(null);
    const text = data?.text || ''
    const parentId = data?.parentId || ''
    const breaks = data?.breaks || []
    const variant = useMemo(() => vt || VARIANT.CLASSIC, [vt])
    const webGL = variant === VARIANT.CLASSIC ? gl : false
    const isDesktop = useIsDesktop()
    const formatted = useMemo(() => {
        const src = formatHTML(text || '')
        return isDesktop ? src : [src.flat()]
    }, [text, isDesktop])
    const onlyBreaksTexts = useMemo(() => getOnlyBreaksTexts(formatted), [formatted])

    useImperativeHandle(fref, () => WrapperRef.current, [variant]);


    const context = useMemo(() => ({
        variant,
        text,
        formatted,
        breaks:{ data: breaks, texts: onlyBreaksTexts },
        color,
        webGL,
        animation
    }),[
        variant,
        text,
        formatted,
        breaks,
        onlyBreaksTexts,
        color,
        webGL,
        animation
    ])

    return (
        <TextWithBreaksContext.Provider value={context}>
            {variant === VARIANT.CLASSIC ? (
                <ClassicTextWithBreaks
                    ref={WrapperRef}
                    className={className}
                    imageLoadPriority={imageLoadPriority}
                    parentId={parentId}
                    left={left}
                    {...props}
                />
            ) : (
                <StaticTextWithBreaks
                    ref={WrapperRef}
                    className={className}
                    imageLoadPriority={imageLoadPriority}
                    parentId={parentId}
                    left={left}
                    {...props}
                />
            )}
        </TextWithBreaksContext.Provider>
    );
});

export function useTextWithBreaksContext(selector) {
    return useCustomContext(TextWithBreaksContext, selector)
}

export default TextWithBreaks;
