import styled from 'styled-components';
import { CURSOR_TYPES } from "@repo/utils/constants";
import {BREAKPOINTS, theme} from "../../../styles/themeSettings";
import {getCssSizeValue, getFontSize, handleSetPositionOnMouseMove} from "@repo/utils";
import {useEffect, useMemo} from "react";
import {useExpandBackgroundAnimation, useHover} from "../../../index";

const ChipWrapper = styled.div`
    position: relative;
    overflow: hidden;
    border-radius: 100px;
	transition: all 0.2s ease-in-out;
    cursor: pointer;
	border: 1px solid ${({ $borderColor, theme }) => $borderColor ? $borderColor : theme.colors.white};
    color: ${({ $color }) => $color };
    margin-bottom: ${getCssSizeValue(5, BREAKPOINTS.MOBILE)};
	font-weight: ${({ theme }) => theme?.fontWeight?.semiBold || 600};
    background: ${({ $backgroundColor }) => $backgroundColor ? $backgroundColor : 'transparent'};
    
    font-size: ${getFontSize(13, BREAKPOINTS.MOBILE)};
    padding:
            ${getCssSizeValue(14, BREAKPOINTS.MOBILE)}
            ${getCssSizeValue(19, BREAKPOINTS.MOBILE)}
            ${getCssSizeValue(14, BREAKPOINTS.MOBILE)}
            ${getCssSizeValue(22, BREAKPOINTS.MOBILE)};

    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        
    }
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        column-gap: ${getCssSizeValue(14, BREAKPOINTS.DESKTOP)};
        font-size: ${getFontSize(16, BREAKPOINTS.DESKTOP)};
        padding:
                ${getCssSizeValue(14, BREAKPOINTS.DESKTOP)}
                ${getCssSizeValue(19, BREAKPOINTS.DESKTOP)}
                ${getCssSizeValue(14, BREAKPOINTS.DESKTOP)}
                ${getCssSizeValue(30, BREAKPOINTS.DESKTOP)};
    }
`;

const Content = styled.div`
    width: fit-content;
    position: relative;
    z-index: 1;
    align-items: center;
    display: flex;
    column-gap: ${getCssSizeValue(14, BREAKPOINTS.MOBILE)};
    
    > span {
        display: block;
        position: relative;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        border: solid 1px ${({ $color }) => $color };
    }

    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        column-gap: ${getCssSizeValue(14, BREAKPOINTS.DESKTOP)};
    }
`

const ButtonBackground = styled.div`
    position: absolute;
    background: ${({ $backgroundColor }) => $backgroundColor ? $backgroundColor : 'transparent'};
    border-radius: 50%;
    top: var(--y, 50%);
    left: var(--x, 50%);
    transform: translate(-50%, -50%);
    z-index: 0;
    width: var(--width);
    height: var(--height);
`

function Chip({ variant, children, onClick, color= theme.colors.white, borderColor = theme.colors.white, isSelected = false, ...props }) {
    const [ref, isHovered] = useHover({ onlyDesktop: true })

    const finalColor = useMemo(() => {
        return ((isSelected || isHovered) && borderColor === theme.colors.white) ? theme.colors.twilightBlue : color
    }, [isSelected, color, borderColor, isHovered])

    const hoverTl = useExpandBackgroundAnimation(ref, true)

    useEffect(() => {
        isHovered ? hoverTl.current.play() : hoverTl.current.reverse()
    }, [isHovered]);

    return (
        <ChipWrapper
            ref={ref}
            $color={finalColor}
            $backgroundColor={isSelected && borderColor}
            $borderColor={borderColor}
            data-cursor={CURSOR_TYPES.BUTTON}
            className={variant}
            onClick={onClick}
            onMouseMove={handleSetPositionOnMouseMove}
            {...props}
        >
            <ButtonBackground
                $backgroundColor={borderColor}
            />
            <Content>
                {children}
                <span className="dot" />
            </Content>
        </ChipWrapper>
    );
}

export default Chip;
