import React, {useImperativeHandle, useMemo, memo, useRef, useEffect} from 'react';
import {REVEAL_CLASSNAME} from "./Reveal";
import {gsap, delay, useGSAP, SplitText, duration} from "@repo/utils/gsap";
import useInView from "../hooks/useInView";
import useAnimationDebug from "./useAnimationDebug";
import {useIsomorphicLayoutEffect, usePageTransitionContext} from "../../index";

const AnimateLinesReveal = ({ target, timeline = null, vars = {} }) => {
    const tl = timeline || gsap.timeline();
    const type ='lines'
    const splitChild = new SplitText(target, { type, [`${type}Class`]: `${REVEAL_CLASSNAME}-child` })
    const splitParent = new SplitText(target, { type, [`${type}Class`]: `${REVEAL_CLASSNAME}-parent axis-y` })
    const delay = splitChild.lines.length/20

    tl.fromTo(splitChild[type], {
        yPercent: 100
    },{
        delay,
        yPercent: 0,
        duration: duration,
        stagger: delay,
        ...vars,
    },0);
    return tl;
};

export function useLinesReveal({
       el,
       onInView = false,
       id: passedId = '',
       animationVars,
       debug: forceDebug=false,
       once = true,
       timeline=undefined,
       disabled=false,
       start= 'top',
       pinnedContainer={ current: undefined },
       depth= 0
    }){
    const debug = useAnimationDebug(forceDebug)
    const { ref: InViewRef, intersected } = useInView({
        id: `lines-${REVEAL_CLASSNAME}-${passedId}`,
        trigger: {
            start: debug ? `${start} center` : `${start} bottom`,
            end: debug ? `${start} center` : `${start} bottom`
        },
        disabled: !onInView || disabled,
        markers: debug,
        // markers: true,
        once: debug ? !debug : once,
        pinnedContainer
    });
    const animationTL = useMemo(() => timeline || gsap.timeline(), [timeline]);
    const { transitionCompleted } = usePageTransitionContext()
    const memoAnimationVars = useRef(animationVars)

    useIsomorphicLayoutEffect(() => {
        memoAnimationVars.current = animationVars
    },[animationVars]);

    useImperativeHandle(InViewRef, () => el?.current, [el])

    useGSAP(() => {
        if(!disabled){
            animationTL.pause()
            let target
            if(depth === 0){
                target = InViewRef.current
            } else if (depth === 1) {
                target = InViewRef.current.children[0]
            }
            AnimateLinesReveal({
                target,
                vars:{
                    duration: duration * 2,
                    ...memoAnimationVars.current
                },
                timeline: animationTL
            })
            if(intersected && transitionCompleted){
                animationTL.play()
            }
        }
    }, {
        dependencies: [depth,intersected, disabled, animationTL, transitionCompleted],
        revertOnUpdate: true
    })
}
const LinesReveal = () => {
    return (
        <div>

        </div>
    );
};

export default LinesReveal;
