import React, { forwardRef, memo, useMemo } from 'react';
import styled from 'styled-components';
import { v4 } from 'uuid';
import useColor from "../../../hooks/useColor";
import {ICONS} from "@repo/utils/constants";

import dynamic from 'next/dynamic';
const List = dynamic(() => import('./SVGR').then((mod) => mod.List));
const Tiles = dynamic(() => import('./SVGR').then((mod) => mod.Tiles));
const Wave = dynamic(() => import('./SVGR').then((mod) => mod.Wave));
const SharpWave = dynamic(() => import('./SVGR').then((mod) => mod.SharpWave));
const CircularWave = dynamic(() => import('./SVGR').then((mod) => mod.CircularWave));
const MediumWave = dynamic(() => import('./SVGR').then((mod) => mod.MediumWave));
const HighWave = dynamic(() => import('./SVGR').then((mod) => mod.HighWave));
const WavyCircle = dynamic(() => import('./SVGR').then((mod) => mod.WavyCircle));
const ArrowUp = dynamic(() => import('./SVGR').then((mod) => mod.ArrowUp));
const ArrowDown = dynamic(() => import('./SVGR').then((mod) => mod.ArrowDown));
const ArrowRight = dynamic(() => import('./SVGR').then((mod) => mod.ArrowRight));
const PleoniansRecruit = dynamic(() => import('./SVGR').then((mod) => mod.PleoniansRecruit));
const PleoDesignLogo = dynamic(() => import('./SVGR').then((mod) => mod.PleoDesignLogo));
const PleoDigitalLogo = dynamic(() => import('./SVGR').then((mod) => mod.PleoDigitalLogo));
const PleoMethodLogo = dynamic(() => import('./SVGR').then((mod) => mod.PleoMethodLogo));
const PleoExpoLogo = dynamic(() => import('./SVGR').then((mod) => mod.PleoExpoLogo));
const PleoGroupLogo = dynamic(() => import('./SVGR').then((mod) => mod.PleoGroupLogo));
const HomeLine = dynamic(() => import('./SVGR').then((mod) => mod.HomeLine));
const Rocket = dynamic(() => import('./SVGR').then((mod) => mod.Rocket));
const HowWeCreateStoryArrowDownDesktop = dynamic(() => import('./SVGR').then((mod) => mod.HowWeCreateStoryArrowDownDesktop));
const HowWeCreateStoryArrowDownMobile = dynamic(() => import('./SVGR').then((mod) => mod.HowWeCreateStoryArrowDownMobile));
const WeArePleoniansTag = dynamic(() => import('./SVGR').then((mod) => mod.WeArePleoniansTag));
const ArrowButtonRight = dynamic(() => import('./SVGR').then((mod) => mod.ArrowButtonRight));
const ArrowButtonLeft = dynamic(() => import('./SVGR').then((mod) => mod.ArrowButtonLeft));
const ServiceCirclePartBorder = dynamic(() => import('./SVGR').then((mod) => mod.ServiceCirclePartBorder));
const AreasOfExpertisePieceOfCircle = dynamic(() => import('./SVGR').then((mod) => mod.AreasOfExpertisePieceOfCircle));
const LongThinArrowLeft = dynamic(() => import('./SVGR').then((mod) => mod.LongThinArrowLeft));
const LongThinArrowRight = dynamic(() => import('./SVGR').then((mod) => mod.LongThinArrowRight));
const ShortThinArrowLeft = dynamic(() => import('./SVGR').then((mod) => mod.ShortThinArrowLeft));
const ShortThinArrowRight = dynamic(() => import('./SVGR').then((mod) => mod.ShortThinArrowRight));
const Cross = dynamic(() => import('./SVGR').then((mod) => mod.Cross));
const PleoDesignLogoMenu = dynamic(() => import('./SVGR').then(((mod => mod.PleoDesignLogoMenu))));
const PleoGroupLogoMenu = dynamic(() => import('./SVGR').then(((mod => mod.PleoGroupLogoMenu))));
const FeaturedProjects = dynamic(() => import('./SVGR').then(((mod => mod.FeaturedProjects))));
const FeaturedProjectsPL = dynamic(() => import('./SVGR').then(((mod => mod.FeaturedProjectsPL))));
const SoundOn = dynamic(() => import('./SVGR').then(((mod => mod.SoundOn))));
const SoundOff = dynamic(() => import('./SVGR').then(((mod => mod.SoundOff))));
const Preloader = dynamic(() => import('./SVGR').then(((mod => mod.Preloader))));
const ThickWave = dynamic(() => import('./SVGR').then(((mod => mod.ThickWave))));
const SoonMask = dynamic(() => import('./SVGR').then(((mod => mod.SoonMask))));
const OpenInNewWindowModal = dynamic(() => import('./SVGR').then(((mod => mod.OpenInNewWindowModal))));

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	width: 100%;
	height: 100%;
	max-width: 100px;
	&.areas-of-expertise-delay {
		svg {
			path,circle {
				transition: all var(--color-transition-duration) var(--ease);
			}
		}
	}
	svg {
		display: block;
		width: 100%;
		height: 100%;
		path,
		circle {
			transition: all var(--color-transition-duration) var(--ease);
			pointer-events: none;
		}
	}
	
	&:not(&.no-color){
		svg {
			path,
			circle {
				 fill: ${({ $color }) => $color || 'var(--secondary-color)'};
			}
		}
	}
`;

const SVGR = ({ name, ...props }) => {
	switch (name) {
		case ICONS.LIST:
			return <List {...props} />;
		case ICONS.TILES:
			return <Tiles {...props} />;
		case ICONS.WAVE:
			return <Wave {...props} />;
		case ICONS.SHARPWAVE:
			return <SharpWave {...props} />;
		case ICONS.CIRCULARWAVE:
			return <CircularWave {...props} />;
		case ICONS.MEDIUMWAVE:
			return <MediumWave {...props} />;
		case ICONS.HIGHWAVE:
			return <HighWave {...props} />;
		case ICONS.ARROWUP:
			return <ArrowUp {...props} />;
		case ICONS.ARROWDOWN:
			return <ArrowDown {...props} />;
		case ICONS.ARROWRIGHT:
			return <ArrowRight {...props} />;
		case ICONS.PLEONIANSRECRUIT:
			return <PleoniansRecruit {...props} />;
		case ICONS.PLEO_LOGO_DESIGN:
			return <PleoDesignLogo {...props} />;
		case ICONS.PLEO_LOGO_DIGITAL:
			return <PleoDigitalLogo {...props} />;
		case ICONS.PLEO_LOGO_METHOD:
			return <PleoMethodLogo {...props} />;
		case ICONS.PLEO_LOGO_EXPO:
			return <PleoExpoLogo {...props} />;
		case ICONS.PLEO_LOGO_GROUP:
			return <PleoGroupLogo {...props} />;
		case ICONS.WAVY_CIRCLE:
			return <WavyCircle {...props} />;
		case ICONS.HOME_LINE:
			return <HomeLine {...props} />;
		case ICONS.ROCKET:
			return <Rocket {...props} />;
		case ICONS.HOW_WE_CREATE_STORY_ARROW_DOWN_DESKTOP:
			return <HowWeCreateStoryArrowDownDesktop {...props} />
		case ICONS.HOW_WE_CREATE_STORY_ARROW_DOWN_MOBILE:
			return <HowWeCreateStoryArrowDownMobile {...props} />
		case ICONS.WE_ARE_PLEONIANS_TAG:
			return <WeArePleoniansTag {...props} />
		case ICONS.BUTTON_ARROW_RIGHT:
			return <ArrowButtonRight {...props} />
		case ICONS.BUTTON_ARROW_LEFT:
			return <ArrowButtonLeft {...props} />
		case ICONS.SERVICE_CIRCLE_PART_BORDER:
			return <ServiceCirclePartBorder {...props} />
		case ICONS.AREAS_OF_EXPERTISE_PIECE:
			return <AreasOfExpertisePieceOfCircle {...props} />
		case ICONS.LONG_THIN_ARROW_LEFT:
			return <LongThinArrowLeft {...props} />
		case ICONS.LONG_THIN_ARROW_RIGHT:
			return <LongThinArrowRight {...props} />
		case ICONS.SHORT_THIN_ARROW_LEFT:
			return <ShortThinArrowLeft {...props} />
		case ICONS.SHORT_THIN_ARROW_RIGHT:
			return <ShortThinArrowRight {...props} />
		case ICONS.CROSS:
			return <Cross {...props} />
		case ICONS.PLEO_DESIGN_MENU_LOGO:
			return <PleoDesignLogoMenu {...props} />
		case ICONS.PLEO_GROUP_MENU_LOGO:
			return <PleoGroupLogoMenu {...props} />
		case ICONS.FEATURED_PROJECTS:
			return <FeaturedProjects {...props} />
		case ICONS.FEATURED_PROJECTS_PL:
			return <FeaturedProjectsPL {...props} />
		case ICONS.SOUND_ON:
			return <SoundOn {...props} />
		case ICONS.SOUND_OFF:
			return <SoundOff {...props} />
		case ICONS.PRELOADER:
			return <Preloader {...props} />
		case ICONS.THICK_WAVE:
			return <ThickWave {...props} />
		case ICONS.SOON_MASK:
			return <SoonMask {...props} />
		case ICONS.OPEN_IN_NEW_WINDOW_MODAL:
			return <OpenInNewWindowModal {...props} />
		default:
			return null;
	}
};

const Icon = forwardRef(function Icon({ id = '', name = '', color='secondary', className='', children, svgProps, ...props }, ref) {
	const uuid = useMemo(() => id || v4(), [id]); //use passed id or generate one
	const standardizedId = `icon-${name}-${uuid}`.replaceAll('/--|- -/g', '-'); //replace "--" or "- -" with "-"
	const cl = useColor(color)
	return (
		<Wrapper ref={ref} id={standardizedId} $color={cl} className={`${className} ${name} icon`} {...props}>
			<SVGR name={name} {...svgProps} />
			{children}
		</Wrapper>
	);
});

export default memo(Icon);
