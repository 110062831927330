import {CURSOR_TYPES} from "@repo/utils/constants";
import {LongArrowLeft, LongArrowRight} from "../common/SVGR";
import {AnimatePresence, motion} from "framer-motion";
import ProgressBar from "./ProgressBar";
import styled from "styled-components";
import {getCssSizeValue} from "@repo/utils";
import {BREAKPOINTS} from "../../../styles/themeSettings";
import {useRef} from "react";
import {useIsDesktop} from "../../../index";

const StyledArrowWrapper = styled.div`
    display: none;
    @media (min-width: ${({theme}) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        top: 37%;
        right: 23%;
        position: absolute;
        z-index: 4;
        display: flex;
        align-items: center;

        &.left {
            left: 23%;
            right: unset;
        }
    }
`

const StyledMobileNavigationWrapper = styled.div`
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: 22;
    top: 56%;
    left: 0;
    width: 100%;
    margin-top: 12px;
    min-height: 62px;


    @media (min-width: ${({theme}) => theme?.breakpoints?.tablet || 768}px) {
        top: 70%;
    }

    @media (min-width: ${({theme}) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        display: none;
    }
`

const StyledMobileNavigation = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 ${getCssSizeValue(50, BREAKPOINTS.MOBILE)};

    @media (min-width: ${({theme}) => theme?.breakpoints?.tablet || 768}px) {
        padding: 0 19%;
    }

    div.change-slide-button {
        transform: scale(0.65) !important;
    }

    @media (min-width: ${({theme}) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        display: none;
    }
`

const StyledUnderCardName = styled(motion.div)`
    font-size: ${getCssSizeValue(30, BREAKPOINTS.MOBILE)};
    letter-spacing: -1.2px;
`

const opacityAndYAxisAnimProperties = {
    initial: {opacity: 0, y: -100},
    animate: {opacity: 1, y: 0},
    exit: {opacity: 0, y: -100}
}


export default function Navigation({
                                       emblaApi,
                                       onClickNext,
                                       onClickPrev,
                                       selectedSnap,
                                       cards,
                                       isSubCarouselExpanded,
                                       snapCount,
                                       isCardsDataDuplicated,

                                   }) {
    const isDesktop = useIsDesktop()
    const mobileNavigationWrapper = useRef(null)

    return (
        <>
            <StyledArrowWrapper
                data-cursor={CURSOR_TYPES.ROUNDED_OUTLINE}
                onClick={() => emblaApi.scrollPrev()} className='change-slide-button left'
            >
                <LongArrowLeft/>
            </StyledArrowWrapper>
            <StyledArrowWrapper
                className='change-slide-button'
                data-cursor={CURSOR_TYPES.ROUNDED_OUTLINE}
                onClick={() => emblaApi.scrollNext()}
            >
                <LongArrowRight/>
            </StyledArrowWrapper>
            <StyledMobileNavigationWrapper ref={mobileNavigationWrapper}>
                <StyledMobileNavigation>
                    <div
                        data-cursor={CURSOR_TYPES.ROUNDED_OUTLINE}
                        onClick={onClickPrev}
                        className='change-slide-button left'
                    >
                        <LongArrowLeft/>
                    </div>
                    {!isDesktop ? (
                        <AnimatePresence mode='wait'>
                            {isSubCarouselExpanded ?
                                <StyledUnderCardName {...opacityAndYAxisAnimProperties}>{cards[selectedSnap]?.name || ''}</StyledUnderCardName>
                                : <motion.div {...opacityAndYAxisAnimProperties}>
                                    <ProgressBar
                                        isCardsDataDuplicated={isCardsDataDuplicated}
                                        isSubCarouselExpanded={isSubCarouselExpanded}
                                        selectedSnap={selectedSnap}
                                        snapCount={snapCount}
                                    />
                                </motion.div>
                            }
                        </AnimatePresence>
                    ) : (
                        <ProgressBar
                            isSubCarouselExpanded={isSubCarouselExpanded}
                            selectedSnap={selectedSnap}
                            snapCount={snapCount}
                            isCardsDataDuplicated={isCardsDataDuplicated}
                        />
                    )}
                    <div
                        className='change-slide-button'
                        data-cursor={CURSOR_TYPES.ROUNDED_OUTLINE}
                        onClick={onClickNext}
                    >
                        <LongArrowRight/>
                    </div>
                </StyledMobileNavigation>
            </StyledMobileNavigationWrapper>
        </>
    )
}