import { gsap } from 'gsap'
import {setIsAnimating, setIsCarouselVisible, setMenuTransition} from "@/state/menuSlice";

gsap.config({ nullTargetWarn: false })

export const SELECTORS = {
    MENU_ITEMS_REVEAL: '.menu-item-reveal-up',
    MENU_BOTTOM_ROW_ITEMS_REVEAL: '.menu-bottom-row-item-reveal-up',
    MENU_SETTINGS_REVEAL: '.menu-bottom-row-settings-reveal-up'
}

export function setTransitionCurvedOpenVisibility(visible = false) {
    return gsap.set('.transition-curved-open', { autoAlpha: visible ? 1 : 0 })
}

export function setTransitionCurvedSvgWrapperPosition(inView = false, callback = () => {}) {
    return gsap.set('.transition-curved-svg-wrapper', { x: inView ? 0 : '-100%', onComplete: callback })
}

export function setCurtainBlockPosition(inView = false, callback) {
    return gsap.set('.curtain-page-transition-block', {
        y: inView ? 0 : '-101%',
    })
}

export function moveCurtainBlock(inView = false, callback) {
    return gsap.to('.curtain-page-transition-block', {
        y: inView ? 0 : '-101%',
        ease: 'power2.out',
        duration: 0.8,
    })
}


export function transformCurvedOpenToTargetPath(path) {
    return gsap.to('.transition-curved-open', {
        ease: 'power1.out',
        duration: 0.2,
        attr: {
            d: path
        },
    })
}

export function transformCurvedOpenToFinalPath(path, callback = () => {}) {
    return gsap.to('.transition-curved-open', {
        duration: 0.39,
        attr: {
            d: path
        },
        onComplete: callback
    })
}

export function revealItems({ selector = '', stagger = 0, reverse = false, callback = () => {}}) {
    return gsap.to(selector, {
        y: reverse ? '100%' : 0,
        stagger,
        onComplete: callback
    })
}

export function moveVerticalCarousel(inView = false, callback = () => {}) {
    return gsap.to('.vertical-carousel-wrapper', {
        y: inView ? 0 : '-100%',
        onComplete: callback
    })
}

export function setVerticalCarousel(inView = false, callback = () => {}) {
    return gsap.set('.vertical-carousel-wrapper', {
        y: inView ? 0 : '-100%',
        onComplete: callback
    })
}


export function moveMobileExpandableBlockWrapper(inView = false) {
    return gsap.to('.menu-expandable-block-wrapper', {
        x: inView ? 0 : '-100%',
        duration: 0.2
    })
}

export function changeActiveMenuItemIndicatorVisibility(visible = false) {
    return gsap.to('.active-item-menu-indicator', { opacity: visible ? 1 : 0, duration: 0.2 })
}
export function setTransitionCurvedCloseVisibility(visible = false, callback = () => {}) {
    return gsap.set('.transition-curved-close', { autoAlpha: visible ? 1 : 0, onComplete: callback })
}

export function transformCurvedCloseToTargetPath(path, callback = () => {}) {
    return gsap.to('.transition-curved-close', {
        ease: 'power1.out',
        duration: 0.2,
        attr: {
            d: path
        },
        onComplete: callback
    })
}

export function transformCurvedCloseToInitialPath(path, callback = () => {}) {
    return gsap.to('.transition-curved-close', {
        duration: 0.35,
        attr: {
            d: path
        }
    })
}

export function moveTopBar(inView = false, delay = 0, callback = () => {}) {
    return gsap.to('.menu-top-row', {
        y: inView ? 0 : '-100%',
        duration: 0.15,
        ease: 'power2.in',
        delay
    })
}

export function toggleTopBarBackgroundVisibility(visible = false, callback) {
    return gsap.to('.top-row-background', {
        opacity: visible ? 1 : 0,
        duration: 0.2,
    })
}


export function closeMenuAnimation(svgPaths, dispatch) {
    return gsap.timeline({
        paused: true,
        onStart: () => {
            dispatch(setIsAnimating(true))
        },
        onComplete: () => {
            dispatch(setIsAnimating(false))
            dispatch(setMenuTransition(false))
        }
    })
        .add(setTransitionCurvedOpenVisibility(false))
        .add(setTransitionCurvedCloseVisibility(true))
        .add(setTransitionCurvedSvgWrapperPosition(true))
        .add(changeActiveMenuItemIndicatorVisibility(false), '<')
        .add(revealItems({ selector: SELECTORS.MENU_ITEMS_REVEAL, stagger: -0.05, reverse: true }), '<')
        .add(revealItems({ selector: SELECTORS.MENU_BOTTOM_ROW_ITEMS_REVEAL, stagger: 0.05, reverse: true }), '<')
        .add(revealItems({ selector: SELECTORS.MENU_SETTINGS_REVEAL, stagger: 0.05, reverse: true }), '<')
        .add(moveVerticalCarousel(false, () => dispatch(setIsCarouselVisible(false))), '<')
        .add(moveMobileExpandableBlockWrapper(false), '<')
        .add(moveCurtainBlock(false), '-=0.80%')
        .add(transformCurvedCloseToTargetPath(svgPaths.close.targetPath), '<')
        .add(transformCurvedCloseToInitialPath(svgPaths.close.initialPath), '-=0.5')
        .add(toggleTopBarBackgroundVisibility(true))
        .set('.menu-content-wrapper', { y: '-100%' })
        .add(setVerticalCarousel(false))
        .add(setTransitionCurvedSvgWrapperPosition(false))
}

export function initialCurtainState(svgPaths, callback) {
    return gsap.timeline({
        paused: true,
    })
        .add(setTransitionCurvedOpenVisibility(true))
        .add(setTransitionCurvedSvgWrapperPosition(true))
        .add(setCurtainBlockPosition(true))
        .add(setTransitionCurvedOpenVisibility(false))
        .add(setTransitionCurvedCloseVisibility(true,() => { callback() }))
}

export function initialCurtainAnimation(svgPaths, callback) {
    return gsap.timeline({
        paused: true,
        onComplete: () => {
            callback()
        }
    })
        .add(setTransitionCurvedOpenVisibility(true))
        .add(setTransitionCurvedSvgWrapperPosition(true))
        .add(setCurtainBlockPosition(true))
        .add(setTransitionCurvedOpenVisibility(false))
        .add(setTransitionCurvedCloseVisibility(true, () => { }))
        .add(moveCurtainBlock(false), '+=1')
        .add(transformCurvedCloseToTargetPath(svgPaths.close.targetPath), '<')
        .add(transformCurvedCloseToInitialPath(svgPaths.close.initialPath), '-=0.5')
        .add(setTransitionCurvedSvgWrapperPosition(false))
        .add(setTransitionCurvedSvgWrapperPosition(false))
}


export function loadedCurtainUpAnimation(svgPaths, callback) {
    return gsap.timeline({
        paused: true,
        onComplete: () => {
            callback()
        }
    })
        .set('.menu-content-wrapper', { y: '0' })
        .add(setTransitionCurvedOpenVisibility(false))
        .add(setTransitionCurvedCloseVisibility(true))
        .add(setTransitionCurvedSvgWrapperPosition(true))
        .add(setVerticalCarousel(false))
        .add(setCurtainBlockPosition(true))
        .add(moveCurtainBlock(false), '+=1')
        .add(transformCurvedCloseToTargetPath(svgPaths.close.targetPath), '<')
        .add(transformCurvedCloseToInitialPath(svgPaths.close.initialPath), '-=0.5')
        .set('.menu-content-wrapper', { y: '-100%' })
        .add(setTransitionCurvedSvgWrapperPosition(false))
}

export function differentRouteChangeOutsideMenuCurtainAnimation(svgPaths, callback, dispatch) {
    return gsap.timeline({
        paused: true,
        onStart: () => {
            dispatch(setIsAnimating(true))
        },
        onComplete: () => {
            dispatch(setIsAnimating(false))
            dispatch(setMenuTransition(false))
            callback()
        }
    })
        .add(setCurtainBlockPosition(false))
        .add(setTransitionCurvedSvgWrapperPosition(true))
        .add(setTransitionCurvedOpenVisibility(true))
        .add(setTransitionCurvedCloseVisibility(false))
        .add(toggleTopBarBackgroundVisibility(false))
        .add(moveTopBar(false))
        .add(moveCurtainBlock(true))
        .add(transformCurvedOpenToTargetPath(svgPaths.open.targetPath), '<')
        .add(transformCurvedOpenToFinalPath(svgPaths.open.finalPath, () => { }), '-=0.5')
}

export function isDifferentRouteChangeFromMenuCurtainAnimation(svgPaths, callback, dispatch) {
    return gsap.timeline({
        paused: true,
        onStart: () => {
            dispatch(setIsAnimating(true))
        },
        onComplete: () => {
            dispatch(setIsAnimating(false))
            dispatch(setMenuTransition(false))
            callback()
        }
    })
        .add(setTransitionCurvedOpenVisibility(false))
        .add(setTransitionCurvedCloseVisibility(true))
        .add(setTransitionCurvedSvgWrapperPosition(true))
        .add(moveVerticalCarousel(false), '<')
        .add(setVerticalCarousel(false))
        .add(changeActiveMenuItemIndicatorVisibility(false), '<')
        .add(revealItems({ selector: SELECTORS.MENU_ITEMS_REVEAL, stagger: -0.05, reverse: true }), '<')
        .add(revealItems({ selector: SELECTORS.MENU_BOTTOM_ROW_ITEMS_REVEAL, stagger: 0.0175, reverse: true }), '<')
        .add(revealItems({ selector: SELECTORS.MENU_SETTINGS_REVEAL, stagger: 0.05, reverse: true }), '<')
        .add(moveTopBar(false), '<')
        .add(moveMobileExpandableBlockWrapper(false), '<')
}