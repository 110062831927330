import {
    getCssSizeValue,
    getIdWithoutSpecialSymbols,
    getPaddingsClassFromData
} from "@repo/utils";
import styled from "styled-components";
import {BREAKPOINTS, COLORS, LOGO_COLORS, THEME_COLOR_SCHEMES} from "../../../styles/themeSettings";
import {Button, Icon, Reveal, useIsDesktop, useIsMobile} from "../../../index";
import {useRef} from "react";
import {ICONS} from "@repo/utils/constants";
import Slogan from "../BlobSlogan/Slogan";
import {getButtonBlockData} from "../common/Button";
import Card from "./Card";

const StyledWrapper = styled.div`
    position: relative;
    z-index: 10;
    color: var(--secondary-color);

    .reveal-parent {
        overflow: hidden;

        .text {
            width: calc(100vw - 2 * var(--paddingX));

            @media (min-width: ${({theme}) => theme.breakpoints.tablet || 768}px) {
                width: calc((100vw - 2 * var(--paddingX)) * 0.60);
            }

            @media (min-width: ${({theme}) => theme.breakpoints.desktop || 1024}px) and (orientation: landscape) {
                width: calc(100vw - 2 * var(--paddingX));
            }
        }
    }
`

const CardsContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    gap: ${getCssSizeValue(160, BREAKPOINTS.MOBILE)};

    @media (min-width: ${({theme}) => theme.breakpoints.desktop || 1024}px) and (orientation: landscape) {
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        gap: ${getCssSizeValue(47, BREAKPOINTS.DESKTOP)};
    }
`;

const ArrowAndButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${getCssSizeValue(80, BREAKPOINTS.DESKTOP)};

    .icon {
        position: relative;
        height: ${getCssSizeValue(40, BREAKPOINTS.MOBILE)};

        @media (min-width: ${({theme}) => theme.breakpoints.tablet || 768}px) {
            height: ${getCssSizeValue(80, BREAKPOINTS.TABLET)};
        }

        @media (min-width: ${({theme}) => theme.breakpoints.desktop || 1024}px) and (orientation: landscape) {
            height: ${getCssSizeValue(110, BREAKPOINTS.DESKTOP)};
        }
        
        .icon-mask {
            pointer-events: none;
            background: var(--primary-color);
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            transform-origin: center bottom;
        }
    }

    margin-top: ${getCssSizeValue(60, BREAKPOINTS.MOBILE)};

    @media (min-width: ${({theme}) => theme.breakpoints.tablet || 768}px) {
        margin-top: ${getCssSizeValue(60, BREAKPOINTS.TABLET)};
    }

    @media (min-width: ${({theme}) => theme.breakpoints.desktop || 1024}px) and (orientation: landscape) {
        margin-top: ${getCssSizeValue(30, BREAKPOINTS.DESKTOP)};
        gap: ${getCssSizeValue(60, BREAKPOINTS.DESKTOP)};
    }
`

const TabletLayoutContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: ${getCssSizeValue(60, BREAKPOINTS.TABLET)};

    .first-column {
        display: flex;
        flex-direction: column;
        gap: 100px;
    }

    .second-column {
        transform: translateY(25%);
    }
`

export const TARGET_ID = 'target_slogan'

export default function HowWeCreateStory({data, isSinglePage}) {
    const id = getIdWithoutSpecialSymbols(data?.id || '')
    const slogan = data?.slogan || ''
    const paddings = getPaddingsClassFromData(data)
    const items = data?.items || []
    const itemsLength = items.length
    const buttonData = getButtonBlockData(data)
    const isDesktop = useIsDesktop()
    const isMobile = useIsMobile()
    const wrapperRef = useRef(null)

    return (
        <StyledWrapper
            id={id}
            ref={wrapperRef}
            className={`${paddings} px-main grid-background-end`}
            data-logo-color={LOGO_COLORS.LIGHT}
            data-color-scheme={THEME_COLOR_SCHEMES.PLEO_EXTRA}
        >
            <Slogan
                id={TARGET_ID}
                data={{align: 'center', containerAlign: 'flex-start', text: slogan}}
                scrollTriggerOptions={{
                    start: 'top center-=8%',
                    end: '+=150%',
                    breakpoint: 0.4
                }}
            />
            {isDesktop || isMobile ? (
                <>
                    <CardsContainer>
                        {items.map((item, index) => (
                            <Card
                                key={item?.id || index}
                                item={item}
                                itemsLength={itemsLength}
                                index={index}
                                buttonText={buttonData.text}
                                buttonLink={buttonData.link}
                                isSinglePage={isSinglePage}
                            />
                        ))}
                    </CardsContainer>
                    <ArrowAndButtonWrapper>
                        <div className='icon'>
                            <div className='icon-mask' />
                            <Icon name={isMobile ? ICONS.HOW_WE_CREATE_STORY_ARROW_DOWN_MOBILE : ICONS.HOW_WE_CREATE_STORY_ARROW_DOWN_DESKTOP} color={COLORS.white}/>
                        </div>
                        <Reveal onInView>
                            <Button as='link' href={`/${buttonData.link}`}>{buttonData.text}</Button>
                        </Reveal>
                    </ArrowAndButtonWrapper>
                </>
            ) : (
                items.length === 3 && (
                    <>
                        <TabletLayoutContainer>
                            <div className='first-column'>
                                {[items[0], items[1]].map((item, index) => (
                                    <Card
                                        key={item?.id || index}
                                        item={item}
                                        itemsLength={itemsLength}
                                        index={index}
                                        buttonText={buttonData.text}
                                        buttonLink={buttonData.link}
                                        isSinglePage={isSinglePage}
                                    />
                                ))}
                            </div>
                            <div className='second-column'>
                                {[items[2]].map((item, index) => (
                                    <Card
                                        key={item?.id || index}
                                        item={item}
                                        itemsLength={itemsLength}
                                        index={index}
                                        buttonText={buttonData.text}
                                        buttonLink={buttonData.link}
                                        isSinglePage={isSinglePage}
                                    />
                                ))}
                            </div>
                        </TabletLayoutContainer>
                        <ArrowAndButtonWrapper>
                            <div className='icon'>
                                <Icon name={ICONS.HOW_WE_CREATE_STORY_ARROW_DOWN_MOBILE}/>
                            </div>
                            <Button as='link' href={`/${buttonData.link}`}>{buttonData.text}</Button>
                        </ArrowAndButtonWrapper>
                    </>
                )
            )}
        </StyledWrapper>
    )
}