import useEmblaCarousel from 'embla-carousel-react'
import styled from "styled-components";
import useSelectedSnapDisplay from "../../hooks/useSelectedSnapDisplay";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { gsap, useGSAP } from '@repo/utils/gsap'
import { CURSOR_TYPES } from "@repo/utils/constants";
import { getCssSizeValue, getHtmlFromRedactorField } from "@repo/utils";
import Card from "./Card";
import SubCarousel from "./SubCarousel";
import ProgressBar from "./ProgressBar";
import { useIsDesktop, useIsMobile } from "../../../index";
import {BREAKPOINTS} from "../../../styles/themeSettings";
import Navigation from "./Navigation";

const StyledWrapper = styled.div`
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    position: relative;
    height: calc(100svh + ${getCssSizeValue(100, BREAKPOINTS.MOBILE)});
    padding-top: ${getCssSizeValue(150, BREAKPOINTS.MOBILE)};
    color: ${({theme}) => theme.colors.twilightBlue};
    
    @media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        height: calc(80svh + ${getCssSizeValue(150, BREAKPOINTS.TABLET)});
        padding-top: ${getCssSizeValue(150, BREAKPOINTS.TABLET)};
    }
   @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        height: calc(100svh + ${getCssSizeValue(100)});
        padding-top: ${getCssSizeValue(200)};
    }
`

const StyledContent = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    position: relative;
`

const StyledEmblaViewPort = styled.div`
    height: 100%;
    width: 100%;
`

const EmblaContainer = styled.div`
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    touch-action: pan-y;
`

const StyledTextLeft = styled.div`
    user-select: none;
    color: ${({theme}) => theme.colors.twilightBlue};
    font-size: ${getCssSizeValue(16, BREAKPOINTS.MOBILE)};
    width: ${getCssSizeValue(300, BREAKPOINTS.MOBILE)};
    font-style: italic;
    font-weight: 400;

    display: block;
    position: absolute;
    top: -15%;
    
    @media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        width: 30%;
        font-size: ${getCssSizeValue(18, BREAKPOINTS.TABLET)};
        top: -10%;
    }
    
   @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        top: 0;
        font-size: ${getCssSizeValue(24, BREAKPOINTS.DESKTOP)};
        width: 450px;
    }   
`

const StyledTextRight = styled.div`
    user-select: none;
    position: absolute;
    white-space: nowrap;
    top: -5%;
    z-index: 10;
    font-size: ${getCssSizeValue(70, BREAKPOINTS.MOBILE)};
    font-weight: 600;
    letter-spacing: -2.8px;
    right: 10px;

    @media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        font-size: ${getCssSizeValue(75, BREAKPOINTS.TABLET)};
        right: 15vw;
        top: 0;
        letter-spacing: -3px;
    }
    
   @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        right: 2vw;
        top: -15%;
        font-size: ${getCssSizeValue(309, BREAKPOINTS.DESKTOP)};
        letter-spacing: -12.36px;
    }
`

const DUPLICATE_CARDS_BREAKPOINT = 3

export default function AboutTeam({ data, onToggle, isGroup }) {
    const id = data?.id?.replace(/[^a-zA-Z ]/g, "") || '';
    const textLeft = data?.leftText || ''
    const boldText = data?.boldText || ''
    const isMobile = useIsMobile()
    const isDesktop = useIsDesktop()
    // Reference for sub carousel emblaApi, to control which one carousel scrolls with arrows
    const [subCarouselEmblaApi, setSubCarouselEmblaApi] = useState(null)
    const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true, active: !isGroup })
    const { selectedSnap, snapCount } = useSelectedSnapDisplay(emblaApi)

    const wrapperRef = useRef(null)
    const tl = useRef(null)
    const subCarouselRef = useRef(null)

    const cardsBuffor = data?.cards || []
    const isCardsDataDuplicated = useMemo(() => cardsBuffor.length <= DUPLICATE_CARDS_BREAKPOINT, [cardsBuffor])

    const cards = useMemo(() => {
        return isCardsDataDuplicated ? [...cardsBuffor, ...cardsBuffor] : cardsBuffor
    }, [cardsBuffor])

    const subCarouselData = useMemo(() => cards[selectedSnap], [selectedSnap])
    const [isSubCarouselExpanded, setIsSubCarouselExpanded] = useState(false)

    useGSAP(() => {
        tl.current = gsap.timeline({
            paused: true, scrollTrigger: {
                trigger: wrapperRef.current,
                start: 'top center',
                end: 'bottom bottom',
                // markers: true
            }
        })

        gsap.set('.change-slide-button', { y: 1000 })
        gsap.set('.text-left', { opacity: 0, y: 100 })
        gsap.set('.text-bold', { opacity: 0, y: 100 })

        tl.current.to('.change-slide-button', {
            y: 0
        }).to('.text-left', {
            opacity: 1,
            y: 0
        }).to('.text-bold', {
            opacity: 1,
            y: 0
        }, '-=0.3')
    }, { scope: wrapperRef, dependencies: [isMobile] })

    const onClickPrev = useCallback(() => {
        if (!emblaApi || !subCarouselEmblaApi) return;
        isSubCarouselExpanded ? subCarouselEmblaApi.scrollPrev() : emblaApi.scrollPrev()
    }, [emblaApi, subCarouselEmblaApi, isSubCarouselExpanded])

    const onClickNext = useCallback(() => {
        if (!emblaApi || !subCarouselEmblaApi) return;
        isSubCarouselExpanded ? subCarouselEmblaApi.scrollNext() : emblaApi.scrollNext()
    }, [emblaApi, subCarouselEmblaApi, isSubCarouselExpanded])

    useEffect(() => {
        if (!isDesktop) {
            gsap.to('.text-left', { x: isSubCarouselExpanded ? '-50svh' : 0 })
        }
    }, [isSubCarouselExpanded, isDesktop])

    return (
        <StyledWrapper id={id} ref={wrapperRef} data-cursor={CURSOR_TYPES.SLIDER}>
            <StyledContent>
                <StyledEmblaViewPort ref={emblaRef}>
                    <EmblaContainer>
                        {cards.map((item, index) => {
                            return (
                                <Card
                                    key={item.id + index}
                                    item={item}
                                    index={index}
                                    selectedSnap={selectedSnap}
                                    cardsLength={cards.length}
                                    isExpanded={isSubCarouselExpanded}
                                    // setIsSubCarouselExpanded={setIsSubCarouselExpanded}
                                    setIsSubCarouselExpanded={(value) => {
                                        if(typeof onToggle === "function") {
                                            onToggle(value)
                                        }
                                        setIsSubCarouselExpanded(value)
                                    }}
                                    onPrevButtonClick={onClickPrev}
                                    onNextButtonClick={onClickNext}
                                />
                            )
                        })}
                    </EmblaContainer>
                    {isDesktop && <ProgressBar
                        isSubCarouselExpanded={isSubCarouselExpanded}
                        selectedSnap={selectedSnap}
                        snapCount={snapCount}
                        isCardsDataDuplicated={isCardsDataDuplicated}
                    />}
                </StyledEmblaViewPort>

                <SubCarousel
                    ref={subCarouselRef}
                    data={subCarouselData}
                    isExpanded={isSubCarouselExpanded}
                    setIsExpanded={(value) => {
                        if(typeof onToggle === "function") onToggle(value)
                        setIsSubCarouselExpanded(value)
                    }}
                    setSubCarouselReference={(value) => setSubCarouselEmblaApi(value)}
                />

                <div className='px-main'>
                    <StyledTextLeft className='text-left' dangerouslySetInnerHTML={getHtmlFromRedactorField(textLeft)} />
                </div>

                <StyledTextRight className='text-bold'>{boldText}</StyledTextRight>

                <Navigation
                    emblaApi={emblaApi}
                    onClickPrev={onClickPrev}
                    onClickNext={onClickNext}
                    isSubCarouselExpanded={isSubCarouselExpanded}
                    cards={cards}
                    selectedSnap={selectedSnap}
                    snapCount={snapCount}
                    isCardsDataDuplicated={isCardsDataDuplicated}
                />
            </StyledContent>
        </StyledWrapper>
    );
}
