import { Provider as Redux } from 'react-redux';
import store from '@/state/store';
import React, { useEffect, useState } from 'react';
import Layout from '@/layout/Layout';
import {GlobalsProvider, Seo, usePageTransitionContext} from "@repo/ui";
import Head from "next/head";
import {useRouter} from "next/router";
import dynamic from "next/dynamic";
import { GoogleTagManager } from '@next/third-parties/google'

const Theme = dynamic(() => import('@repo/ui').then((mod) => mod.Theme), {
	loading: () => {},
	ssr: false
});
const Debug = dynamic(() => import('@repo/ui').then((mod) => mod.Debug), {
	loading: () => {},
	ssr: false
});
const Scroll = dynamic(() => import('@repo/ui').then((mod) => mod.Scroll), {
	loading: () => {},
	ssr: false
});
const PageTransition = dynamic(() => import('@repo/ui').then((mod) => mod.PageTransition), {
	loading: () => {},
	ssr: false
});

const LoadedProvider = dynamic(() => import('@repo/ui').then((mod) => mod.LoadedProvider), {
	loading: () => {},
	ssr: false
});

export default function App({ Component, pageProps }) {
	const { data, globals, cookies, menu, footer } = pageProps;
	const darkTheme = data?.themeDark || null;
	const lightTheme = data?.themeLight || null;
	const cookiesData = cookies?.[0] || [];
	const menuData = menu || [];
	const transitionCompleted = usePageTransitionContext((state) => state.transitionCompleted)
	const { asPath, locale } = useRouter()
	const footerData = {
		socials: globals?.socials || [],
		contact: {
			address: globals?.address || '',
			email: globals?.email || '',
			phone: globals?.phone || '',
		},
		buttons: {
			top: globals?.toTopButton || '',
			cookies: globals?.cookiesButton || '',
			policy: globals?.policyButton
		},
		others: {
			logos: globals?.rest || []
		},
		...footer
	}



	return (
		<GlobalsProvider data={globals}>
			<Redux store={store}>
				<Seo data={data} />
				<Debug/>
				<Head>
					<meta name="viewport" content="width=device-width, initial-scale=1"/>
					<link rel="icon" href="/favicon.svg"/>
				</Head>
				<GoogleTagManager gtmId={process.env.GTM_ID}/>
				<PageTransition isDesign={true}>
					<LoadedProvider key={asPath}>
						<Theme lightTheme={lightTheme} darkTheme={darkTheme} forceLight>
							<Scroll ready={transitionCompleted} updateDependencies={[transitionCompleted, locale]}>
								<Layout
									globals={globals}
									cookies={cookiesData}
									menu={menuData}
									data={data}
									footer={footerData}
								>
									<Component {...pageProps} />
								</Layout>
							</Scroll>
						</Theme>
					</LoadedProvider>
				</PageTransition>
			</Redux>
		</GlobalsProvider>
	);
}
