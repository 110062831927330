import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState} from 'react';
import Banner from "./Banner";
import styled from "styled-components";
import CommonButton from "../common/Button";
import Text from "../common/Text";
import Icon from "../common/Icon/Icon";
import {ICONS} from "@repo/utils/constants";
import {BREAKPOINTS} from "../../../styles/themeSettings";
import {getCssSizeValue, getFontSize, getHtmlFromRedactorField, removeTags, setCssVars} from "@repo/utils";
import Image from "../common/Image";
import {getCardData} from "../common/CardsSlider/Card";
import Tag from "../common/Tag";
import Link from "../common/Link";
import {useRouter} from "next/router";
import {isDesktop} from "react-device-detect";
import {useSearchParams} from "next/navigation";
import {ScrollTrigger, gsap, useGSAP} from '@repo/utils/gsap'
import {useDispatch} from "react-redux";
import {setIsMenuTransparent} from '@/state/menuSlice';
import {useSetTransparentMenu} from "../../../index";


const Wrapper = styled(Banner)`
    --area-color: var(--primary-color);
    --border-color: var(--tertiary-color);
    //background-color: var(--area-color);
    min-height: 85svh;
    padding-top: ${getCssSizeValue(100, BREAKPOINTS.MOBILE)};
    &.news-services {
        min-height: 101svh;   
    }
    //background: linear-gradient(0deg, rgba(5, 29, 39, 0.80) 52.69%, rgba(5, 29, 39, 0.24) 98.26%);
    .icon {
        width: 15px;
        max-width: unset;
    }

    .small-text {
        font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
        color: var(--secondary-color) !important;
        letter-spacing: normal;
        line-height: normal;
    }

    @media (min-width: ${({theme}) => theme?.breakpoints?.tablet || 768}px) {
        padding-top: ${getCssSizeValue(170, BREAKPOINTS.TABLET)};
    }
    @media (min-width: ${({theme}) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        padding-top: ${getCssSizeValue(180, BREAKPOINTS.DESKTOP)};
        padding-bottom: ${getCssSizeValue(180, BREAKPOINTS.DESKTOP)};
        min-height: 101svh;
        .small-text {
            font-size: ${getFontSize(18, BREAKPOINTS.DESKTOP)};
        }
    }

`

const Button = styled(CommonButton)`
    margin-bottom: ${getCssSizeValue(80, BREAKPOINTS.MOBILE)};
    @media (min-width: ${({theme}) => theme?.breakpoints?.tablet || 768}px) {
        margin-bottom: 0;
    }
    &.new-services {
        opacity: 0;
        transform: translateY(200px);
        z-index: 3;
        position: relative;
        * {
            color: white;
            fill: white;
        }
    }
`

const HeaderWrapper = styled.div`
    display: flex;
    margin-top: auto;
    margin-bottom: ${getCssSizeValue(45, BREAKPOINTS.MOBILE)};
    overflow: visible;
    justify-content: start;
    flex-direction: column-reverse;
    row-gap: ${getCssSizeValue(30, BREAKPOINTS.MOBILE)};
    position: relative;
    z-index: 2;
    opacity: 0;
    transform: translateY(200px);
    &.new-services {
        .header {
            color: white;
            transition: opacity 0.3s ease-in-out 0.5s;
            opacity: 1;
        }
        em {
            font-weight: ${({theme}) => theme?.fontWeight?.regular || 400}!important;
            font-style: normal;
        }
    }
    em {
        font-weight: ${({theme}) => theme?.fontWeight?.light || 200};
    }

    .image {
        width: ${getCssSizeValue(172, BREAKPOINTS.MOBILE)};
        height: ${getCssSizeValue(122, BREAKPOINTS.MOBILE)};
    }

    @media (min-width: ${({theme}) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        --image-height: ${getCssSizeValue(308, BREAKPOINTS.DESKTOP)};
        margin-bottom: unset;
        justify-content: space-between;
        height: 100%;
        flex-direction: row;
        align-items: end;

        .image {
            width: ${getCssSizeValue(437, BREAKPOINTS.DESKTOP)};
            height: var(--image-height);
        }

        .header {
            transform: translateY(calc(var(--image-height) * -0.5 + 50%));
        }
    }
`

const TagsWrapper = styled.div`
    display: flex;
    gap: ${getCssSizeValue(15, BREAKPOINTS.DESKTOP)};
    flex-wrap: wrap;
    font-size: ${getFontSize(18, BREAKPOINTS.DESKTOP)};
    opacity: 0;
    transform: translateY(200px);
    .line-break {
        width: 100%;
    }
    &.new-services {
        position: relative;
        z-index: 3;
        * {
            color: white !important;
        }
    }
    .header {
        margin-bottom: ${getCssSizeValue(15, BREAKPOINTS.MOBILE)};
    }

    @media (min-width: ${({theme}) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
            //max-width: ${getCssSizeValue(485, BREAKPOINTS.DESKTOP)};
        max-width: ${getCssSizeValue(685, BREAKPOINTS.DESKTOP)};

        .tag-header {
                //margin-bottom: ${getCssSizeValue(26, BREAKPOINTS.DESKTOP)};
        }
    }
`

const VideoBlend = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(0deg, rgba(5, 29, 39, 0.80) 52.69%, rgba(5, 29, 39, 0.24) 98.26%);
    z-index: 1;
    opacity: 0;
    transform: translateY(50px);
`

const VideoWrapper = styled.div`
    //position: relative;
    //width: 100vw;
    //height: 100vh;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    video {
        height: 100%;
        width: 100%;
        object-fit: cover;
        opacity: 1;
        transition: opacity 0s linear 0.4s;
        &.hidden {
            opacity: 0;
        }
    }
`;

const TagsBanner = forwardRef(function TagsBanner({data, videoData, tags , isNewServices, hideBackToAllServices, backToPrevPage = false}, fref) {
    const BannerRef = useRef()
    const buttonRef = useRef();
    const videoRef = useRef();
    const headerRef = useRef();
    const blendRef = useRef();
    const tagsWrapperRef = useRef();
    const router = useRouter();
    const header = getHtmlFromRedactorField(data?.header || 'TagsBanner')
    const backButton = data?.backButton || 'Back'
    const backToAllButton = data?.backToAllButton || 'Back To All'
    const backLink = data?.backLink || ''
    const tagHeader = tags?.header || ''
    const tagData = tags?.data || []
    const tagPrefix = tags?.prefix || ''
    const {icon} = getCardData(data.card)
    const searchParams = useSearchParams()
    const origin = searchParams.get('origin')
    const hasIcon = icon.url.length > 0
    const videoFile = videoData?.videoFile?.url || '';
    const [area, border] = useMemo(() => [
        data?.areaColor || 'var(--primary-color)',
        data?.borderColor || 'var(--tertiary-color)'
    ], [data])

    useSetTransparentMenu(BannerRef, true)

    //should have origin as dependency, but it caused changing before page transition had time to cover it
    const buttonText = useMemo(() => origin ? backButton : backToAllButton, [])

    const handleBack = useCallback(() => {
        let destination
        if (origin) {
            destination = origin.replace('-id-', '#')
        } else {
            destination = backLink
        }
        return router.push(destination, destination, {
            locale: router.locale
        })
    }, [router, backLink, origin])

    useEffect(() => {
        setCssVars(BannerRef.current, {
            'area-color': area,
            'border-color': border
        })
    }, [area, border]);

    useImperativeHandle(fref, () => BannerRef.current, [])
    const [savedTimeState, setSavedTime] = useState(null);
    useEffect(() => {
        if(isNewServices) {
            const savedTime = localStorage.getItem("videoTime");
            if (videoRef.current && savedTime) {
                videoRef.current.currentTime = parseFloat(savedTime);
                setSavedTime(savedTime);
            }
        }
    }, []);

    const [isBannerContentVisible, setIsBannerContentVisible] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setIsBannerContentVisible(isNewServices)
        }, 2000)
    }, [isNewServices])

    useGSAP(() =>{
        gsap.to(blendRef.current, {
            y: 0,
            opacity: 1,
            ease: "back.out",
            duration: 0.3,
            delay: 1,
        })
        gsap.to(buttonRef.current, {
            y: 0,
            opacity: 1,
            ease: "back.out",
            duration: 0.5,
            delay: 1.2
        })
        gsap.to(headerRef.current, {
            y: 0,
            opacity: 1,
            ease: "back.out",
            duration: 0.5,
            delay: 1.5
        })
        gsap.to(tagsWrapperRef.current, {
            y: 0,
            opacity: 1,
            ease: "back.out",
            duration: 0.5,
            delay: 1.7
        })
    },[])
    return (
        <Wrapper className={isNewServices ? 'news-services' : ''} ref={BannerRef} data-color-scheme={null}>
            {isNewServices && <VideoBlend ref={blendRef}/>}
            {/*{isNewServices && localStorage.getItem("videoTime") && <VideoWrapper>*/}
            {isNewServices && <VideoWrapper>
                <video
                    className={savedTimeState ? '' : 'hidden'}
                ref={videoRef}
                key={videoFile}
                preload="auto"
                muted
                loop
                autoPlay={true}
                // autoPlay={savedTimeState}
                playsInline
                >
                    <source src={videoFile} type="video/mp4"/>
                </video>
            </VideoWrapper>}
            <Button
                ref={buttonRef}
                onClick={handleBack}
                variant="unstyled"
                className={`${isNewServices ? 'new-services' : ""} small-text`}
            >
                <Icon name={ICONS.BUTTON_ARROW_LEFT} color={isNewServices ? 'white' : 'secondary'} />
                {buttonText}
            </Button>
            {/*{isBannerContentVisible && <HeaderWrapper ref={headerRef} className={isNewServices ? 'new-services' : ''}>*/}
            <HeaderWrapper ref={headerRef} className={isNewServices ? 'new-services' : ''}>
                <Text as="div" className="header" dangerouslySetInnerHTML={header}/>
                {hasIcon && !isNewServices && (
                    <Image
                        src={icon.url}
                        focalPoint={isDesktop ? {x: 0.5, y: 0.5} : {x: 0, y: 0.5}}
                        alt={icon.alt}
                        fit="contain"
                        priority
                    />
                )}
            </HeaderWrapper>
            <TagsWrapper ref={tagsWrapperRef} className={isNewServices ? 'new-services' : ''}>
                <Text className="small-text tag-header">
                    {tagHeader}
                </Text>
                <div className="line-break"/>
                {tagData.map((tag, index) => {
                    const id = tag?.id || index
                    const name = removeTags(tag?.name || 'Tag', ['em', 'br', 'p'])
                    const slug = `/${tagPrefix}${tag?.slug || ''}`.trim()
                    return (
                        <React.Fragment key={name}>
                            <Tag as={Link} href={slug} isNewServices={isNewServices} accentColor={border}>{name}</Tag>
                        </React.Fragment>
                    )
                })}
            </TagsWrapper>
        </Wrapper>
    );
})

export default TagsBanner;
