import styled from "styled-components";
import {motion, AnimatePresence, useAnimate} from "framer-motion";
import {ShortArrowLeft, ShortArrowRight} from "../common/SVGR";
import {forwardRef, useEffect, useImperativeHandle, useMemo, useRef} from "react";
import {useRouter} from "next/router";
import {CURSOR_TYPES, LOCALES} from "@repo/utils/constants";
import {useMouseDown} from "../../hooks/useMouseDown";

const StyledWrapper = styled.div`
    user-select: none;
    position: absolute !important;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    transform-origin: center;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 9px;
    pointer-events: none;

    &.snap {
        top: 0;
        left: 0;
        transform: translate(calc(var(--x)), calc(var(--y) * -1));
        will-change: transform;
    }
`

const StyledCircle = styled(motion.div)`
    position: relative;
    z-index: 1;
    background: ${({color}) => color};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 104px;
    width: auto;
    aspect-ratio: 1;
    border-radius: 50%;
    transform-origin: center center;
    &.border {
        border: 2px solid ${({color}) => color};
        background-color: transparent;
    }
`

const StyledSvgWrapper = styled(motion.div)`
    height: 12px;
    width: 12px;
`

const StyledTextWrapper = styled.div`
    overflow: hidden;
`

const StyledText = styled(motion.p)`
    color: ${({textColor}) => textColor};
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
`

const circleVariants = {
    hidden: {scale: 0},
    show: {scale: 1},
    small: {scale: 0.6}
};

const svgLeftVariants = {
    hidden: {x: 75, opacity: 0},
    show: {x: 0, opacity: 1},
};

const svgRightVariants = {
    hidden: {x: -75, opacity: 0},
    show: {x: 0, opacity: 1},
};

const cursorTextMap = {
    [CURSOR_TYPES.SLIDER]: {textPL: 'przesuń', textEN: 'move', isSvgHidden: false},
    [CURSOR_TYPES.SLIDER_OPEN]: {textPL: 'otwórz', textEN: 'open', isSvgHidden: false},
    [CURSOR_TYPES.MARQUEE]: {textPL: 'rozwiń', textEN: 'expand', isSvgHidden: true},
    [CURSOR_TYPES.MARQUEE_EXPANDED]: {textPL: 'zwiń', textEN: 'collapse', isSvgHidden: true},
    [CURSOR_TYPES.CHECK]: {textPL: 'zobacz', textEN: 'check', isSvgHidden: true},
    [CURSOR_TYPES.SERVICES_PREVIOUS]: {textPL: 'poprzedni', textEN: 'previous', isSvgHidden: true},
    [CURSOR_TYPES.SERVICES_NEXT]: {textPL: 'następny', textEN: 'next', isSvgHidden: true},
    [CURSOR_TYPES.CLOSE]: {textPL: 'zamknij', textEN: 'close', isSvgHidden: true},
    [CURSOR_TYPES.SOON]: {textPL: 'wkrótce', textEN: 'soon', isSvgHidden: true},
    [CURSOR_TYPES.GO]: {textPL: 'przejdź', textEN: 'go', isSvgHidden: true},
};

const TouchReactiveCursor = forwardRef(function TouchReactiveCursor({cursorText, targetType, isSnapCursor = false, color = 'white'}, fref) {
    const [scope, animate] = useAnimate()
    const {locale} = useRouter()
    const isMouseDown = useMouseDown()
    const CursorRef = useRef()
    const textColor = targetType === CURSOR_TYPES.SERVICES_NEXT ? 'white' : '#051D27';
    useImperativeHandle(fref, () => CursorRef.current, [])

    const {textPL, textEN, isSvgHidden} = cursorTextMap[cursorText] || {isSvgHidden: false, text: ''};

    const displayText = isMouseDown ? '' : locale === LOCALES.PL ? textPL : textEN;

    const prevTextMemo = useRef(null);
    const textMemo = useMemo(() => displayText, [locale, cursorText, isMouseDown]);
    const isSvgHiddenMemo = useMemo(() => (isMouseDown || targetType === CURSOR_TYPES.SERVICES_PREVIOUS || targetType === CURSOR_TYPES.SERVICES_NEXT) ? true : isSvgHidden, [cursorText, isMouseDown, isSvgHidden]);

    useEffect(() => {
        if (prevTextMemo.current !== null) {
            animate(scope.current, {height: [104, 84, 104], duration: 0.1});
        }
        prevTextMemo.current = textMemo;
    }, [textMemo]);

    return (
        <StyledWrapper
            className={`${isSnapCursor ? 'snap' : ''}`}
            ref={CursorRef}
        >
            <StyledSvgWrapper
                variants={svgLeftVariants}
                initial='hidden'
                animate={isSvgHiddenMemo ? 'hidden' : 'show'}
                transition={{delay: 0.1, duration: 0.4}}
            >
                <ShortArrowLeft/>
            </StyledSvgWrapper>
            <StyledCircle
                ref={scope}
                color={(color !== null || targetType === CURSOR_TYPES.SERVICES_PREVIOUS) ? color : 'white'}
                className={targetType === CURSOR_TYPES.SERVICES_PREVIOUS ? 'border' : ''}
                variants={circleVariants}
                initial='hidden'
                animate={isMouseDown ? 'small' : 'show'}
                transition={{duration: 0.4}}
            >
                <StyledTextWrapper>
                    <AnimatePresence mode='wait'>
                        <StyledText
                            key={textMemo}
                            textColor={textColor}
                            initial={{y: 20}}
                            animate={{y: 0}}
                            exit={{y: 20}}
                            transition={{duration: 0.2}}
                        >
                            {textMemo}
                        </StyledText>
                    </AnimatePresence>
                </StyledTextWrapper>
            </StyledCircle>
            <StyledSvgWrapper
                className='rotate'
                variants={svgRightVariants}
                initial='hidden'
                animate={isSvgHiddenMemo ? 'hidden' : 'show'}
                transition={{delay: 0.1, duration: 0.4}}
            >
                <ShortArrowRight/>
            </StyledSvgWrapper>
        </StyledWrapper>
    )
})

export default TouchReactiveCursor;
