import {useDispatch} from "react-redux";
import {ScrollTrigger, useGSAP} from "@repo/utils/gsap";
import {setIsMenuTransparent} from '@/state/menuSlice';
import {usePageTransitionContext} from "../components/PageTransition";

export default function useSetTransparentMenu(ref, forceChangeValueOnInit = false) {
    const { isLoaded } = usePageTransitionContext()
    const dispatch = useDispatch()

    function handleSetTheme(isTransparent) {
        dispatch(setIsMenuTransparent(isTransparent))
    }

    useGSAP(() => {
        if (!isLoaded) return

        if (forceChangeValueOnInit) {
            handleSetTheme(true)
        }

        ScrollTrigger.create({
            trigger: ref.current,
            onEnter: () => handleSetTheme(true),
            onEnterBack: () => handleSetTheme(true),
            onLeave: () => handleSetTheme(false),
            onLeaveBack: () => handleSetTheme(false)
        })
    }, [isLoaded])
}