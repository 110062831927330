import {
    getColorScheme, getCssSizeValue,
    getFontSize,
    getHtmlFromRedactorField,
    getIdWithoutSpecialSymbols, getMapKey,
    getPaddingsClassFromData
} from "@repo/utils";
import styled from "styled-components";
import {BREAKPOINTS} from "../../../styles/themeSettings";
import {CURSOR_TYPES} from "@repo/utils/constants";
import JobOfferCard from "./JobOfferCard";

const Wrapper = styled.section`
    position: relative;
    color: var(--secondary-color);
`

const Header = styled.div`
    letter-spacing: -1.6px;
    font-size: ${getFontSize(40, BREAKPOINTS.MOBILE)};
    line-height: ${getFontSize(57, BREAKPOINTS.MOBILE)};
    margin-bottom: ${getCssSizeValue(100, BREAKPOINTS.MOBILE)};
    em {
        font-weight: 300;
    }
    
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        letter-spacing: -3px;
        font-size: ${getFontSize(75, BREAKPOINTS.TABLET)};
        line-height: ${getFontSize(88, BREAKPOINTS.TABLET)};
        margin-bottom: ${getCssSizeValue(180, BREAKPOINTS.TABLET)};
    }
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        letter-spacing: -4.64px;
        font-size: ${getFontSize(116, BREAKPOINTS.DESKTOP)};
        line-height: ${getFontSize(136, BREAKPOINTS.DESKTOP)};
        margin-bottom: ${getCssSizeValue(180)};
    }
`

const Description = styled.div`
    font-weight: ${props => props.theme.fontWeight.regular};
    font-size: ${getFontSize(24, BREAKPOINTS.MOBILE)};
    line-height: ${getFontSize(31, BREAKPOINTS.MOBILE)};
    margin-bottom: ${getCssSizeValue(80, BREAKPOINTS.MOBILE)};

    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        width: 70%;
        font-size: ${getFontSize(24, BREAKPOINTS.TABLET)};
        line-height: ${getFontSize(40, BREAKPOINTS.TABLET)};
        margin-bottom: ${getCssSizeValue(120, BREAKPOINTS.TABLET)};
    }

    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) {
        width: 62%;
        font-size: ${getFontSize(48, BREAKPOINTS.DESKTOP)};
        line-height: ${getFontSize(66, BREAKPOINTS.DESKTOP)};
        margin-bottom: ${getCssSizeValue(150)};
    }
`

const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: ${getCssSizeValue(20, BREAKPOINTS.MOBILE)};
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        grid-template-columns: repeat(2, 1fr);
        gap: ${getCssSizeValue(20, BREAKPOINTS.TABLET)}; 
    }

    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) {
        grid-template-columns: repeat(4, 1fr);
        gap: ${getCssSizeValue(48)};
    }
`

export default function CareerModule({ data }) {
    const id = getIdWithoutSpecialSymbols(data?.id || '')
    const colorScheme = getColorScheme(data)
    const paddings = getPaddingsClassFromData(data)

    const header = data?.header || ''
    const description = data?.description || ''
    const jobOffers = data?.jobOffers || []

    return (
        <Wrapper
            id={id}
            data-color-scheme={colorScheme}
            className={`${paddings} px-main`}
            data-cursor={CURSOR_TYPES.HTML}
        >
            {!!header && <Header dangerouslySetInnerHTML={getHtmlFromRedactorField(header)} />}
            {!!description && <Description dangerouslySetInnerHTML={getHtmlFromRedactorField(description)} />}

            <Grid>
                {jobOffers.map((item, index) => <JobOfferCard key={getMapKey(item, index)} item={item} /> )}
            </Grid>
        </Wrapper>
    )
}