import styled from "styled-components";
import {BREAKPOINTS, LOGO_COLORS, THEME_COLOR_SCHEMES} from "../../../styles/themeSettings";
import {
    getCssSizeValue,
    getFontSize,
    getIdWithoutSpecialSymbols,
    getPaddingClass,
} from "@repo/utils";
import {useRef} from "react";
import { gsap, useGSAP, ScrollTrigger } from '@repo/utils/gsap'
import {Button, Icon, useIsDesktop, useWindowSize} from "../../../index";
import Content from "../Projects/Content";
import {CURSOR_TYPES, ICONS, LOCALES} from "@repo/utils/constants";
import {getButtonBlockData} from "../common/Button";
import {useRouter} from "next/router";

const StyledWrapper = styled.div`
    position: relative;
    --pin-wrapper-height: 500svh;
`

const StyledExpandedTextWrapper = styled.div`
    height: var(--pin-wrapper-height);
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 1;
    overflow: hidden;
    background: ${({ theme }) => theme.colors.twilightBlue};
    .pin-element {
    }
    
    .icon {
        max-width: unset;
        width: ${getFontSize(260, BREAKPOINTS.MOBILE)};
    
        @media (min-width: ${({theme}) => theme.breakpoints.tablet || 768}px) {
            width: ${getFontSize(360, BREAKPOINTS.TABLET)};
        }

        @media (min-width: ${({theme}) => theme.breakpoints.desktop || 1024}px) and (orientation: landscape) {
            width: ${getFontSize(405, BREAKPOINTS.DESKTOP)};
        }
        
        &.pl {
            width: ${getFontSize(300, BREAKPOINTS.MOBILE)};
        
            @media (min-width: ${({theme}) => theme.breakpoints.tablet || 768}px) {
                width: ${getFontSize(400, BREAKPOINTS.TABLET)};
            }
    
            @media (min-width: ${({theme}) => theme.breakpoints.desktop || 1024}px) and (orientation: landscape) {
                width: ${getFontSize(500, BREAKPOINTS.DESKTOP)};
            }
        }
    }
`

const StyledProjectsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    z-index: 2;
`

const StyledMoreProjectsButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
     margin-top: ${getCssSizeValue(120, BREAKPOINTS.MOBILE)};

    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        margin-top: unset;
    }
`

const DummyColorSchemeBlock = styled.div`
    --first-height: 250svh;
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: var(--first-height);
    &.second {
        top: var(--first-height);
        height: calc(var(--pin-wrapper-height) - var(--first-height));
    }
`

const FOCUS_OPTIONS = {
    MIDDLE: 'middle',
    LEFT: 'left'
}

export default function FeaturedProjects({data, type=''}) {
    const id = getIdWithoutSpecialSymbols(data?.id || '')
    const text = data?.text || ''
    const scaleXAxisVariant = data?.scaleXAxisVariant || ''
    const buttonData = getButtonBlockData(data)
    const paddingBottomData = data?.paddings?.bottom || '';
    const paddingBottom = getPaddingClass(paddingBottomData, 'bottom')
    const button = data?.button

    const { locale } = useRouter()
    const isDesktop = useIsDesktop()

    const wrapperRef = useRef(null)
    const textRef = useRef(null)
    const scaleTextTl = useRef(null)
    const focusLeftterRef = useRef(null)
    const { width } = useWindowSize()

    useGSAP(() => {
        if (!width) return
        const textEl = textRef.current;
        const bbox = textEl.getBoundingClientRect();
        const focusLetterElement = textRef.current.querySelector('#focus');

        const transformValues = {
            x: 0,
            y: 0
        };

        if (focusLetterElement) {
            focusLeftterRef.current = focusLetterElement;
            const focusElementBbox = focusLetterElement.getBoundingClientRect();
            const focusElementCenterX = (focusElementBbox.left + focusElementBbox.right) / 2;
            const focusElementCenterY = (focusElementBbox.top + focusElementBbox.bottom) / 2;

            let xAxisOffset;
            const yAxisOffset = focusElementCenterY - bbox.top;
            if (scaleXAxisVariant === FOCUS_OPTIONS.MIDDLE) {
                xAxisOffset = focusElementCenterX - bbox.left;
            } else {
                const accuracy = locale === LOCALES.EN ? 0.198 : 0.185
                const oneFourthOfElementWidth = focusElementBbox.left - bbox.left + (focusElementBbox.width * accuracy);
                xAxisOffset = oneFourthOfElementWidth;
            }

            const transformOriginXPercentage = (xAxisOffset / bbox.width) * 100;
            const transformOriginYPercentage = (yAxisOffset / bbox.height) * 100;

            transformValues.x = transformOriginXPercentage;
            transformValues.y = transformOriginYPercentage;
        }

        const SCALE_MULTIPLIER = isDesktop ? 1.3 : 1.8
        const totalPaths = textEl.querySelectorAll('path').length;
        const letterWidth = bbox.width / totalPaths;
        const scaleFactor = (width / letterWidth) * SCALE_MULTIPLIER;

        scaleTextTl.current = gsap.timeline({ paused: true })
            .to(".expanded-text", {
                scale: scaleFactor,
                transformOrigin: `${transformValues.x}% ${transformValues.y}%`,
                ease: 'power1.in',
                force3D: false
            });

        ScrollTrigger.create({
            trigger: '.expanded-text-wrapper',
            start: 'top center-=15%',
            end: 'bottom bottom',
            pin: '.pin-element',
            animation: scaleTextTl.current,
            scrub: true,
            pinSpacing: true,
        })
    }, { scope: wrapperRef, dependencies: [width, scaleXAxisVariant, text, locale, isDesktop], revertOnUpdate: true})

    return (
        <StyledWrapper
            id={id}
            ref={wrapperRef}
            className={paddingBottom}
        >
            <DummyColorSchemeBlock
                data-color-scheme={THEME_COLOR_SCHEMES.PLEO_EXTRA}
                data-logo-color={LOGO_COLORS.DARK}
            />
            <DummyColorSchemeBlock
                data-color-scheme={THEME_COLOR_SCHEMES.PLEO_MAIN}
                className='second'
                data-logo-color={LOGO_COLORS.LIGHT}
            />
            <StyledExpandedTextWrapper
                className='expanded-text-wrapper'
                data-logo-color={LOGO_COLORS.LIGHT}
                data-cursor={CURSOR_TYPES.HTML}
            >
                <div className='pin-element'>
                    <div className='expanded-text' ref={textRef}>
                        <Icon name={locale === LOCALES.EN ? ICONS.FEATURED_PROJECTS : ICONS.FEATURED_PROJECTS_PL} className={`no-color ${locale}`} />
                    </div>
                </div>
            </StyledExpandedTextWrapper>
            <StyledProjectsWrapper
                data-color-scheme={THEME_COLOR_SCHEMES.PLEO_MAIN}
                data-logo-color={LOGO_COLORS.DARK}
            >
                <Content data={[data?.projects || []]} webGL type={type} />
                <StyledMoreProjectsButtonWrapper>
                    {button && <Button variant='primary' as='link' href={buttonData.link}>{buttonData.text}</Button>}
                </StyledMoreProjectsButtonWrapper>
            </StyledProjectsWrapper>
        </StyledWrapper>
    )
}
