import React, {useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState} from 'react';
import styled from 'styled-components';
import useIsDesktop from '../../hooks/useIsDesktop';
import useWindowSize from '../../hooks/useWindowSize';
import { useScrollbar } from '@14islands/r3f-scroll-rig';
import {deepClone, setCssVars} from '@repo/utils';
import {
	CURSOR_ATTR,
	CURSOR_EVENT,
	CURSOR_TYPES,
	CURSOR_INITIAL_TARGET,
	AFTER_SIZE, CURSOR_COLOR_ATTR,
} from '@repo/utils/constants';
import {COLORS, THEME_MODES} from "../../../styles/themeSettings";
import { transform } from "framer-motion";
import {useSelector} from "react-redux";
import {useCursor} from "./Cursor";
import {useColor, useExtendedTheme, usePageTransitionContext} from "../../../index";
import TouchReactiveCursor from "./TouchReactiveCursor";
import AssetCursor from "./AssetCursor";
import useCursorAsset from "./useCursorAsset";
import {isMacOs} from "react-device-detect";


const After = styled.div`
	--width: var(--radius);
	--height: var(--radius);
	--x: 0px;
	--y: 0px;
	position: fixed;
	display: block;
	top: 50%;
	left: 50%;
	height: var(--height);
	width: var(--width);
	z-index: ${({$transitionCompleted}) => $transitionCompleted ? 100 : -10};
	transform: translate3d(calc(var(--x) - 50%), calc(var(--y) * -1 - 50%), 0);
	will-change: transform;
	transform-origin: center;
	transition: width 0.3s ease, height 0.3s ease;
	pointer-events: none;
	&:after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		//border: solid 2px transparent;
		border: solid 2px ${({$isMenuOpen, $themeMode, $color}) => {
			if($color) return $color
			if (!$isMenuOpen) return 'var(--secondary-color);'
			if ($themeMode === THEME_MODES.LIGHT) return COLORS.twilightBlue;
			return COLORS.white;
		}};
		border-radius: 50%;
		transition: border-radius 0.3s ease-in-out, scale 0.3s ease-in-out;
		opacity: 1;
		scale: var(--scale);
		//transform: scaleX(--scaleX) scaleY(--scaleY);
		transform: rotate(var(--stickyCursorRotationAngle)) scaleX(var(--stickyCursorScaleX)) scaleY(var(--stickyCursorScaleY));
	}
	&.blob {
	}
	&.circle {
		&:after {
		}
	}
	&.button-without-border {
		&:after {
			background-color: var(--primary-color) !important;
			border: 2px solid var(--primary-color) !important;
		}
	}	
	&.button-without-border-white {
		&:after {
			background-color: white !important;
			border: 2px solid white !important;
		}
	}
	&.button {
		&:after {
			//background-color: var(--primary-color);
			//background-color: royalblue;
			//opacity: 0.8;
			//border: 1px solid var(--primary-color);
			//border: 1px solid royalblue;
			//border-radius: 100%;
		}
	}
	&.button-small-circle {
		&:after {
			border-radius: 800px;
		}
	}
	&.elevator-bar {
		&:after {
			border-radius: 7px;
			border: 1px solid white;
		}
	}
    &.rounded-outline {
        &:after {
			border-radius: 80px;
        }
    }
	&.link {
		&:after {
			border: solid 1px var(--secondary-color);
		}
	}
	&.theme-change {
		z-index: 99;
		&:after {
			 border: solid 1px ${({ theme }) => theme?.colors?.gold || 'rgb(255,215,0)'};
			//border: solid 1px red;
		}
	}
	&.sticky {
		&:after {
			transform: rotate(var(--stickyButtonRotationAngle)) scaleX(var(--stickyButtonScaleX)) scaleY(var(--stickyButtonScaleY));
		}
	}
	
	&.chip{
		&:after{
			border-radius: calc(var(--width) / 2);
		}
	}	
	&.chip-left{
		&:after{
			border-radius: calc(var(--width) / 2);
		}
	}

	&.slider, &.marquee, &.marquee_expanded {
		&:after{
			border: none;
		}
	}

	&.slider_open{
		&:after{
			border: none;
		}
	}
`;

function getChipWidth(el,size){
	const value = getComputedStyle(el).getPropertyValue('--size')
	let result
	if(!value.includes('px')) {
		result = size.width * value.slice(4,10)/100 //recalculate css responsive values
		result.toFixed(2) //cut to 2 floating points
	} else {
		result = value.substring(0,2)//cut px
	}
	return Number(result) - 1 //turn into number, subtract 1px of border
}

const HtmlCursor = () =>  {
    const isDesktop = useIsDesktop();
    const { __lenis } = useScrollbar();
    const windowSize = useWindowSize();
    const extendedTheme = useExtendedTheme()
	const Ref = useRef()
	const { after, target, setTarget } = useCursor()
	const isMenuOpen = useSelector(state => state?.menu?.open)
    const [targetPosition] = useState(() => CURSOR_INITIAL_TARGET.position.clone());
    const [size] = useState(() => deepClone(CURSOR_INITIAL_TARGET.size));
	const [cursorText, setCursorText] = useState('')
	const [cursorColor, setCursorColor] = useState('white')
	const [isSticky, setIsSticky] = useState(false);
	const { cursorAsset, cursorAssetSize, cursorType, entryDirection } = useCursorAsset(target)
	const color = useColor(target.color)
	const { type: targetType } = target;
	const { isLoaded, transitionCompleted } = usePageTransitionContext()

	const handleIntersection = useCallback(
        ({ target, clientX, clientY }) => {
            if (!target) return
			const el = target.closest(`[${CURSOR_ATTR}]`)
			const attr = el?.getAttribute(CURSOR_ATTR);
			const colorAttr = el?.getAttribute(CURSOR_COLOR_ATTR);
			setCursorText(attr);
			setCursorColor(colorAttr);
			const offsetX = windowSize.width / 2
			const offsetY = windowSize.height / 2
            if (!attr || !el) {
                setTarget(CURSOR_INITIAL_TARGET);
            } else {
                setTarget((prev) => {
                    if (attr !== CURSOR_TYPES.STICKY && prev.el === el) return prev;
					const bounds = el.getBoundingClientRect();
                    const { width, height, right, top, left } = bounds;
                    const scrollDif = (__lenis?.animatedScroll || 0) - (__lenis?.targetScroll || 0);
					let blobScale = 0;
					let radius = 2;
					let scale = 1;
					let color = 'secondary'
					const topOffset = isMacOs ? 0.2 : 0.8;
					const rightOffset = isMacOs ? 23.5 : 23.5;
					switch (attr) {
						case CURSOR_TYPES.LINK:
							targetPosition.set(right - offsetX, -(top + height - offsetY) - scrollDif);
							size.width = radius;
							size.height = radius;
							break;
						case CURSOR_TYPES.LINK_LOWERCASE: { //braces fix lexical declaration error in switch case
							radius = 1
							const y =  -(top + height * 0.845 - offsetY) - scrollDif
							targetPosition.set(right - offsetX,y);
							size.width = radius;
							size.height = radius;
						}
							break;
						case CURSOR_TYPES.PILL:
							targetPosition.set(
								right - 24 - offsetX,
								(top -1 + height / 2 - offsetY) * -1 - scrollDif,
							);
							size.width = 5;
							size.height = 5;
							break;
						case CURSOR_TYPES.BUTTON:
							targetPosition.set(
								right - 24 - offsetX,
								(top - 1 + height / 2 - offsetY) * -1 - scrollDif,
							);
							size.width = 5;
							size.height = 5;
							break;
						case CURSOR_TYPES.BUTTON_WITHOUT_BORDER:
							targetPosition.set(
								// right - 22.3 - offsetX,
								right - rightOffset - offsetX,
								(top - topOffset + height / 2 - offsetY) * -1 - scrollDif,
							);
							size.width = 6;
							size.height = 6;
							break;
						case CURSOR_TYPES.BUTTON_WITHOUT_BORDER_WHITE:
							targetPosition.set(
								// right - 22.3 - offsetX,
								right - rightOffset - offsetX,
								(top - topOffset + height / 2 - offsetY) * -1 - scrollDif,
							);
							size.width = 6;
							size.height = 6;
							break;
						case CURSOR_TYPES.ELEVATOR:
							targetPosition.set(right - 245 - offsetX, (top + 30 - offsetY) * -1 - scrollDif);
							size.width = 489;
							size.height = 60;
							break;
						case CURSOR_TYPES.FULL_CIRCLE:
							targetPosition.set(
								right - width / 2 - offsetX,
								(top + height / 2 - offsetY) * -1 - scrollDif,
							);
							size.width = 100;
							size.height = 100;
							break;
						case CURSOR_TYPES.STICKY: {
							let newScaleX, newScaleY, angle
							if (!isSticky) {
								const center = {x: left + width / 2, y: top+height / 2}
								const distance = {x: clientX - center.x, y: clientY - center.y}

								// Rotate cursor
								angle = Math.atan2(distance.y, distance.x)

								// Stretch cursor
								const absDistance = Math.max(Math.abs(distance.x), Math.abs(distance.y))
								newScaleX = transform(absDistance, [0, width / 2], [1, 1.3])
								newScaleY = transform(absDistance, [0, height / 2], [1, 0.8])
								targetPosition.set(
									(right - width / 2 - offsetX) + distance.x * 0.2,
									((top + height / 2 - offsetY) * -1 - scrollDif) - distance.y * 0.2,
								);
							} else {
								targetPosition.set(
									(right - width / 2 - offsetX),
									((top + height / 2 - offsetY) * -1 - scrollDif)
								);
								newScaleX = newScaleY = 1
							}
							setCssVars(Ref.current, { stickyButtonScaleX: newScaleX, stickyButtonScaleY: newScaleY, stickyButtonRotationAngle: `${angle}rad` })
							size.width = 90;
							size.height = 90;
							}
							break;
						case CURSOR_TYPES.ROUNDED_OUTLINE:
							targetPosition.set(
								right - width / 2 - offsetX,
								(top + height / 2 - offsetY) * -1 - scrollDif,
							);
							size.width = width + 20;
							size.height = height + 20;
							break;
						case CURSOR_TYPES.CIRCLE:
							targetPosition.set(
								right - width / 2 - offsetX - 0.5,
								(top + height / 2 - offsetY) * -1 - scrollDif,
							);
							size.width = width;
							size.height = height;
							break;
						case CURSOR_TYPES.CHIP: {
							let chipWidth
							chipWidth = getChipWidth(el, windowSize);
							targetPosition.set(
								left + chipWidth - offsetX, //positioning from left because right changes
								(top + height / 2 - offsetY) * -1 - scrollDif,
							);
							// size.width = width * 2 + 2;
							size.width = chipWidth * 2;
							size.height = chipWidth;
						}
							break;
						case CURSOR_TYPES.CHIP_LEFT: {
							let chipWidth
							chipWidth = getChipWidth(el, windowSize);
							targetPosition.set(
								right - chipWidth - offsetX, //positioning from right because left changes
								(top + height / 2 - offsetY) * -1 - scrollDif,
							);
							size.width = chipWidth * 2;
							size.height = chipWidth;
						}
							break;
						case CURSOR_TYPES.TEXT3D:
							scale = 0;
							blobScale = 2;
							break;
						case CURSOR_TYPES.THEME_CHANGE: {
								let xPosition
								if (extendedTheme?.mode === THEME_MODES.LIGHT) {
									xPosition = right - offsetX - 18;
								} else {
									xPosition = right - offsetX - 32;
								}
								targetPosition.set(xPosition, ((top - offsetY) + height/2) * -1);
								size.width = 15
								size.height = 15
							}
							break;
						case CURSOR_TYPES.IMAGE:
							color = 'transparent'
							break;
						case CURSOR_TYPES.HTML:
							scale = 1;
							size.width = AFTER_SIZE * 20
							size.height = AFTER_SIZE * 20
							break;
						case CURSOR_TYPES.SLIDER:
						case CURSOR_TYPES.SLIDER_OPEN:
						case CURSOR_TYPES.SOON:
						case CURSOR_TYPES.MARQUEE:
						case CURSOR_TYPES.MARQUEE_EXPANDED:
						case CURSOR_TYPES.CHECK:
						case CURSOR_TYPES.SERVICES_NEXT:
						case CURSOR_TYPES.SERVICES_PREVIOUS:
						case CURSOR_TYPES.CLOSE:
						case CURSOR_TYPES.HIDE:
						case CURSOR_TYPES.GO:
							scale = 0;
							break;
						default:
							size.width = CURSOR_INITIAL_TARGET.size.width;
							size.height = CURSOR_INITIAL_TARGET.size.height;
							break;
					}
					return {
						el,
						type: attr,
						blobScale,
						position: targetPosition,
						size,
						isSticky,
						scale,
						bounds,
						color
					};
                });
            }
        },
        [__lenis, size, target, setTarget, targetPosition, windowSize, extendedTheme],
    );

	const handleMouseEnter = () => {
		setIsSticky(true)
	}

	const handleMouseLeave = () => {
		setIsSticky(false)
	}

	// Handling sticky cursor
	useEffect(() => {
		const el = document.querySelector('.sticky-button-element')
		if (!el) return
		if (isDesktop) {
			el.addEventListener('mouseenter', handleMouseEnter, true);
			el.addEventListener('mouseleave', handleMouseLeave, true);
		} else {
			el.addEventListener('mouseenter', handleMouseEnter, true);
			el.removeEventListener('mouseleave', handleMouseLeave, true);
		}
		return () => {
			el.addEventListener('mouseenter', handleMouseEnter, true);
			el.removeEventListener('mouseleave', handleMouseLeave, true);
		};
	}, [isDesktop]);

	useEffect(() => {
        if (isDesktop) {
            window.addEventListener(CURSOR_EVENT, handleIntersection, true);
        } else {
            window.removeEventListener(CURSOR_EVENT, handleIntersection, true);
        }
        return () => {
            window.removeEventListener(CURSOR_EVENT, handleIntersection, true);
        };
    }, [handleIntersection, isDesktop]);

	useImperativeHandle(after, () => Ref.current, [isDesktop])

	const shouldRenderTouchReactiveCursor = useMemo(() => {
		return targetType ===
			CURSOR_TYPES.SLIDER
			|| targetType === CURSOR_TYPES.SLIDER_OPEN
			|| targetType === CURSOR_TYPES.MARQUEE
			|| targetType === CURSOR_TYPES.MARQUEE_EXPANDED
			|| targetType === CURSOR_TYPES.CHECK
			|| targetType === CURSOR_TYPES.SERVICES_PREVIOUS
			|| targetType === CURSOR_TYPES.SERVICES_NEXT
			|| targetType === CURSOR_TYPES.CLOSE
			|| targetType === CURSOR_TYPES.SOON
			|| targetType === CURSOR_TYPES.GO
	}, [targetType])

	return isDesktop &&
		<After
			ref={Ref}
			$isMenuOpen={isMenuOpen}
			$color={color}
			$themeMode={extendedTheme.mode}
			$transitionCompleted={transitionCompleted}
			className={`${target.type} `}
		>
			<AssetCursor
				cursorAsset={cursorAsset}
				cursorAssetSize={cursorAssetSize}
				cursorType={cursorType}
				entryDirection={entryDirection}
			/>
			{
				shouldRenderTouchReactiveCursor && (
					<TouchReactiveCursor cursorText={cursorText} targetType={targetType} color={cursorColor} />
				)
			}
		</After>
};

export default HtmlCursor;
