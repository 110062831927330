import React, { useEffect, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import {
    getColorScheme,
    getCssSizeValue,
    getFontSize,
    getHtmlFromRedactorField,
    getPaddingsClassFromData,
    getIdWithoutSpecialSymbols,
    removeTags
} from '@repo/utils';
import {BREAKPOINTS} from '../../../styles/themeSettings';
import {gsap, ScrollTrigger, useGSAP} from '../../../../utils/gsap'
import CommonVideo from "../common/Video";
import Image from "../common/Image";

const StyledTextCenteredWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	background-color: transparent;
	@media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
		flex-direction: ${({ imageOnRightSide }) => (imageOnRightSide ? 'row' : 'row-reverse')};
		justify-content: space-between;
        padding-right: ${({imageToEdge, imageOnRightSide}) => (imageOnRightSide && imageToEdge) ? 0 : `var(--paddingX)` };
        padding-left: ${({imageToEdge, imageOnRightSide}) => (!imageOnRightSide && imageToEdge) ? 0 : `var(--paddingX)` };
	}
`;

const StyledTextsContainer = styled.div`
    padding-right: var(--paddingX);
    padding-left: var(--paddingX);
	@media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        width: 43%;
		margin-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        &.no-pin {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }
	}
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        padding-right: ${({imageOnRightSide}) => imageOnRightSide ?  `var(--paddingX)` : 0};
        padding-left: ${({imageOnRightSide}) => !imageOnRightSide ?  `var(--paddingX)` : 0};
        width: 50%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        &.no-pin {
            align-items: center;
        }
	}
    @media(min-width: 1920px) {
        //max-width: unset;
    }
`;

const StyledTextsWrapper = styled.div`
	width: 100%;
    height: fit-content;
	position: relative;
	z-index: 0;
	margin-bottom: 90px;
    &.no-pin {
        margin-bottom: 0;
    }
`;

const StyledHeader = styled.div`
	width: 100%;
	color: var(--secondary-color);
	font-weight: ${({ theme }) => theme.fontWeight ? theme.fontWeight.semiBold : 600};
    font-size: ${getFontSize(22, BREAKPOINTS.MOBILE)};
    line-height: ${getFontSize(24, BREAKPOINTS.MOBILE)};
    margin-bottom: ${getCssSizeValue(33, BREAKPOINTS.MOBILE)};
    &::first-letter {
        text-transform: uppercase;
    }
    @media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        font-size: ${getFontSize(26, BREAKPOINTS.TABLET)};
        line-height: ${getFontSize(32, BREAKPOINTS.TABLET)};
        margin-bottom: ${getCssSizeValue(35, BREAKPOINTS.TABLET)};
    }
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		font-size: ${getFontSize(36, BREAKPOINTS.DESKTOP)};
        line-height: ${getFontSize(56, BREAKPOINTS.DESKTOP)};
        margin-bottom: ${getCssSizeValue(70, BREAKPOINTS.DESKTOP)};
	}
`;

const StyleDescription = styled.div`
	width: 100%;
	color: var(--secondary-color);
	font-style: normal;
    font-weight: ${({ theme }) => theme?.fontWeight?.regular || 400};
    font-size: ${getFontSize(16, BREAKPOINTS.MOBILE)};
    line-height: ${getFontSize(28, BREAKPOINTS.MOBILE)};
    @media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        font-size: ${getFontSize(22, BREAKPOINTS.TABLET)};
        line-height: ${getFontSize(32, BREAKPOINTS.TABLET)};
    }
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        font-size: ${getFontSize(28, BREAKPOINTS.DESKTOP)};
        font-weight: 300;
        line-height: ${getFontSize(46, BREAKPOINTS.DESKTOP)};
	}
`;

const StyledImagesWrapper = styled.div`
	width: 100%;
    padding-right: ${({imageToEdge}) => (imageToEdge) ? 0 : `var(--paddingX)` };
    padding-left: ${({imageToEdge}) => (imageToEdge) ? 0 : `var(--paddingX)` };
	@media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        padding-right: 0;
        padding-left: 0;
		width: 50%;
        display: flex;
        flex-direction: row;
        justify-content: center;
	}
    video {
        object-fit: cover;
        width: 100%;
    }
`;

const StyledSingleImage = styled.img`
	width: 100%;
    height: auto;
    //height: fit-content;
    //aspect-ratio: ${({ aspectRatio }) => aspectRatio || '16/9'};
    //height: fit-content;
    object-fit: contain;
    @media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        //height: fit-content;
        height: auto;
        width: ${({finalWidth}) => finalWidth};
        // height: ${({height}) => height};
        aspect-ratio: ${({ aspectRatio }) => aspectRatio || '16/9'};
    }
`;

const SingleVideoWrapper = styled.div`
    position: relative;
    width: 100%;
    aspect-ratio: ${({videoAspect}) => videoAspect};
`

const Video = styled(CommonVideo)`
	position: relative;
	cursor: ${({ theme }) => (theme.mode !== 'a11y' ? 'none' : 'auto')};
`

gsap.registerPlugin(ScrollTrigger);
const PinnedTextWithImage = ({ data }) => {
    const id = getIdWithoutSpecialSymbols(data?.id);
    const noPin = data?.noPin || false;
    const imageToEdge = data?.imagetoedge || false;
    const videoUrl = data?.videoUrl?.url || '';
    const videoHeight = data?.videoUrl?.height || 1;
    const videoWidth = data?.videoUrl?.width || 1;
    const videoAspect = videoWidth / videoHeight;
    const imgTempUrl = data?.imgTempUrl?.src || '';
    const header = data?.header || '';
    const description = data?.description || '';
    // const redactorHeader = getHtmlFromRedactorField(header);
    const redactorHeader = getHtmlFromRedactorField(removeTags(header, ['p'])); //tag removing so animation works fine
    // const redactorDesc = getHtmlFromRedactorField(description);
    const redactorDesc = getHtmlFromRedactorField(removeTags(description, ['p'])); //tag removing so animation works fine
    const imagesData = data?.image || [];
    const assetUrl = imagesData?.[0]?.url || '';
    const assetContentType = imagesData?.[0]?.mimeType || '';
    const isAssetTypeImage = (assetContentType && assetContentType.includes('image')) || false;
    const imageOnRightSide = data?.imageOnRightSide || false;
    const colorScheme = getColorScheme(data)
    const paddings = getPaddingsClassFromData(data)
    const pinnedModuleRef = useRef();
    const textsWrapperRef = useRef();
    const descriptionWrapperRef = useRef();
    const HeaderRef = useRef();
    const DescRef = useRef();
    const [resizeFlag, setResizeFlag] = useState(false);
    const theme = useTheme();
    const [isPinBlocked, setIsPinBlocked] = useState(noPin);

    useEffect(() => {
            setIsPinBlocked(noPin);
    }, [noPin]);

    useEffect(() => {
        if (!document.getElementsByTagName('body')[0]) return;
        const resizeObserver = new ResizeObserver(() => {
            setResizeFlag(!resizeFlag);
            const currentST = ScrollTrigger.getById(`texts-pinned-${id}`);
            currentST && currentST.refresh();
        });
        resizeObserver.observe(document.getElementsByTagName('body')[0]);
        return () => resizeObserver.disconnect();
    }, []);
    useGSAP(() => {
        if(!isPinBlocked) {
            const mm = gsap.matchMedia();
            mm.add(`(min-width: ${theme?.breakpoints?.tablet || 768}px)`, () => {
                const el = descriptionWrapperRef.current;
                const offset = el?.getBoundingClientRect()?.height || 0;
                gsap.from(pinnedModuleRef.current, {
                    scrollTrigger: {
                        id: `texts-pinned-${id}`,
                        trigger: descriptionWrapperRef.current,
                        start: 'top top+=170',
                        endTrigger: pinnedModuleRef.current,
                        end: `bottom top+=${offset + 220}`,
                        // markers: true,
                        pin: true,
                        pinSpacing: false,
                    },
                });
            });
        }
    }, [isPinBlocked]);

    // useLinesReveal({
    //     el:HeaderRef,
    //     onInView: true,
    //     disabled: !header,
    //     pinnedContainer: descriptionWrapperRef
    // })
    // useLinesReveal({
    //     el:DescRef,
    //     onInView: true,
    //     disabled: !description,
    //     pinnedContainer: descriptionWrapperRef
    // })

    return (
        <StyledTextCenteredWrapper
            id={id}
            data-color-scheme={colorScheme}
            imageOnRightSide={imageOnRightSide}
            imageToEdge={imageToEdge}
            // className={`${paddings} px-main`}
            className={`${paddings}`}
        >
            <StyledTextsContainer
                imageOnRightSide={imageOnRightSide}
                imageToEdge={imageToEdge}
                className={noPin ? 'no-pin' : ''}
                ref={textsWrapperRef}>
                <StyledTextsWrapper className={noPin ? 'no-pin' : ''} ref={descriptionWrapperRef}>
                    {/*<Reveal onInView pinnedContainer={descriptionWrapperRef}>*/}
                    {/*    <StyledHeader ref={HeaderRef} dangerouslySetInnerHTML={redactorHeader} />*/}
                    {/*</Reveal>*/}
                    {/*<Reveal onInView pinnedContainer={descriptionWrapperRef}>*/}
                    {/*    <StyleDescription ref={DescRef} dangerouslySetInnerHTML={redactorDesc} />*/}
                    {/*</Reveal>*/}
                    <StyledHeader ref={HeaderRef} dangerouslySetInnerHTML={redactorHeader} />
                    <StyleDescription ref={DescRef} dangerouslySetInnerHTML={redactorDesc} />
                </StyledTextsWrapper>
            </StyledTextsContainer>
            <StyledImagesWrapper imageToEdge={imageToEdge} ref={pinnedModuleRef}>
                {videoUrl === '' && isAssetTypeImage && imagesData &&  imagesData.map((item, key) => {
                    const imgUrl = item?.url || '';
                    const width = item?.width || 0;
                    const height = item?.height || 1;
                    const type = item?.mimeType || '';
                    const isGifType = type.includes('gif');
                    const aspectRatio = width / height === 0 ? '16/9' : width / height;
                    const moduleWidth = pinnedModuleRef?.current?.offsetWidth || 1;
                    const finalWidth = width < moduleWidth ? `${width}px` : '100%';
                    return(
                        <React.Fragment key={imgUrl}>
                            {!isGifType ?
                                <StyledSingleImage finalWidth={finalWidth} height={height} aspectRatio={aspectRatio}  key={imgUrl} src={imgUrl} alt="img-alt"/> :
                                <Image src={imgUrl} fit="contain" sizes="100%" />}
                        </React.Fragment>
                    )
                })}
                {!isAssetTypeImage && imagesData.length > 0 && (
                    <video
                        preload="auto"
                        muted
                        loop
                        autoPlay={true}
                        playsInline
                    >
                        <source src={assetUrl} type="video/mp4"/>
                    </video>
                )}
                {/*{imgTempUrl && <StyledSingleImage key={imgTempUrl} src={imgTempUrl} alt="img-alt"/>}*/}
                {videoUrl !== '' && <SingleVideoWrapper videoAspect={videoAspect}>
                    <Video autoplay={true} src={videoUrl}/>
                </SingleVideoWrapper>}
            </StyledImagesWrapper>
        </StyledTextCenteredWrapper>
    );
};

export default PinnedTextWithImage;
