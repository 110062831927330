import styled from "styled-components";
import {getCssSizeValue, getFontSize, getHtmlFromRedactorField, handleSetPositionOnMouseMove} from "@repo/utils";
import {BREAKPOINTS} from "../../../styles/themeSettings";
import {Button, getCardData, Image, Reveal, useExpandBackgroundAnimation, Video} from "../../../index";
import {useEffect, useMemo, useState} from "react";
import {useRouter} from "next/router";
import useHover from "../../hooks/useHover";

const StyledEmblaWrapper = styled.div`
	flex: 0 0 auto;
	max-width: 90%;
	min-width: 0;
	position: relative;
    @media (min-width: ${({ theme }) => theme.breakpoints.desktop || 1024}px) and (orientation: landscape) {
		flex: 0 0 auto;
		max-width: 80%;
        cursor: pointer;
	}
`;

const StyledCard = styled.div`
    overflow: hidden;
    user-select: none;
    background: ${props => props.theme.colors.lightGray};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;    
    max-width: ${getCssSizeValue(360, BREAKPOINTS.MOBILE)};
    min-height: ${getCssSizeValue(490, BREAKPOINTS.MOBILE)};
    padding: ${getCssSizeValue(66, BREAKPOINTS.MOBILE)} ${getCssSizeValue(28, BREAKPOINTS.MOBILE)} ${getCssSizeValue(44, BREAKPOINTS.MOBILE)};
    
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        max-width: unset;
        max-height: unset;
        width: ${getCssSizeValue(480, BREAKPOINTS.TABLET)};
        height: ${getCssSizeValue(620, BREAKPOINTS.TABLET)};
        justify-content: space-between;
        padding: ${getCssSizeValue(49, BREAKPOINTS.TABLET)} ${getCssSizeValue(28, BREAKPOINTS.TABLET)} ${getCssSizeValue(37, BREAKPOINTS.TABLET)};
    }
    
    @media (min-width: ${({ theme }) => theme.breakpoints.desktop || 1024}px) and (orientation: landscape) {
        width: ${getCssSizeValue(560, BREAKPOINTS.DESKTOP)};
        height: ${getCssSizeValue(724, BREAKPOINTS.DESKTOP)};
        padding: ${getCssSizeValue(89, BREAKPOINTS.DESKTOP)} ${getCssSizeValue(24, BREAKPOINTS.DESKTOP)} ${getCssSizeValue(55, BREAKPOINTS.DESKTOP)};
    }
    
    .video {
        aspect-ratio: ${({ $aspectRatio }) => $aspectRatio || '16/9'};
        width: 100%;
        height: auto;
    }
    
    .header {
        font-size: ${getFontSize(36, BREAKPOINTS.MOBILE)};
        line-height: ${getFontSize(40, BREAKPOINTS.MOBILE)};
        font-weight: ${({ theme }) => theme.fontWeight.bold || 700};
        margin-top: ${getCssSizeValue(65, BREAKPOINTS.MOBILE)};
        margin-bottom: ${getCssSizeValue(35, BREAKPOINTS.MOBILE)};
        letter-spacing: -1.6px;
        
        @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(75, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(85, BREAKPOINTS.TABLET)};
            margin-top: ${getCssSizeValue(63, BREAKPOINTS.TABLET)};
            margin-bottom: 0;
            letter-spacing: -3px;
        }
        
        @media (min-width: ${({ theme }) => theme.breakpoints.desktop || 1024}px) and (orientation: landscape) {
            font-size: ${getFontSize(90, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(96, BREAKPOINTS.DESKTOP)};
            letter-spacing: -3.6px;
            margin-top: ${getCssSizeValue(61)};
            margin-bottom: 0;
        }
    }
    .description-container {
        @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
            height: ${getCssSizeValue(160, BREAKPOINTS.TABLET)};
        }
        
        @media (min-width: ${({ theme }) => theme.breakpoints.desktop || 1024}px) and (orientation: landscape) {
            height: ${getCssSizeValue(180, BREAKPOINTS.DESKTOP)};
        }
    }
    .description {
        font-size: ${getFontSize(16, BREAKPOINTS.MOBILE)};
        line-height: ${getFontSize(26, BREAKPOINTS.MOBILE)};
        font-weight: ${({ theme }) => theme.fontWeight.regular || 400};
        @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(20, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(26, BREAKPOINTS.TABLET)};
        }
        
        @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
            font-size: ${getFontSize(24, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(36, BREAKPOINTS.DESKTOP)};
        }
    }
    
    .button {
        margin-top: ${getCssSizeValue(30, BREAKPOINTS.MOBILE)};
        
        @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
            margin-top: ${getCssSizeValue(35, BREAKPOINTS.TABLET)};
        }
        
        @media (min-width: ${({ theme }) => theme.breakpoints.desktop || 1024}px) and (orientation: landscape) {
            margin-top: ${getCssSizeValue(50, BREAKPOINTS.DESKTOP)};
        }
    }
`

const CardBackground = styled.div`
    position: absolute;
    background: ${props => props.theme.colors.white};
    top: var(--y, 50%);
    left: var(--x, 50%);
    background: ${props => props.theme.colors.aliceBlue};
    border-radius: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    width: var(--width); 
    height: var(--height);
`

const StyledImage = styled(Image)`
    aspect-ratio: ${({ $aspectRatio }) => $aspectRatio || '16/9'};
    width: auto;
    height: ${getCssSizeValue(100, BREAKPOINTS.MOBILE)};

    @media (min-width: ${({theme}) => theme.breakpoints.tablet || 768}px) {
        height: ${getCssSizeValue(100, BREAKPOINTS.TABLET)};
    }

    @media (min-width: ${({theme}) => theme.breakpoints.desktop || 1024}px) and (orientation: landscape) {
        height: ${getCssSizeValue(120)};
    }
`

export default function SingleCard({ item, buttonText = '', index }) {
    const router = useRouter()
    const { header, description, icon, button } = getCardData(item?.servicePage?.card || {})
    const [ref, isHovering] = useHover({onlyDesktop: true})
    const videoSrc = item?.video?.url || ''
    const videoAlt = item?.video?.alt || ''
    const videoWidth = item?.image?.width || 0;
    const videoHeight = item?.image?.height || 1;
    const aspectRatio = useMemo(() => {
        let result;
        if (videoSrc !== '') {
            result =  videoWidth / videoHeight === 0 ? '16/9' : videoWidth / videoHeight;
        } else {
            result = icon.width / icon.height === 0 ? '16/9' : icon.width / icon.height;
        }

        return result
    }, [])

    // const [isHovered, setIsHovered] = useState(false)
    const hoverTl = useExpandBackgroundAnimation(ref)
    useEffect(() => {
        isHovering ? hoverTl.current.play() : hoverTl.current.reverse()
    }, [isHovering]);

    return (
        <StyledEmblaWrapper
            onClick={() => router.push(`/services/${button.link}`)}
        >
            <Reveal onInView>
                <StyledCard
                    ref={ref}
                    $aspectRatio={aspectRatio}
                    className={`${isHovering ? 'hover single-card' : 'single-card'}`}
                    onMouseMove={handleSetPositionOnMouseMove}
                    // onMouseEnter={() => setIsHovered(prevState => !prevState)}
                    // onMouseLeave={() => setIsHovered(prevState => !prevState)}
                >
                    <CardBackground/>
                    <div>
                        <div>
                            {icon.url !== '' && (
                                <StyledImage
                                    src={icon.url}
                                    alt={icon.alt}
                                    sizes={'100vw'}
                                    $aspectRatio={aspectRatio}
                                />
                            )}
                            {videoSrc !== '' && (
                                <div className='video'>
                                    <Video src={videoSrc} alt={videoAlt} playing={isHovered} />
                                </div>
                            )}
                        </div>
                        <div className='header'>{header}</div>
                    </div>
                    <div className='description-container'>
                        <div className='description' dangerouslySetInnerHTML={description}/>
                        {/*<div className='button'>*/}
                        {/*    <Button className={`${isHovered ? 'hover': ''}`} as='link' href={`/services/${button.link}`} variant='primary'>{buttonText}</Button>*/}
                        {/*</div>*/}
                    </div>
                </StyledCard>
            </Reveal>
        </StyledEmblaWrapper>
    )
}
