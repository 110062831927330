import {useEffect, useRef} from "react"
import {gsap} from "@repo/utils/gsap"
import {usePageTransitionContext} from "../components/PageTransition"

const SINGLE_CHARACTER_DURATION = 0.015

const useStaggeredUnderline = ({selector}) => {
    const { isLoaded } = usePageTransitionContext()
    const ref = useRef(null)

    useEffect(() => {
        if (!ref.current || !isLoaded) return

        const controller = new AbortController()
        const {signal} = controller

        const textElements = ref?.current?.querySelectorAll(selector) || []

        textElements?.forEach((el) => {
            gsap.set(el, {
                display: "inline-block",
                position: "relative",
                textDecoration: "none",
                backgroundImage: "linear-gradient(currentColor, currentColor)",
                backgroundPosition: "0% 85%",
                backgroundRepeat: "no-repeat",
                backgroundSize: "0% 2px",
                transition: "background-size 0.3s ease-in-out",
            })
        })

        const tl = gsap.timeline({ paused: true })

        textElements.forEach(el => {
            const textContent = el?.textContent || ''
            const textContentLength = textContent.length
            const duration = SINGLE_CHARACTER_DURATION * textContentLength;

            tl.to(el, {
                backgroundSize: "100% 2px",
                ease: "expo.out",
                delay: 0.1,
                duration
            })
        })

        const handleMouseEnter = () => {
            tl.play()
        }

        const handleMouseLeave = () => {
            tl.timeScale(2).reverse()
        }

        ref.current?.addEventListener("mouseenter", handleMouseEnter, {signal})
        ref.current?.addEventListener("mouseleave", handleMouseLeave, {signal})

        return () => controller.abort()
    }, [ref.current, isLoaded])

    return ref
}

export default useStaggeredUnderline