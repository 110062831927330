import {Button, Reveal, useExpandBackgroundAnimation, useHover} from "../../../index";
import {CURSOR_TYPES} from "@repo/utils/constants";
import {getCssSizeValue, getFontSize, getHtmlFromRedactorField, handleSetPositionOnMouseMove} from "@repo/utils";
import styled from "styled-components";
import {BREAKPOINTS} from "../../../styles/themeSettings";
import {multilineUnderlineCss} from "../../../styles/globalStyles";
import {useCallback, useEffect} from "react";
import useIsDesktop from "../../hooks/useIsDesktop";
import {useRouter} from "next/router";

const Card = styled.div`
    overflow: hidden;
    position: relative;
    cursor: pointer;
    padding: ${getCssSizeValue(42, BREAKPOINTS.MOBILE)} ${getCssSizeValue(35, BREAKPOINTS.MOBILE)} ${getCssSizeValue(40, BREAKPOINTS.MOBILE)};
    background: ${props => props.theme.colors.white};

    @media (min-width: ${({theme}) => theme?.breakpoints?.tablet || 768}px) {
        gap: ${getCssSizeValue(70, BREAKPOINTS.TABLET)};
        padding: ${getCssSizeValue(42, BREAKPOINTS.TABLET)} ${getCssSizeValue(30, BREAKPOINTS.TABLET)} ${getCssSizeValue(50, BREAKPOINTS.TABLET)};
    }

    @media (min-width: ${({theme}) => theme?.breakpoints?.desktop || 1024}px) {
        gap: ${getCssSizeValue(120)};
        padding: ${getCssSizeValue(42, BREAKPOINTS.DESKTOP)} ${getCssSizeValue(40, BREAKPOINTS.DESKTOP)} ${getCssSizeValue(50, BREAKPOINTS.DESKTOP)};
    }

    .header-level {
        .header {
            ${multilineUnderlineCss};

            letter-spacing: -0.88px;
            font-size: ${getFontSize(22, BREAKPOINTS.MOBILE)};
            line-height: ${getFontSize(28, BREAKPOINTS.MOBILE)};
            margin-bottom: ${getCssSizeValue(20, BREAKPOINTS.MOBILE)};

            @media (min-width: ${({theme}) => theme?.breakpoints?.tablet || 768}px) {
                letter-spacing: -0.96px;
                font-size: ${getFontSize(24, BREAKPOINTS.TABLET)};
                line-height: ${getFontSize(30, BREAKPOINTS.TABLET)};
                margin-bottom: ${getCssSizeValue(20, BREAKPOINTS.TABLET)};
            }

            @media (min-width: ${({theme}) => theme?.breakpoints?.desktop || 1024}px) {
                letter-spacing: unset;
                width: 90%;
                font-weight: ${props => props.theme.fontWeight.bold};
                font-size: ${getFontSize(34, BREAKPOINTS.DESKTOP)};
                line-height: ${getFontSize(46, BREAKPOINTS.DESKTOP)};
                margin-bottom: ${getCssSizeValue(25)};
            }
        }

        .level {
            display: flex;
            gap: 2px;
        }
    }

    .description-button {
        .description {
            padding-top: 20px;
            border-top: 1px solid var(--secondary-color);
            font-weight: ${props => props.theme.fontWeight.regular};
            font-size: ${getFontSize(16, BREAKPOINTS.MOBILE)};
            line-height: ${getFontSize(26, BREAKPOINTS.MOBILE)};

            @media (min-width: ${({theme}) => theme?.breakpoints?.tablet || 768}px) {
                font-size: ${getFontSize(18, BREAKPOINTS.TABLET)};
                line-height: ${getFontSize(24, BREAKPOINTS.TABLET)};
            }

            @media (min-width: ${({theme}) => theme?.breakpoints?.desktop || 1024}px) {
                font-size: ${getFontSize(18, BREAKPOINTS.DESKTOP)};
                line-height: ${getFontSize(24, BREAKPOINTS.DESKTOP)};
                margin-bottom: ${getCssSizeValue(32)};
            }
        }
    }

    transition: transform var(--transition-duration) var(--ease);

    &.hover {
        @media (min-width: ${({theme}) => theme?.breakpoints?.desktop || 1024}px) {
            transform: scale(1.02);
        }
    }
`

const CardBackground = styled.div`
    position: absolute;
    background: ${props => props.theme.colors.white};
    top: var(--y, 50%);
    left: var(--x, 50%);
    background: ${props => props.theme.colors.aliceBlue};
    border-radius: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
    width: var(--width); 
    height: var(--height);
`

const Pill = styled.div`
    height: 2px;
    width: 30px;
    background: ${props => props.theme.colors.dimmedGray};
    transition: background var(--color-transition-duration) var(--ease);
    
    &.hover {
        background: ${props => props.theme.colors.richBlack};
    }

    &.one {
        &:first-child {
            background: ${props => props.theme.colors.designBlue};
        }
    }

    &.two {
        &:first-child, &:nth-child(2) {
            background: ${props => props.theme.colors.gold};
        }
    }

    &.three {
        background: ${props => props.theme.colors.red};
    }
    
`

const CardContent = styled.div`
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    gap: ${getCssSizeValue(78, BREAKPOINTS.MOBILE)};

    @media (min-width: ${({theme}) => theme?.breakpoints?.tablet || 768}px) {
        gap: ${getCssSizeValue(70, BREAKPOINTS.TABLET)};
    }

    @media (min-width: ${({theme}) => theme?.breakpoints?.desktop || 1024}px) {
        gap: ${getCssSizeValue(120)};
    }
`

const ADVANCEMENT_LEVELS = {
    ONE: 'one',
    TWO: 'two',
    THREE: 'three'
}

function formatData(data) {
    return {
        slug: data?.slug || '',
        header: data?.card?.header || '',
        level: data?.card?.level || '',
        description: data?.card?.description || '',
        buttonText: data?.card?.buttonText || '',
    }
}

export default function JobOfferCard({item}) {
    const router = useRouter()
    const isDesktop = useIsDesktop()
    const [ref, isHovering] = useHover({onlyDesktop: true})

    const {slug, header, level, description, buttonText} = formatData(item)
    const advancementLevelClassName = ADVANCEMENT_LEVELS[level]

    const handleClick = useCallback((slug) => {
        router.push(`/about/${slug}`)
    }, [router])

    const hoverTl = useExpandBackgroundAnimation(ref)

    useEffect(() => {
        isHovering ? hoverTl.current.play() : hoverTl.current.reverse()
    }, [isHovering]);

    return (
        <Card
            ref={ref}
            onClick={() => handleClick(slug)}
            className={`${isHovering ? 'hover' : ''}`}
            onMouseMove={handleSetPositionOnMouseMove}
        >
            <CardBackground/>
            <CardContent>
                <Reveal onInView>
                    <div className='header-level'>
                        <div
                            className='header'
                            data-cursor={CURSOR_TYPES.HIDE}
                        >
                            <div dangerouslySetInnerHTML={getHtmlFromRedactorField(header)}/>
                        </div>
                        <div className='level'>
                            {Array.from({length: 3}).map((_, index) => {
                                return <Pill
                                    className={`pill ${advancementLevelClassName} ${isHovering ? 'hover' : ''}`}
                                    key={index}
                                />
                            })}
                        </div>
                    </div>
                    <div className='description-button'>
                        <div className='description' dangerouslySetInnerHTML={getHtmlFromRedactorField(description)}/>
                        {isDesktop && (
                            <Button
                                onClick={() => handleClick(slug)}
                                className={isHovering ? 'hover' : ''}
                                variant='tertiary'
                            >
                                {buttonText}
                            </Button>
                        )}
                    </div>
                </Reveal>
            </CardContent>
        </Card>
    )
}