import {useEffect} from "react";
import {useRouter} from "next/router";
import {useDispatch} from "react-redux";
import {setIsMenuTransparent} from '@/state/menuSlice';

export default function useSetIsMenuTransparentOnPageLoad() {
    const router = useRouter();
    const dispatch = useDispatch()

    const regex = /^\/services\/.+/;

    function handleSetTheme(isTransparent) {
        dispatch(setIsMenuTransparent(isTransparent))
    }

    useEffect(() => {
        const isSingleServicePage = regex.test(router.asPath)

        if (!isSingleServicePage) {
            handleSetTheme(false)
        }
    }, []);
}