import React, { useRef} from 'react';
import styled from 'styled-components';
import {
	getColorScheme,
	getHtmlFromRedactorField,
	getPaddingsClassFromData,
	getIdWithoutSpecialSymbols,
	getCssSizeValue, getFontSize
} from '@repo/utils';
import { Button } from "../../../index";
import {BREAKPOINTS} from "../../../styles/themeSettings";
import {gsap, ScrollTrigger, useGSAP} from '../../../../utils/gsap'
import {CMS_ELEMENT_TYPENAME, CONTENT_TYPES, CURSOR_TYPES} from "@repo/utils/constants";
import {useRouter} from "next/router";

const StyledProjectRangeWrapper = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	width: 100%;
	//padding: 0 15px;
	@media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
		//padding: 0 30px;
	}
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		//padding: 0 100px;
		flex-direction: row;
		justify-content: space-between;
		column-gap: 50px;
	}
`;


const StyledTextsContainer = styled.div`
	width: 100%;
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		width: 50%;
	}
`;

const StyledProjectRangeContentWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	//border: 2px solid red;
	&.second {
		//background-color: red;
		//border: 2px solid royalblue;
		@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
			width: 50%;
		}
	}
`;

const StyledProjectShortDescription = styled.h2`
	text-align: left;
	color: var(--secondary-color);
	transition: color 0.1s linear;
	font-weight: 600;
	margin-bottom: 42px;
	font-size: ${getFontSize(30, BREAKPOINTS.MOBILE)};
	line-height: ${getFontSize(42, BREAKPOINTS.MOBILE)};
	@media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
		max-width: 50%;
		font-size: ${getFontSize(36, BREAKPOINTS.TABLET)};
		line-height: ${getFontSize(46, BREAKPOINTS.TABLET)};
		margin-bottom: 45px;
	}
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		max-width: 100%;
		margin-bottom: 45px;
		font-size: ${getFontSize(48, BREAKPOINTS.DESKTOP)};
		line-height: ${getFontSize(56, BREAKPOINTS.DESKTOP)};
	}
	@media (min-width: 1440px) {
		//max-width: 505px;
		max-width: 70%;
	}
`;

const StyledProjectInfoTable = styled.div`
	width: 100%;
	margin-bottom: 45px;
	@media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
		max-width: 580px;
	}
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		max-width: unset;
		margin-bottom: 75px;
	}
	@media (min-width: 1440px) {
		//width: 581px;
		width: ${getCssSizeValue(700, BREAKPOINTS.DESKTOP)};
	}
`;

const StyledSingleProjectInfoTableRow = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	color: var(--secondary-color);
	transition: color 0.1s linear;
	//margin-bottom: 15px;
	padding: 19px 0 16px;
	position: relative;

	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		width: 90%;
	}
	
	.multiline-underline-animation {
		display: inline;
		background:linear-gradient(var(--secondary-color),var(--secondary-color)) left bottom no-repeat;
		background-size:0% 1px;
		text-decoration: none;
		transition: all var(--transition-duration) var(--ease);

		&:hover {
			background-size:100% 1px;
		}
	}
	span {
		cursor: pointer;
		//text-transform: lowercase;
	}
	svg {
		width: 70px;
		height: 13px;
		position: absolute;
		right: 0;
		bottom: -8px;
	}
	&.year::before {
		display: none;
	}
	&:before {
		content: '';
		position: absolute;
		left: 0;
		bottom: -2px;
		height: 1px;
		width: 100%;
		background-color: var(--secondary-color);
		transition: background-color 0.1s linear;
	}
	a {
		text-decoration: none;
		color: inherit;
	}
	.underline-animation {
		cursor: pointer;
		position: relative;
		max-width: fit-content;
		&:before {
			background: var(--secondary-color);
		}
	}
	//&:first-of-type {
	//	&:before {
	//		width: calc(100% - 69px);
	//	}
	//}
	div:first-child {
		width: 130px;
		font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
		line-height: ${getFontSize(24, BREAKPOINTS.MOBILE)};
		font-weight: 400;
		@media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
			font-size: ${getFontSize(18, BREAKPOINTS.TABLET)};
			line-height: ${getFontSize(24, BREAKPOINTS.TABLET)};
			min-width: 150px;
		}
		@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
			font-size: ${getFontSize(16, BREAKPOINTS.DESKTOP)};
			line-height: ${getFontSize(22, BREAKPOINTS.DESKTOP)};
			width: 40%;
		}
	}
	div:nth-child(2) {
		width: calc(100% - 130px);
		font-size: ${getFontSize(18, BREAKPOINTS.MOBILE)};
		line-height: ${getFontSize(24, BREAKPOINTS.MOBILE)};
		font-weight: 400;
		@media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
			font-size: ${getFontSize(18, BREAKPOINTS.TABLET)};
			line-height: ${getFontSize(26, BREAKPOINTS.TABLET)};
		}
		@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
			width: 60%;
			font-size: ${getFontSize(16, BREAKPOINTS.DESKTOP)};
			line-height: ${getFontSize(24, BREAKPOINTS.DESKTOP)};
		}
	}
`;

const StyledProjectRangeDescription = styled.div`
	font-size: ${getFontSize(16, BREAKPOINTS.MOBILE)};
	line-height: ${getFontSize(28, BREAKPOINTS.MOBILE)};
	font-weight: ${({ theme }) => theme?.fontWeight?.book || 300};
	letter-spacing: 0em;
	text-align: left;
	color: var(--secondary-color);
	transition: color 0.1s linear;
	@media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
		font-size: ${getFontSize(20, BREAKPOINTS.TABLET)};
		line-height: ${getFontSize(30, BREAKPOINTS.TABLET)};
	}
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		font-size: ${getFontSize(21, BREAKPOINTS.DESKTOP)};
		line-height: ${getFontSize(34, BREAKPOINTS.DESKTOP)};
	}
	p:nth-of-type(2n) {
		margin: 20px 0 35px;
		@media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
			margin: 30px 0 60px;
		}
		@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
			margin: 40px 0 78px;
		}
	}

	strong {
		font-weight: 600;
		font-size: ${getFontSize(18, BREAKPOINTS.MOBILE)};
		line-height: ${getFontSize(24, BREAKPOINTS.MOBILE)};
		@media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
			font-size: ${getFontSize(20, BREAKPOINTS.TABLET)};
			line-height: ${getFontSize(24, BREAKPOINTS.TABLET)};
		}
		@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
			font-size: ${getFontSize(28, BREAKPOINTS.DESKTOP)};
			line-height: ${getFontSize(24, BREAKPOINTS.DESKTOP)};
		}
	}
`;

const StyledLinksButtonsWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	column-gap: 50px;
	margin-bottom: 64px;
	@media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
		justify-content: flex-start;
	}
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		margin-bottom: 0;
	}
`;

gsap.registerPlugin(ScrollTrigger);
const ProjectRange = ({ data, locale }) => {
	const projectShortDescription = data?.projectShortDescription || '';
	const projectDescription = data?.projectDescription || '';
	const router = useRouter();
	const id = getIdWithoutSpecialSymbols(data?.id);
 	const carouselText = data?.carouselText || '';
	const client = data?.client || '';
	const industries = data?.industry || [];
	const technologies = data?.technologies || '';
	const tools = data?.tools || '';
	const competences = data?.competences || '';
	const cooperation = data?.cooperation || '';
	const links = data?.link || '';
	const serviceRange = data?.serviceRange || [];
	const year = data?.year || '';
	const year2 = data?.year2 > 0 ? `-${data.year2.toString()}` : '';
	const dateRange = `${year.toString()}${year2}`.trim()
	const clientPlaceholder = locale === 'pl' ? 'KLIENT:' : 'CLIENT:';
	const industryPlaceholder = locale === 'pl' ? 'BRANŻA:' : 'INDUSTRY:';
	const serviceRangePlaceholder = locale === 'pl' ? 'ZAKRES USŁUG:' : 'SERVICE RANGE:';
	const technologiesPlaceholder = locale === 'pl' ? 'TECHNOLOGIE:' : 'TECHNOLOGIES:';
	const competencesPlaceholder = locale === 'pl' ? 'KOMPETENCJE:' : 'COMPETENCES:';
	const yearPlaceholder = locale === 'pl' ? 'ROK:' : 'YEAR:';
	const toolsPlaceholder = locale === 'pl' ? 'NARZĘDZIA:' : 'TOOLS:';
	const cooperationPlaceholder = locale === 'pl' ? 'WSPÓŁPRACA:' : 'COOPERATION:';
	const industriesString = industries.map((item) => item.industryName).join(', ');
	const paddings = getPaddingsClassFromData(data)
	const colorScheme = getColorScheme(data)
	const pinnedModuleRef = useRef();
	const descriptionWrapperRef = useRef();

	useGSAP(() => {
		const mm = gsap.matchMedia();
		mm.add(`(min-width: 1024px)`, () => {
			const el = pinnedModuleRef.current;
			const offset = el?.getBoundingClientRect()?.height || 0;
			gsap.from(descriptionWrapperRef.current, {
				scrollTrigger: {
					id: `project-range-${id}`,
					trigger: pinnedModuleRef.current,
					start: 'top top+=120',
					endTrigger: descriptionWrapperRef.current,
					end: `bottom top+=${offset + 120}`,
					// markers: true,
					pin: true,
					pinSpacing: false,
				},
			});
		});
	}, []);

	return (
		<StyledProjectRangeWrapper id={id} data-color-scheme={colorScheme} className={`${paddings} px-main`}>
			<StyledTextsContainer>
				<StyledProjectRangeContentWrapper ref={pinnedModuleRef}>
					<StyledProjectShortDescription>{projectShortDescription}</StyledProjectShortDescription>
					<StyledProjectInfoTable>
						<StyledSingleProjectInfoTableRow>
							<div>{clientPlaceholder}</div>
							<div>{client.clientName}</div>
						</StyledSingleProjectInfoTableRow>
						{industriesString !== '' && <StyledSingleProjectInfoTableRow>
							<div>{industryPlaceholder}</div>
							<div>{industriesString}</div>
						</StyledSingleProjectInfoTableRow>}
						{serviceRange.length > 0 && <StyledSingleProjectInfoTableRow>
							<div>{serviceRangePlaceholder}</div>
							<div>{serviceRange.map((item, index) => {
								const serviceName = item?.serviceName || ''
								const competenceName = item?.competenceName || ''
								const renderItem = serviceName ? serviceName : competenceName
								const slug = item?.slug || ''
								const href = item?.[CMS_ELEMENT_TYPENAME] === CONTENT_TYPES.SERVICE_PAGE_RECORD ? `/services/${slug}` : `/competence/${slug}`
								return (
									<span className='multiline-underline-animation' data-cursor={CURSOR_TYPES.HIDE} onClick={() => router.push(href)} key={index}>
                                            {renderItem}
										{index < serviceRange.length - 1 && ', '}
                                        </span>
								)
							})}</div>
						</StyledSingleProjectInfoTableRow>}
						{competences.length > 0 && <StyledSingleProjectInfoTableRow>
							<div>{competencesPlaceholder}</div>
							<div>{competences.map((item, index) => {
								const competenceName = item?.competenceName || ''
								const slug = item?.slug || ''
								const href = `/competence/${slug}`
								return (
									<span className='underline-animation' data-cursor={CURSOR_TYPES.LINK} onClick={() => router.push(href)} key={index}>
                                            {competenceName}
										{index < competences.length - 1 && ', '}
                                        </span>
								)
							})}</div>
						</StyledSingleProjectInfoTableRow>}
						{technologies.length > 0 && <StyledSingleProjectInfoTableRow>
							<div>{technologiesPlaceholder}</div>
							<div>{technologies.map((item, index) => {
								const technologyOrToolName = item?.projectTechnologyName || ''
								const slug = item?.slug || ''
								const href = `/tools-methods/${slug}`;
								return (
									<span key={index} style={{ cursor: 'initial' }}>
										{technologyOrToolName}
										{index < technologies.length - 1 && ', '}
									</span>
								)
							})}</div>
						</StyledSingleProjectInfoTableRow>}
						{tools.length > 0 && <StyledSingleProjectInfoTableRow>
							<div>{toolsPlaceholder}</div>
							<div>{tools.map((item, index) => {
								const technologyOrToolName = item?.projectTechnologyName || ''
								const slug = item?.slug || ''
								const href = `/tools-methods/${slug}`;
								return (
									<span key={index} style={{ cursor: 'initial' }}>
										{technologyOrToolName}
										{index < tools.length - 1 && ', '}
									</span>
								)
							})}</div>
						</StyledSingleProjectInfoTableRow>}
						{cooperation !== '' && <StyledSingleProjectInfoTableRow>
							<div>{cooperationPlaceholder}</div>
							<div className='underline-animation' data-cursor={CURSOR_TYPES.HIDE} dangerouslySetInnerHTML={getHtmlFromRedactorField(cooperation)}/>
						</StyledSingleProjectInfoTableRow>}
						<StyledSingleProjectInfoTableRow className='year'>
							<div>{yearPlaceholder}</div>
							{/*<div>{year}</div>*/}
							<div>{dateRange}</div>
						</StyledSingleProjectInfoTableRow>
					</StyledProjectInfoTable>
					<StyledLinksButtonsWrapper>
						{links.map((item) => {
							const buttonText = item?.buttonText || '';
							const url = item?.url || '';
							const variant = item?.variant || 'primary';
							return (
								<Button variant={variant} key={url} href={url} as="link">
									{buttonText}
								</Button>
							);
						})}
					</StyledLinksButtonsWrapper>
				</StyledProjectRangeContentWrapper>
			</StyledTextsContainer>
			<StyledProjectRangeContentWrapper className='second' ref={descriptionWrapperRef}>
					{projectDescription && (<>
						<StyledProjectRangeDescription dangerouslySetInnerHTML={getHtmlFromRedactorField(projectDescription)} />
					</>)}
				</StyledProjectRangeContentWrapper>
		</StyledProjectRangeWrapper>
	);
};

export default ProjectRange;
