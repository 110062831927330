import React from 'react';
import styled from "styled-components";
import {CURSOR_TYPES, ICONS} from "@repo/utils/constants";
import {CircleIcon} from "../../../index";
import Button from "./Button";
import {useSelector} from "react-redux";

const LinkButton = ({external = false, ...props}) => {
    const tag = external ? 'a' : 'link'
    return (
        <Button id="top-row-story-button" as={tag} data-cursor={CURSOR_TYPES.CIRCLE} {...props}>
            <CircleIcon name={ICONS.THICK_WAVE}/>
        </Button>
    )
}

const StyledButton = styled(LinkButton)`
    aspect-ratio: 1;
    display: flex;
    align-self: center;
    overflow: hidden !important;
    border-radius: 50%;

    .icon {
        --size: calc(35px / 1.5);
        box-shadow: 0 0 2px 4px transparent;
        border-width: 3px;
    }

    //translate: 0 25%;
    @media (min-width: ${({theme}) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        .icon {
            --size: calc(43px / 1.5);
        }
    }

    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: opacity var(--color-transition-duration) var(--ease);
    
    &.visible{
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
    }
    
`
const StoryButton = ({variant = 'unstyled', href = '/story', external = false, className = '', ...props}) => {
    const {open} = useSelector((state) => state.menu);
    
    return (
        <StyledButton
            variant={variant}
            href={href}
            external={external}
            className={`${className} story-button ${!open ? 'visible' : ''}`}
            {...props}
        />
    )
}

export default StoryButton;
