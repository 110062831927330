import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import {getCssSizeValue, getHtmlFromRedactorField} from "@repo/utils";
import {BREAKPOINTS} from "../../../styles/themeSettings";
import {Button, Icon} from "../../../index";
import {AnimatePresence, motion} from "framer-motion";
import {ICONS} from "@repo/utils/constants";
import ModalButton from "../common/ModalButton";

const StyledBackground = styled(motion.div)`
    height: 100svh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99;
    background-color: rgba(5, 29, 39, 0.66);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 var(--paddingX);
    @media (min-width: ${({theme}) => theme?.breakpoints?.tablet || 768}px) {
        padding: unset;
    }
`;

const StyledModalWrapper = styled.div`
    position: relative;
    width: 100%;
    max-width: ${getCssSizeValue(302, BREAKPOINTS.MOBILE)};
    border-radius: 19px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: ${({theme}) => theme.colors.white};

    @media (min-width: ${({theme}) => theme?.breakpoints?.tablet || 768}px) {
        width: ${getCssSizeValue(380, BREAKPOINTS.TABLET)};
        max-width: ${getCssSizeValue(380, BREAKPOINTS.TABLET)};
    }

    @media (min-width: ${({theme}) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        width: ${getCssSizeValue(520, BREAKPOINTS.DESKTOP)};
        max-width: ${getCssSizeValue(520, BREAKPOINTS.DESKTOP)};
    }

    .rive-wrapper {
        height: ${getCssSizeValue(124, BREAKPOINTS.MOBILE)};
        width: ${getCssSizeValue(124, BREAKPOINTS.MOBILE)};

        @media (min-width: ${({theme}) => theme?.breakpoints?.tablet || 768}px) {
            height: ${getCssSizeValue(156, BREAKPOINTS.TABLET)};
            width: ${getCssSizeValue(156, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints.desktop}px) {
            height: ${getCssSizeValue(156, BREAKPOINTS.DESKTOP)};
            width: ${getCssSizeValue(156, BREAKPOINTS.DESKTOP)};
        }
    }
`;

const Content = styled.div`
    padding: ${getCssSizeValue(52, BREAKPOINTS.MOBILE)} ${getCssSizeValue(30, BREAKPOINTS.MOBILE)} 0;

    @media (min-width: ${({theme}) => theme?.breakpoints?.tablet || 768}px) {
        padding: ${getCssSizeValue(52, BREAKPOINTS.TABLET)} ${getCssSizeValue(56, BREAKPOINTS.TABLET)} 0;
    }
    @media (min-width: ${({theme}) => theme.breakpoints.desktop}px) {
        padding: ${getCssSizeValue(72, BREAKPOINTS.DESKTOP)} ${getCssSizeValue(50, BREAKPOINTS.DESKTOP)} 0;
    }
`

const IconWrapper = styled.div`
    width: 100%;
    display: grid;
    place-items: center;
    margin-bottom: ${getCssSizeValue(52, BREAKPOINTS.MOBILE)};

    .icon {
        max-width: unset;
        width: ${getCssSizeValue(146, BREAKPOINTS.MOBILE)};
    }

    @media (min-width: ${({theme}) => theme?.breakpoints?.tablet || 768}px) {
        margin-bottom: ${getCssSizeValue(52, BREAKPOINTS.TABLET)};
        .icon {
            width: ${getCssSizeValue(146, BREAKPOINTS.TABLET)};
        }
    }

    @media (min-width: ${({theme}) => theme.breakpoints.desktop}px) {
        margin-bottom: ${getCssSizeValue(60, BREAKPOINTS.DESKTOP)};
        .icon {
            width: ${getCssSizeValue(202, BREAKPOINTS.DESKTOP)};
        }
    }
`

const Buttons = styled.div`
    width: 100%;
`

const StyledButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

const StyledHeading = styled.p`
    text-align: center;
    color: ${({theme}) => theme.colors.twilightBlue};
    font-size: ${getCssSizeValue(22, BREAKPOINTS.MOBILE)};
    line-height: ${getCssSizeValue(26, BREAKPOINTS.MOBILE)};
    margin-bottom: ${getCssSizeValue(32, BREAKPOINTS.MOBILE)};
    padding: 0 ${getCssSizeValue(25, BREAKPOINTS.MOBILE)};
    @media (min-width: ${({theme}) => theme?.breakpoints?.tablet || 768}px) {
        padding: 0;
        font-size: ${getCssSizeValue(28, BREAKPOINTS.TABLET)};
        line-height: ${getCssSizeValue(26, BREAKPOINTS.TABLET)};
        margin-bottom: ${getCssSizeValue(32, BREAKPOINTS.TABLET)};
    }
    @media (min-width: ${({theme}) => theme.breakpoints.desktop}px) {
        font-size: ${getCssSizeValue(48, BREAKPOINTS.DESKTOP)};
        line-height: ${getCssSizeValue(56, BREAKPOINTS.DESKTOP)};
        margin-bottom: ${getCssSizeValue(32, BREAKPOINTS.DESKTOP)};
    }
`;

const StyledDescription = styled.div`
    text-align: center;
    color: ${({theme}) => theme.colors.twilightBlue};
    font-size: ${getCssSizeValue(16, BREAKPOINTS.MOBILE)};
    line-height: ${getCssSizeValue(21, BREAKPOINTS.MOBILE)};
    margin-bottom: ${getCssSizeValue(74, BREAKPOINTS.MOBILE)};
    @media (min-width: ${({theme}) => theme?.breakpoints?.tablet || 768}px) {
        font-size: ${getCssSizeValue(18, BREAKPOINTS.TABLET)};
        line-height: ${getCssSizeValue(21, BREAKPOINTS.TABLET)};
        margin-bottom: ${getCssSizeValue(74, BREAKPOINTS.TABLET)};
    }
    @media (min-width: ${({theme}) => theme.breakpoints.desktop}px) {
        font-size: ${getCssSizeValue(24, BREAKPOINTS.DESKTOP)};
        line-height: ${getCssSizeValue(41, BREAKPOINTS.DESKTOP)};
        margin-bottom: ${getCssSizeValue(80, BREAKPOINTS.DESKTOP)};
    }
`;


const Modal = ({data, isVisible, Rive = null, handleCloseButton}) => {
    const header = data?.header || '';
    const text = data?.text || '';
    const buttonText = data?.buttonText || '';
    const buttonTextOpen = data?.buttonTextOpen || '';
    const externalUrl = data?.externalUrl || '';
    const modalRef = useRef();

    const handleClickOutside = (e) => {
        if (modalRef?.current?.contains(e.target)) {
            return;
        }
        if (isVisible) {
            handleCloseButton();
        }
    };

    const handleEscapeKey = (event) => {
        if (event.key === 'Escape') {
            handleCloseButton();
        }
    };

    const handleClickOpenInNewTab = () => {
        const newWindow = window.open(externalUrl, '_blank', 'noopener,noreferrer')
        handleCloseButton()
        if (newWindow) newWindow.opener = null
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside, false);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside, false);
        };
    }, [isVisible]);

    useEffect(() => {
        if (isVisible) {
            document.addEventListener('keydown', handleEscapeKey);
        } else {
            document.removeEventListener('keydown', handleEscapeKey);
        }

        return () => {
            document.removeEventListener('keydown', handleEscapeKey);
        };
    }, [isVisible]);

    return (
        <AnimatePresence mode='wait'>
            {isVisible && (
                <StyledBackground
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                >
                    <StyledModalWrapper ref={modalRef}>
                        <Content>
                            <IconWrapper>
                                {
                                    Rive
                                        ? <Rive className='rive-wrapper'/>
                                        : <Icon name={ICONS.OPEN_IN_NEW_WINDOW_MODAL} className='no-color'/>
                                }
                            </IconWrapper>
                            <StyledHeading dangerouslySetInnerHTML={getHtmlFromRedactorField(header)}/>
                            <StyledDescription dangerouslySetInnerHTML={getHtmlFromRedactorField(text)}/>
                        </Content>
                        <Buttons>
                            {buttonTextOpen && (
                                <StyledButtonWrapper>
                                    <ModalButton
                                        className='left dimmed'
                                        onClick={handleCloseButton}
                                    >
                                        <Button variant='quaternary'>{buttonText}</Button>
                                    </ModalButton>
                                    <ModalButton
                                        className='right bold'
                                        onClick={handleClickOpenInNewTab}
                                    >
                                        <Button variant='quaternary' type="button">{buttonTextOpen}</Button>
                                    </ModalButton>
                                </StyledButtonWrapper>
                            )}
                            {!buttonTextOpen && (
                                <ModalButton
                                    className='single'
                                    onClick={handleCloseButton}
                                >
                                    <Button type="submit" variant='quaternary'>{buttonText}</Button>
                                </ModalButton>
                            )}
                        </Buttons>
                    </StyledModalWrapper>
                </StyledBackground>
            )}
        </AnimatePresence>
    );
};

export default Modal;
