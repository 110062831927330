import styled from "styled-components";
import { ScrollTrigger, useGSAP } from '@repo/utils/gsap'
import {useRef} from "react";
import {usePageTransitionContext} from "../PageTransition";
import {getCssSizeValue, getIdWithoutSpecialSymbols} from "@repo/utils";
import {useIsDesktop} from "../../../index";
import {BREAKPOINTS} from "../../../styles/themeSettings";

const Wrapper = styled.div`
    pointer-events: none;
    background: transparent;
    top: 95%;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100lvh;
    z-index: 0;
    padding: 0 var(--paddingX);
    overflow: hidden;

    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        top: 0;
        padding: 0;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: ${getCssSizeValue(47, BREAKPOINTS.DESKTOP)};
    }
`;

const GridColumn = styled.div`
    height: 100%;
    border-left: 1px solid ${({ theme }) => theme.colors.charcoal};
    border-right: 1px solid ${({ theme }) => theme.colors.charcoal};
`;

const OPTIONS = {
    start: 'top top',
    end: 'bottom bottom'
}

export default function GridLinesBackground({
    options = OPTIONS,
    gridLinesEndModuleId = ''
}) {
    const isDesktop = useIsDesktop()
    const { isLoaded } = usePageTransitionContext()
    const endModuleIdWithoutSpecialSymbols = getIdWithoutSpecialSymbols(gridLinesEndModuleId)
    const GRID_COLUMNS_AMOUNT = isDesktop ? 3 : 1
    const wrapperRef = useRef(null)

    const { start, end } = options;

    useGSAP(() => {
        if (!isLoaded) return

        ScrollTrigger.create({
            trigger: wrapperRef.current,
            pin: true,
            start,
            endTrigger: `#${endModuleIdWithoutSpecialSymbols}`,
            end,
        })
    }, {dependencies: [isLoaded]})

    return (
        <Wrapper ref={wrapperRef}>
            {Array.from({ length: GRID_COLUMNS_AMOUNT }).map((_, i) => (
                <GridColumn key={i} />
            ))}
        </Wrapper>
    )
}