import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import Portal from "./Portal";
import Video from "./Video";
import {CURSOR_TYPES, ICONS} from "@repo/utils/constants";
import styled from "styled-components";
import {useScrollContext} from "../Scroll";
import {getCssSizeValue, isFunction} from "@repo/utils";
import {BREAKPOINTS} from "../../../styles/themeSettings";
import Button from "./Button";
import {Icon, useIsDesktop} from "../../../index";
import {isIOS} from "react-device-detect";

const Wrapper = styled.div`
    animation: fade-in var(--transition-duration) var(--ease);
    position: fixed;
    inset: 0;
    z-index: 99;
    background-color: var(--primary-color);

    --video-width: 100vw;
    --video-height: 100vh;
    @media (min-aspect-ratio: 16/9) {
        --video-height: 56.25vw;
    }
    @media (max-aspect-ratio: 16/9) {
        --video-width: 177.78vh;
    }

    iframe {
        pointer-events: none;
        position: absolute;
        top: 50%;
        left: 50%;
        width: var(--video-width) !important;
        height: var(--video-height) !important;
        transform: translate(-50%, -50%);
    }
`

const StyledCursor = styled.div`
    --size: ${getCssSizeValue(75, BREAKPOINTS.MOBILE)};
    position: absolute;
    //translate: -50% -50%;
    display: flex;
    width: var(--size);
    height: var(--size);
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.black};
    border-radius: 50%;
    z-index: 1;
    pointer-events: none;
    transform: translate3d(calc(var(--x)), calc(var(--y) * -1), 0);
    will-change: transform;
    transform-origin: center;
    justify-content: center;
    align-items: center;
    left: 50%;
    top: 50%;
    translate: -50% -50%;

    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        --size: ${getCssSizeValue(100, BREAKPOINTS.TABLET)};
    }
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        --size: 100px;
    }
`

const ToggleMuteButton = styled(Button)`
	position: absolute;
    display: block;
	top: 2.5vw;
	right: 2.5vw;
	//top: 50%;
	//left: 50%;
    //translate: -50% -50%;
	//background-color: var(--secondary-color) !important;
	padding: 5px;
	color: white;
    z-index: 3;
    border-radius: 50%;
    width: ${getCssSizeValue(50, BREAKPOINTS.MOBILE)};
    aspect-ratio: 1;
    .icon{
        pointer-events: none;
    }
`

const TOGGLE_MUTE_CLASS = 'toggle_mute'
export const FullScreenCursor = forwardRef(function Cursor({ ...props }, fref){
    const CursorRef = useRef()

    useImperativeHandle(fref, () => CursorRef.current, [])

    return (
        <StyledCursor ref={CursorRef} {...props} >
            Play
        </StyledCursor>
    )
})

const FullScreenVideo = ({ isOpen=false, src='', handleClose }) => {
    const close = useRef(() => {})
    const scroll = useScrollContext()
    const [muted, setMuted] = useState(true)
    const isDesktop = useIsDesktop()

    if(isFunction(handleClose)) {
        close.current = (e) => {
            const isToggleMuteButton = e.target.classList.contains(TOGGLE_MUTE_CLASS)
            if(isToggleMuteButton) return
            handleClose(e)
        }
    } else {
        close.current = () => {}
    }

    useEffect(() => {
        const __lenis = scroll?.instance?.__lenis
        if(__lenis){
            if(isOpen){
                __lenis?.stop()
            } else {
                __lenis?.start()
            }
        }
    }, [isOpen]);

    useEffect(() => {
        return () => {
            if(!isOpen){
                setMuted(true)
            }
        }
    }, [isOpen]);

    useEffect(() => {
        if(isOpen && !isIOS) setMuted(false)
    }, [isOpen]);

    if (!isOpen) return null;
    return (
        <Portal wrapperId="main">
            <Wrapper onClick={close.current} data-cursor={CURSOR_TYPES.CLOSE}>
                <Video src={src} playing={true} muted={muted} progress/>
                {!isDesktop && (
                    <ToggleMuteButton
                        onClick={() => {
                            setMuted((prevState) => !prevState)
                        }}
                        data-cursor={CURSOR_TYPES.HIDE}
                        className={`${TOGGLE_MUTE_CLASS}`}
                        variant="unstyled"
                    >
                        <Icon name={!muted ? ICONS.SOUND_ON : ICONS.SOUND_OFF} className="no-color"/>
                    </ToggleMuteButton>
                )}
            </Wrapper>
        </Portal>
    );
};

export default FullScreenVideo;
